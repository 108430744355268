import { classNames } from '@oshcut/components';
import { Order } from '@oshcut/oshlib'
import './OrderStatusTag.scss'
import { useMemo } from 'react';

function convertSnakeToHuman(value: string): string {
  let humanValueArray = value.split('_');
  humanValueArray.forEach((humanValue, index) => {
    humanValueArray[index] = humanValue.charAt(0).toUpperCase() + humanValue.slice(1);
  })
  return humanValueArray.join(' ')
}

/**
 * Converts all `'hold_...'` statuses to `'processing'`, and converts `'hold_payment'` to 
 * `'awaiting_payment'`. These new values are fed into the className and the `convertSnakeToHuman` 
 * function.
 * @param status 
 * @returns 
 */
function collapseStatus(status: Order['status']) {
  switch(status) {
    case 'processing':
    case 'hold_feedback':
    case 'hold_material':
    case 'hold_review':
      return 'processing'
    case 'hold_pickup':
      return 'ready_for_pickup'
    case 'hold_payment':
      return 'awaiting_payment'
    default:
      return status
  }
}

/** Returns `convertSnakeToHuman(status)`, except for some specific cases */
function getFriendlyStatusName(status: string) {
  if(status === "ready_for_pickup") {
    return "Ready for Pickup"
  }
  return convertSnakeToHuman(status)
}

type OrderStatusTagProps = {
  status: Order['status']
  fontSize?: number
}
const OrderStatusTag = ({ status, fontSize = 13 }: OrderStatusTagProps) => {


  const dotSize = fontSize * 0.6//Math.max(3, fontSize - 7)
  const sidePadding = fontSize / 1.6
  const gap = fontSize / 2.5

  const collapsedStatus = useMemo(() => collapseStatus(status), [status])

  return (
    <div className={classNames('OrderStatusTag', collapsedStatus)} style={{ padding: `0 ${sidePadding}px`, gap }}>
      <div className="dot" style={{ width: dotSize, height: dotSize }}></div>
      <span style={{ fontSize }}>{getFriendlyStatusName(collapsedStatus)}</span>
    </div>
  )
}

export default OrderStatusTag