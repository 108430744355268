import { useRouteMatch } from "react-router-dom"
import { CatalogStateType } from "./Catalog"

export type CatalogParamsType = {
  /**
   * Whether we're viewing sheets or tubes.
   * 
   * @example
   * "sheet"
   * "tube"
   * undefined
   */
  category: 'sheet' | 'tube' | undefined
}

export function useCatalogParams(state: CatalogStateType) {

  const routeSheet = useRouteMatch('/catalog/sheet')
  const routeTube = useRouteMatch('/catalog/tube')

  // Combine params
  const params: CatalogParamsType = {
    category: routeSheet ? 'sheet' : routeTube ? 'tube' : undefined,
  }

  return params

}