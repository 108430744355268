import React from 'react'

import './css/ProgressSpinner.scss'

type PropType = {
  progress?: number | null
}

export function ProgressSpinner({ progress }: PropType) {

  return (
    <div className='ProgressSpinner'>
      <div className='ProgressSpinnerAnimation' />
      <div className='ProgressSpinnerText'>
        {progress != null &&
          <span>{progress.toFixed(0)}
            <span className='small'>%</span>
          </span>
        }
      </div>
    </div>
  )


}
