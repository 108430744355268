/** Set the part scale factor and save (overwrite). */
export function getMessageFromError(err: string) {
  let message
  if (~err.indexOf('Maximum exection time exceeded')) {
    message = 'We are sorry, but it took too long to process your part.  Please contact us at quote@oshcut.com for help.'
  }
  else if (~err.indexOf('Maximum queue time exceeded')) {
    message = 'We are sorry, but our system appears to be more busy than usual.  Please try again, or contact us at quote@oshcut.com for help.'
  }
  else if (~err.indexOf('Maximum queue size exceeded')) {
    message = 'We are sorry, but our system appears to be more busy than usual.  Please try again, or contact us at quote@oshcut.com for help.'
  }
  else if (~err.indexOf('Input file contains no entities.')) {
    message = 'Your file contains no entities.'
  }
  else if (~err.indexOf('Input file contains no supported entities.')) {
    message = 'Your file contains no entities we support.'
  }
  else if (~err.indexOf('Input file contains 3D entities.')) {
    message = 'Your file contains 3D entities we do not support.'
  }
  else if (~err.indexOf('Timeout waiting for Arcify server to respond')) {
    message = 'Our servers seem to be unusually busy right now. Please try uploading your part again later.'
  }
  else if (~err.indexOf('Binary DXF not supported.')) {
    message = 'Sorry, but binary DXF is not yet supported. Please save your file as an ASCII DXF and try again.'
  }
  else if (~err.indexOf('Failure - ')) {
    message = 'There was an error processing your part: ' + err.substring(err.indexOf('Failure - ') + 10)
  }

  return message
}
