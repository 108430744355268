import { Highlight, MultiSelect } from "@oshcut/components"
import { useCallback, useMemo } from "react"
import './css/LibraryPartTags.scss'

type PropType = {
  options: string[]
  value: string[]
  setOptions: (options: string[]) => void
  setValue: (value: string[]) => void
}

export function LibraryPartTagsEditor({ options, setOptions, value, setValue }: PropType) {

  const handleNewCreateOption = useCallback((newOption: string) => {
    setOptions([...options, newOption])
  }, [options])

  const selectOptions = useMemo(() => {
    return options.map(op => ({ label: op, value: op }))
  }, [options])

  return <MultiSelect
    options={selectOptions}
    value={value}
    isCreatable
    onChange={setValue}
    onNewCreatedOption={handleNewCreateOption}
  />
}

export function LibraryPartTags({ value, highlightText }: { value: string[], highlightText?: string }) {
  return <Highlight text={highlightText ?? ''}>
    <div className='LibraryPartTags'>{value.map(tag => <span className='tag' key={tag}>{tag}</span>)}</div>
  </Highlight>
}