import { ArcifiedTube, TubeProfileClassification } from "@oshcut/oshlib"
import { PartType } from "types"

// TODO: This function may not be needed once all clients have updated and parts have been reprocessed, so that
// `classification` is always defined.

/** 
 * Maps the tube profiling solutions for a part to their classifications. If the classifications are undefined, as they
 * will be for old parts, then the function returns undefined.
 * @param part The part to get the tube profiling classifications for
 * @returns The tube profiling classifications for the part, or undefined if the classifications are undefined. If the
 * part has no tube profiling solutions, an empty array is returned.
 */
export function getTubeProfileClassifications(part: PartType<ArcifiedTube>): TubeProfileClassification[] | undefined {
  if (part.arcified.tubeProfilingSolutions.length === 0) return []
  let classifications = part.arcified.tubeProfilingSolutions?.map(p => p.classification).filter((c): c is TubeProfileClassification => !!c) ?? undefined
  return classifications
}