import React from 'react'
import './css/Dialog.scss';
import './css/AlertPopup.scss';
import {Dialog, PrimaryButton} from "@oshcut/components";

export function AlertPopup(props) {
    
    return (
        <Dialog
            className={'alertPopup'}
            open
            title={props.title}
            onClose={props.onOk}
        >
                <div className="alertPopupMessage">{props.message}</div>
                <div className="alertPopupButtons">
                    <PrimaryButton onClick={props.onOk}>OK</PrimaryButton>
                </div>
        </Dialog>
    )
}
