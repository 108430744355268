export default function plural(string: string, number: number) {
  let vowels = ['a', 'e', 'i', 'o', 'u']

  if (number === 1) {
    return string
  }
  else {
    if (string[string.length - 1] === 's') {
      return string + 'es'
    } else if (string[string.length - 1] === 'y' && !vowels.some(v => v === string[string.length - 2])) {
      return string.substring(0, string.length - 1) + 'ies'
    } else {
      return string + 's'
    }
  }
}

export function upperFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isAre(number: number) {
  return number === 1 ? 'is' : 'are'
}

export function wasWere(number: number) {
  return number === 1 ? 'was' : 'were'
}

export function isntArent(number: number) {
  return number === 1 ? 'isn\'t' : 'aren\'t'
}

export function doesDo(number: number) {
  return number === 1 ? 'does' : 'do'
}

export function thisThese(number: number) {
  return number === 1 ? 'this' : 'these'
}

export function ThisThese(number: number) {
  return number === 1 ? 'This' : 'These'
}

export function hasHave(number: number) {
  return number === 1 ? 'has' : 'have'
}
