import React, { useMemo } from 'react'
import { FaCartShopping } from "react-icons/fa6"
import { NavLink, Link } from "react-router-dom"
import { StateType } from 'types'
import { csrfHeader } from '@oshcut/oshlib'
import logo from "../images/logo.svg"
import logoSmall from "../images/logo-small.svg"
import './css/SiteHeader.scss'
import UserButton from './UserButton'
import { DispatchFunction } from 'reducer'
import { LinkButton } from '@oshcut/components'
import { useSignIn } from 'useSignIn'
import { useClientWidth } from 'hooks/useClientWidth'

interface SiteHeaderProps {
	state?: StateType
	dispatch?: DispatchFunction
}

const SiteHeader: React.FC<SiteHeaderProps> = (props) => {

	const { customerUser, showSignIn, showSignUp } = useSignIn()

  const clientWidth = useClientWidth()

  let logoSrc = useMemo(() => {
    if(clientWidth > 600) {
      return logo
    } else {
      return logoSmall
    }
  }, [clientWidth])

	// Run touch API call once on page load
	React.useEffect(() => {
		if (typeof document !== `undefined`) {
			const headers = {
				'Content-Type': "application/json; charset=utf-8",
				...csrfHeader
			}

			let data = {
				referrer: document.referrer,
				url: document.URL
			}

			let opts = { method: 'POST', headers, body: JSON.stringify(data) }

			fetch("/api/v3/customer/touch", opts)
				.then((res) => res.text())
				.then((res) => JSON.parse(res))
				.then((result) => {
					if (!result.success) {
						console.error('Failed on touch')
					}
				})
				.catch((e) => {
					console.error('Failed on touch.  Error = ')
					console.error(e)
				})
		}
	}, [])

	const cartLink = props.state?.order ? `/cart/${encodeURIComponent(props.state.order.guid)}` : '/cart'

	const numberOfPartsInCart = props.state?.parts.filter(p => !p.deleted).length || 0

	return (
		<div className={'siteHeader'}>
			<div className="headerLogoContainer">
				<Link to={cartLink}> <img
					src={logoSrc}
					alt="OSH Cut Metal Fabrication"
				/>
				</Link>
			</div>

			{props.state && props.dispatch &&
				<div className='userInfo'>
					{customerUser ? (<>
						<UserButton state={props.state} dispatch={props.dispatch} />
						<NavLink to={cartLink} className={'cartBtn'}>
							{numberOfPartsInCart ? <div className={'cartItemCount'}>{numberOfPartsInCart}</div> : <></>}
							<FaCartShopping />
						</NavLink>
					</>) : (<>
						<LinkButton className='createAccount' onClick={showSignUp}>Create Account</LinkButton>
							<button onClick={showSignIn} className='signIn'>
							Sign In
						</button>
					</>)}
				</div>
			}
		</div>
	);
};
export default SiteHeader;