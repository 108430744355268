import {
  add, mul, sub, norm, atan2, norm2,
  getPointTangentOfEntity, correctAngles
} from "@oshcut/oshlib"

/**
 * Offsets a contour by a given positive amount by translating lines, expanding or contracting arcs, and creating circles of fixed radius at each entity endpoint. The contour should be closed, and oriented CCW. The returned entities do not themselves form a closed contour, but they are guaranteed to occupy the same area that the true offset contour does. In reality, the entities would need to be trimmed at their points of intersection to create a good contour.
 * @param {Contour} contour The contour to offset
 * @param {number} offset The amount to offset the contour by
 */
export function offsetContourCrude(contour, offset) {

  let offsetEntities = []
  for (let e of contour.entities) {
    switch (e.type) {
      case 'LINE':
        let tangent = sub(e.end, e.start)
        tangent = mul(tangent, 1 / norm(tangent))
        let normal = { x: tangent.y, y: -tangent.x }   // May have to negate this
        if (e.reversed) normal = mul(normal, -1)
        offsetEntities.push({
          ...e,
          start: add(e.start, mul(normal, offset)),
          end: add(e.end, mul(normal, offset)),
        })
        break
      case 'ARC':
      case 'CIRCLE':
        let newR = e.reversed ? e.r - offset : e.r + offset
        if (newR > 0) {
          offsetEntities.push({
            ...e,
            r: newR
          })
        }
        break
      default:
        throw new Error('Unsupported entity in offsetContourCrude: ' + e.type)
    }

    let endPoint = getPointTangentOfEntity(e, e.reversed ? 1 : 0)[0]
    offsetEntities.push({
      type: 'CIRCLE',
      x: endPoint.x,
      y: endPoint.y,
      r: offset
    })
  }

  return offsetEntities
}

/**
 * Offsets a contour by a given positive amount by translating lines, expanding or contracting arcs, and creating arcs of fixed radius with positive sweep angle joining each expanded entity. The contour should be closed, and oriented CCW. The returned contour is always closed; however, when applied to a non-convex contour, the result will be self-intersecting, and entities would need to be trimmed at their points of intersection to create a good contour.
 * @param {Contour} contour The contour to offset
 * @param {number} offset The amount to offset the contour by
 */
export function offsetContourBetter(contour, offset) {

  let offsetEntities = []
  for (let e of contour.entities) {
    switch (e.type) {
      case 'LINE':
        let tangent = sub(e.end, e.start)
        tangent = mul(tangent, 1 / norm(tangent))
        let normal = { x: tangent.y, y: -tangent.x }   // May have to negate this
        if (e.reversed) normal = mul(normal, -1)
        offsetEntities.push({
          ...e,
          start: add(e.start, mul(normal, offset)),
          end: add(e.end, mul(normal, offset)),
        })
        break

      case 'ARC':
        let newR = e.reversed ? e.r - offset : e.r + offset
        if (newR > 0) {
          offsetEntities.push({
            ...e,
            r: newR
          })
        } else {
          let newE = {
            ...e,
            startAngle: e.endAngle + Math.PI,
            endAngle: e.startAngle + Math.PI,
            r: Math.abs(newR),
            reversed: !e.reversed
          }
          correctAngles(newE)
          offsetEntities.push(newE)

        }
        break
      case 'CIRCLE': {
        let newR = e.reversed ? e.r - offset : e.r + offset
        offsetEntities.push({
          ...e,
          r: Math.abs(newR)
        })
        break
      }
      default:
        throw new Error('Unsupported entity in offsetContourCrude: ' + e.type)
    }
  }

  for (let i = 0; i < contour.entities.length; i++) {
    // Get angle between entities
    let e0 = contour.entities[i]
    let e1 = contour.entities[(i + 1) % contour.entities.length]

    let [p0, tan0] = getPointTangentOfEntity(e0, e0.reversed ? 0 : 1)
    let [p1, tan1] = getPointTangentOfEntity(e1, e1.reversed ? 1 : 0)

    // Verify entities meet at same point
    if (norm2(sub(p0, p1)) > 1e-6) {
      throw new Error('Cannot get angle between entities: entities do not meet at a point')
    }

    if (e0.reversed) tan0 = mul(tan0, -1)
    if (e1.reversed) tan1 = mul(tan1, -1)

    let angle0 = atan2(tan0)
    let angle1 = atan2(tan1)

    let startAngle = angle0 - Math.PI / 2
    let endAngle = angle1 - Math.PI / 2


    let newE = {
      type: 'ARC',
      startAngle,
      endAngle,
      x: p0.x,
      y: p0.y,
      r: offset,
      reversed: false
    }
    correctAngles(newE)

    if (newE.endAngle - newE.startAngle > 1e-9 && newE.endAngle - newE.startAngle < Math.PI * 2 - 1e-9) {
      offsetEntities.push(newE)
    }
  }


  return offsetEntities
}