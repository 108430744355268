import { Affiliate, fetchPost } from '@oshcut/oshlib'
import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import AffiliateRegister from './AffiliateRegister'


/**
 * Checks the affiliate code in the URL and redirects back to cart if invalid.
 * Otherwise, renders the AffiliateRegister component with the affiliate object.
 */
const AffiliateRouteWrapper = () => {

  const { potentialAffiliateCode } = useParams<{ potentialAffiliateCode: string }>()

  const [fetchingAffiliate, setFetchingAffiliate] = useState<boolean>(true)
  const [affiliate, setAffiliate] = useState<Affiliate | null>(null)
  
  // Hide the default app header and make the background black
  useEffect(() => {
    const header = document.getElementsByClassName("siteHeader")?.[0]
    if (header) {
      //@ts-ignore
      header.style.display = "none"
    }

    document.body.style.backgroundColor = "#121212"
    document.body.style.transition = "background-color 0.5s"

    return () => {
      if(header) {
        //@ts-ignore
        header.style.display = "flex"
      }
      document.body.style.backgroundColor = "white"
    }

  }, [])

  useEffect(() => {
    fetchPost("/api/v2/affiliate/get_by_code", {
      url_code: potentialAffiliateCode
    })
      .then(data => {
        console.log(data)
        if (data.success && data.affiliate != null) {
          setAffiliate(data.affiliate)
        } else {
          setAffiliate(null)
        }
      })
      .catch(_ => {}) // On any errors, the user is redirected
      .finally(() => setFetchingAffiliate(false))
  }, [potentialAffiliateCode])

  return (<>
    {!fetchingAffiliate && affiliate == null && <Redirect to="/" />} 
    {!fetchingAffiliate && affiliate != null && <AffiliateRegister affiliate={affiliate} />}
  </>)
}

export default AffiliateRouteWrapper