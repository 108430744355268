import { ReactNode } from 'react'
import constants from '../constants'
import './css/Button.scss'

type PropType = {
  type?: 'BTN_PRIMARY' | 'BTN_SECONDARY' | 'BTN_TERTIARY'
  id?: string
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  children?: ReactNode
}

export function Button(props: PropType) {
  const buttonClassName = `button${props.type === constants.BTN_PRIMARY ? ' btnPrimary' : ''}${props.type === constants.BTN_SECONDARY ? ' btnSecondary' : ''}${props.type === constants.BTN_TERTIARY ? ' btnTertiary' : ''}${props.className ? ' ' + props.className : ''}${props.disabled ? ' disabled' : ''}`
  return (
    <button id={props.id} className={buttonClassName} onClick={props.onClick} disabled={props.disabled} style={props.disabled ? { pointerEvents: "none" } : {}}>
      {props.children}
    </button>
  )
}
