import React, { useContext, useEffect, useMemo, useState } from 'react'
import './AddNewPaymentMethod.scss'
import { CustomerPaymentMethod } from '@oshcut/oshlib'
import { classNames, Input, LoadingSpinner, PrimaryButton, SecondaryButton } from '@oshcut/components'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SetupIntent, StripePaymentElementChangeEvent, StripePaymentElementOptions } from '@stripe/stripe-js'
import { PaymentMethodsContext } from './PaymentMethodsContext'
import { PaymentMethodActionsWrapper } from './PaymentMethodActionsWrapper'
import { ErrorCard } from './ErrorCard'

type props = {
  onSuccess: (paymentMethod: CustomerPaymentMethod) => void
  onCancel: () => void
}

export function AddNewPaymentMethod({onSuccess, onCancel}: props) {
  const [loading, setLoading] = useState(false)
  const [loadingPaymentElement, setLoadingPaymentElement] = useState(true)
  const [nickname, setNickname] = useState<string | null>(null)
  const [viewTerms, setViewTerms] = useState(false)
  const [paymentElementListened, setPaymentElementListened] = useState(false)
  const [paymentElementReady, setPaymentElementReady] = useState(false)
  const [paymentMethodType, setPaymentMethodType] = useState<null | CustomerPaymentMethod['type']>(null)
  const [paymentTermsAgreed, setPaymentTermsAgreed] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null)
  const stripe = useStripe()
  const elements = useElements()

  const {createPaymentMethod, paymentMethods, customer, customerUser} = useContext(PaymentMethodsContext)

  useEffect(() => {
    if(paymentElementListened) return
    if(!elements) return
    const element = elements.getElement('payment')
    if(!element) return
    element.once('ready', () => {
      setLoadingPaymentElement(false)
    })
    setPaymentElementListened(true)

  }, [elements, paymentElementListened])

  const prefillData: StripePaymentElementOptions = useMemo(() => {
    if (!customerUser) return {}
    return {
      defaultValues: {
        billingDetails: {
          name: `${customerUser.first_name} ${customerUser.last_name}`,
          email: customerUser.email,
          phone: customerUser.phone,
          address: {
            country: 'US',
            postal_code: customer.zip,
            state: customer.state,
            city: customer.city,
            line1: customer.address,
            line2: customer.address2,
          }
        }
      }
    }
  }, [customerUser, customer])

  async function handleCreateCustomerPaymentMethod() {
    if (!stripe || !elements || !paymentElementReady || !paymentMethodType) return
    setLoading(true)

    let localSetupIntent = setupIntent
    if(!localSetupIntent) {
      const stripeResult = await stripe.confirmSetup({ elements: elements, redirect: 'if_required' })

      if (!stripeResult.setupIntent) {
        setError('Error creating payment method')
        setLoading(false)
        return
      }
      localSetupIntent = stripeResult.setupIntent
      setSetupIntent(localSetupIntent) // this is in case there is a server error and the user needs to retry
    }


    createPaymentMethod(localSetupIntent.id, paymentMethodType, nickname)
      .then((pm)=> onSuccess(pm))
      .catch(e => {
        setError(e.serverMessage ?? e.message ?? 'An unknown error occurred.')
        setLoading(false)
      })
  }

  function handlePaymentElementOnChange(evt: StripePaymentElementChangeEvent) {
    try {
      setPaymentMethodType(getOshcutPaymentMethodTypeString(evt.value.type))
      setPaymentElementReady(evt.complete)
    } catch (e: any) {
      setError(e.message)
    }
  }

  const dynamicTerms = useMemo(() => {
    if(!paymentMethodType) {
      return { one: '', two: '', three: '', four: '' } //not really a concern, but makes types happy.
    }

    switch (paymentMethodType) {
    case 'credit_card':
      return {
        one: 'charge the provided credit card',
        two: 'charge your credit card',
        three: 'charge your card',
        four: 'charges',
        five: 'charged',
      }
    case 'ach_bank_debit':
      return {
        one: 'debit the provided bank account',
        two: 'debit your account',
        three: 'debit your bank account',
        four: 'debits',
        five: 'debited',
      }
    default:
      const never: never = paymentMethodType
      throw new Error('unsupported payment method type: ' + paymentMethodType)
    }
  }, [paymentMethodType])

  return (
    <div className="AddNewPaymentMethod">
      {loadingPaymentElement && <LoadingSpinner />}
      <div className={classNames('add-new-method-main-content', { 'viewTerms': viewTerms, 'payment-element-loading': loadingPaymentElement })}>
        <div className='not-terms'><label>Payment Nickname<Input placeholder='(optional)' value={nickname} maxLength={100} onChange={(e, parsed) => setNickname(parsed)} /></label></div>
        <div className='not-terms'><PaymentElement onChange={handlePaymentElementOnChange} options={{readOnly: loading, defaultValues: prefillData.defaultValues}}/></div>
        <div className='add-payment-terms'>
          <div className='payment-terms-title'>OSH Cut Payment Terms</div>
          <p>
            By checking 'I agree', you authorize OSH Cut to {dynamicTerms.one} for any amount owed for charges arising from your use of OSH Cut’s services and/or purchase of products from OSH Cut,
            pursuant to OSH Cut’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by removing the payment method from your account.
            We may {dynamicTerms.two} for any active order with that assigned payment method, if an alternative method of payment is not provided by you.
            If you use OSH Cut’s services or purchase additional products periodically pursuant to OSH Cut’s terms, you authorize OSH Cut to {dynamicTerms.three} periodically.
            Payments that fall outside of the regular {dynamicTerms.four} authorized above will only be {dynamicTerms.five} after your authorization is obtained.
          </p>
          <div><label><input type='checkbox' disabled={loading} checked={paymentTermsAgreed} onChange={(e) => setPaymentTermsAgreed(e.target.checked)} /> I agree to the payment terms</label></div>
        </div>
        <PaymentMethodActionsWrapper>
          {!viewTerms && <PrimaryButton disabled={loading || !paymentElementReady} onClick={() => setViewTerms(true)}>Continue</PrimaryButton>}
          {viewTerms && <PrimaryButton disabled={loading || !paymentTermsAgreed} onClick={handleCreateCustomerPaymentMethod}>{loading ? <LoadingSpinner /> : 'Confirm'}</PrimaryButton>}
          <SecondaryButton disabled={loading} onClick={()=> viewTerms ? setViewTerms(false) : onCancel()}>{viewTerms ? 'Back' : 'Cancel'}</SecondaryButton>
        </PaymentMethodActionsWrapper>
        {!!error && <ErrorCard>{error}</ErrorCard>}
        </div>
    </div>
  )
}

function getOshcutPaymentMethodTypeString(stripeType: string): CustomerPaymentMethod['type'] {
  switch (stripeType) {
    case 'card':
      return 'credit_card'
    case 'us_bank_account':
      return 'ach_bank_debit'
    default:
      throw new Error(`Unhandled stripe payment method type: ${stripeType}`)
  }
}
