import { createLeadInGeometry } from "./createLeadInGeometry"
import {
  mul, norm, sub, cross, dot,
  getPointTangentOfEntity, distanceToEntities, getTangentNormalAtParametricPoint, splitEntity, shortenEntities
} from '@oshcut/oshlib'
import update from 'immutability-helper'
import constants from "./constants"
import Log from "./logs"

/**
 * Creates distinct cut paths for a contour given a set of contour properties, which include lead-ins and all.
 * @param {Contour} contour The contour to create the cut paths for
 * @param {Object} contourProperty Contour properties, lead-in positions, cut direction, etc.
 * @returns {Array} An array of objects, each containing a cutPath, a leadin, and a firstPassLeadIn. These two objects are arrays of entities that represent the distinct cut paths for this contour. NC code can be generated directly from these cut paths, so the editor will render these cut paths to ensure they are correct. The cutPath includes the entire lead in and the part contour. The firstPassLeadIn includes only the lead in, which stops short of the part, and is only used when performing a two-pass lead-in.
 */
export function createCutPathsFull(contour, contourProperty) {

  // TODO: Cache cut paths by contour id and serialized contourProperty -- if not, they'll be calculated for each partCoord on each render

  // For open contours, return the entities unchanged (since pierce will be exactly at the beginning)
  // For each lead-in position, find the parametric position of the lead-in.
  // The distanceToEntity function returns information identifying each hit entity by reference. Map that to the entity indices, since the references will change.
  // If any of the parametric coordinates has the value of 1, move it to the start of next entity. This ensures that corner lead-ins will be enter at the right direction.
  // Calculate the tangent and normal vectors at the lead-in point.
  // Sort the lead-ins by their parametric position.
  // For each lead-in, if the parametric cut coordinate is not 0 or 1, split the entity at the cut position, splice in the new entities, and increment the entity indices of all later cut positions by 1.
  // Iterate through cut indices (they should all still be correct) and create individual contours.
  // Create the lead in geometries and prepend those entities onto the contours.
  // Shorten each cut path by the microjoint length, keeping in mind that this length might be longer than the last several entity(ies).
  // Return this array of contours.

  if (contourProperty.cuttingMethod === constants.CUTTING_METHOD_IGNORE) {
    return []
  }

  // We will be changing this array, don't want to modify the original
  let entities = [...contour.entities]

  // To reverse the cutting direction, reverse the entities array and toggle the reversed property on each entity
  if (contourProperty.cuttingDirection === constants.CLOCKWISE) {
    // Reverse the direction of this contour so that it is cut CW
    entities.reverse()
    entities = entities.map(e => update(e, { reversed: { $set: !e.reversed } }))
  }

  // Mark entities as removed
  if (contourProperty.removedEntities) {
    for (let toRemoveIdx of contourProperty.removedEntities) {
      entities[toRemoveIdx] = { ...entities[toRemoveIdx], removed: true }
    }
  }

  if (!contour.isClosed) {
    // For open contours, return the entities unchanged (since pierce will be exactly at the beginning)
    return [{ partPath: entities, leadIn: [], firstPassLeadIn: [] }]
  }

  // Quick verify that we're using the correct contourProperty
  if (contour.id !== contourProperty.contourId) {
    Log.info(contour, contourProperty)
    throw new Error(`contour id does not match contourProperty id. contour.id = ${contour.id}, contourProperty.contourId = ${contourProperty.contourId}`)
  }

  let cuts = contourProperty.positions.map(pos => {

    // For each lead-in position, find the parametric position of the lead-in.
    let cut = distanceToEntities(pos, entities, true)

    if (cut.nearestParametric > 0.999999)
      cut.nearestParametric = 1
    if (cut.nearestParametric < 0.000001)
      cut.nearestParametric = 0

    // The distanceToEntities identifies each entity by reference. Map that to the entity's index, since the references will change.
    cut.nearestEntityIndex = entities.indexOf(cut.nearestEntity)



    // If any of the parametric coordinates has the value of 1, move it to the start of next entity and recalculate the tangent and normal vectors. This ensures that corner lead-ins will be enter at the right direction.
    const nearEndOfEntity = (cut.nearestParametric === 1 && !cut.nearestEntity.reversed) || (cut.nearestParametric === 0 && cut.nearestEntity.reversed)
    if (nearEndOfEntity) {
      cut.nearestEntityIndex = (cut.nearestEntityIndex + 1) % entities.length
      cut.nearestEntity = entities[cut.nearestEntityIndex] // This should work even if the contour has only a single entity
      cut.nearestParametric = cut.nearestEntity.reversed ? 1 : 0
    }

    // Calculate the tangent and normal vectors at the lead-in point.
    let { tangent, normal } = getTangentNormalAtParametricPoint(contour, cut.nearestEntity, cut.nearestParametric)
    // Hack to move normal to correct side for clockwise contours
    if (contourProperty.cuttingDirection === constants.CLOCKWISE) {
      normal = mul(normal, -1)
    }

    // Detect exterior corners
    const nearBeginningOfEntity = (cut.nearestParametric === 0 && !cut.nearestEntity.reversed) || (cut.nearestParametric === 1 && cut.nearestEntity.reversed)
    if (nearBeginningOfEntity) {
      const previousEntityIndex = (cut.nearestEntityIndex - 1 + entities.length) % entities.length
      const previousEntity = entities[previousEntityIndex]
      const previousEntityEndParametric = previousEntity.reversed ? 0 : 1
      const previousEntityStartParametric = previousEntity.reversed ? 1 : 0
      const nearestEntityEndParametric = cut.nearestEntity.reversed ? 0 : 1
      const nearestEntityStartParametric = cut.nearestEntity.reversed ? 1 : 0
      const previousEntityEndTangent =
        getTangentNormalAtParametricPoint(contour, previousEntity, previousEntityEndParametric)
          .tangent
      const previousEntityStartPoint = getPointTangentOfEntity(previousEntity, previousEntityStartParametric)[0]
      const previousEntityEndPoint = getPointTangentOfEntity(previousEntity, previousEntityEndParametric)[0]
      const nearestEntityStartPoint = getPointTangentOfEntity(cut.nearestEntity, nearestEntityStartParametric)[0]
      const nearestEntityEndPoint = getPointTangentOfEntity(cut.nearestEntity, nearestEntityEndParametric)[0]
      const previousEntitySize = norm(sub(previousEntityStartPoint, previousEntityEndPoint))
      const nearestEntitySize = norm(sub(nearestEntityStartPoint, nearestEntityEndPoint))
      // If the angle between the previous tangent and this tangent is greater than about 60 degrees, and the two entities are sufficiently large, change the lead-in to a straight approach.

      // Get signed angle between the two vectors.
      // Found this nifty trick at https://stackoverflow.com/questions/5188561/signed-angle-between-two-3d-vectors-with-same-origin-within-the-same-plane
      let _cross = cross(previousEntityEndTangent, tangent)
      let _dot = dot(previousEntityEndTangent, tangent)
      let angle = Math.atan2(_cross, _dot)

      // For outer contours with CCW traversal, we want angle to be positive
      // For inner contours, this is reversed
      // For CW traversal, this is reversed

      if (contour.type === 'inner') {
        angle = -angle
      }
      if (contourProperty.cuttingDirection === constants.CLOCKWISE) {
        angle = -angle
      }

      let minAngle = 70 * Math.PI / 180
      if (angle > minAngle && previousEntitySize > 0.25 && nearestEntitySize > 0.25) {
        // Override lead-in properties
        cut.corner = true
      }


    }

    cut.tangent = tangent
    cut.normal = normal


    return cut

  })

  // Sort the lead-ins by their parametric position.   
  cuts.sort((a, b) => {
    if (a.nearestEntityIndex > b.nearestEntityIndex) {
      return 1
    } else if (a.nearestEntityIndex < b.nearestEntityIndex) {
      return -1
    } else if (a.nearestParametric > b.nearestParametric && !a.nearestEntity.reversed) {
      return 1
    } else if (a.nearestParametric < b.nearestParametric && !a.nearestEntity.reversed) {
      return -1
    } else if (a.nearestParametric < b.nearestParametric && a.nearestEntity.reversed) {
      return 1
    } else if (a.nearestParametric > b.nearestParametric && a.nearestEntity.reversed) {
      return -1
    } else {
      return 0
    }
  })

  // For each lead-in, if the parametric cut coordinate is not 0 or 1, split the entity at the cut position, splice in the new entities, and increment the entity indices of all later cut positions by 1.

  for (let i = 0; i < cuts.length; i++) {
    let cut = cuts[i]
    if (cut.nearestParametric === 0 || cut.nearestParametric === 1) {
      continue
    }

    let splintities = splitEntity(cut.nearestEntity, cut.nearestParametric)
    // Splice in the new splintities
    entities.splice(cut.nearestEntityIndex, 1, ...splintities)

    let originalEntity = cut.nearestEntity
    let originalParametric = cut.nearestParametric

    // Modify this cut to place the lead-in an the start of the last entity
    cut.nearestEntity = splintities[splintities.length - 1]
    // par.nearestEntityIndex is modified in the for loop below
    cut.nearestParametric = cut.nearestEntity.reversed ? 1 : 0


    if (splintities.length === 2) {
      // Increment indices of this and all later cuts
      for (let j = i; j < cuts.length; j++) {

        if (j > i && cuts[j].nearestEntity === originalEntity) {
          // The nearestEntity for this later cut is the entity that was split, so set it to the new entity
          cuts[j].nearestEntity = entities[cuts[j].nearestEntityIndex + 1]

          // The nearestParametric for this later cut has also changed. Can we compute it directly?
          if (originalEntity.reversed) {
            cuts[j].nearestParametric = cuts[j].nearestParametric / originalParametric
          } else {
            cuts[j].nearestParametric = (cuts[j].nearestParametric - originalParametric) / (1 - originalParametric)
          }
        }

        cuts[j].nearestEntityIndex++
      }
    } else {
      // A circle was cut and was replaced with an arc

      // Adjust parametric of cuts on this entity
      for (let j = i; j < cuts.length; j++) {

        if (j > i && cuts[j].nearestEntity === originalEntity) {
          // The nearestEntity for this later cut is the new arc
          cuts[j].nearestEntity = splintities[0]

          // The nearestParametric for this later cut has also changed.
          cuts[j].nearestParametric -= originalParametric
        }
      }
    }
  }

  // Iterate through cut indices (the cuts should all be updated with the latest entity indices and references) and create individual cut paths

  let cutPaths = []
  for (let i = 0; i < cuts.length; i++) {
    let cut = cuts[i]
    let nextCut = cuts[(i + 1) % cuts.length]
    // if thisCut === nextCut, then everything will still work out just fine

    // Loop through all entities from thisCut.nearestEntityIndex through and including nextCut.nearestEntityIndex-1
    let eIdxStart = cut.nearestEntityIndex
    let eIdxEnd = nextCut.nearestEntityIndex
    let eIdx = eIdxStart

    // Create the lead in geometries and prepend those entities onto the contours.
    // Log.info(cut)
    // Log.info(contourProperty)
    let leadIn
    let firstPassLeadIn

    // Override length setting for ENGRAVE contours
    let leadInLength = contourProperty.cuttingMethod === constants.CUTTING_METHOD_ENGRAVE ? 0 : contourProperty.length
    if (cut.corner) {
      leadIn = createLeadInGeometry(cut.nearestPoint, cut.tangent, cut.normal, leadInLength, 0, Math.PI)
    } else {
      leadIn = createLeadInGeometry(cut.nearestPoint, cut.tangent, cut.normal, leadInLength, contourProperty.radius, contourProperty.angle * Math.PI / 180)
    }

    // Shorten the leadIn to form the firstPassLeadIn, which is cut first for very thick materials
    if (leadIn.entities.length === 1) {
      // Straight lines are shortened by 0.05
      firstPassLeadIn = shortenEntities(leadIn.entities, 0.05)
    } else {
      // Lead-ins with radius just keep the straight portion
      firstPassLeadIn = leadIn.entities.slice(0, 1)
    }

    let partPath = []

    while (true) {
      partPath.push(entities[eIdx])
      eIdx = (eIdx + 1) % entities.length
      if (eIdx === eIdxEnd)
        break
    }

    // Shorten each cut path by the microjoint length, keeping in mind that this length might be longer than the last several entity(ies).

    // Microjoints only applied for CUT contours
    if (contourProperty.microjoint > 0 && contourProperty.cuttingMethod === constants.CUTTING_METHOD_CUT) {
      let shortenedPartPath = shortenEntities(partPath, contourProperty.microjoint)
      partPath = shortenedPartPath
    }

    cutPaths.push({ partPath, leadIn: leadIn.entities, firstPassLeadIn })
  }



  // Log.info(cuts)
  // Log.info(cutPaths)

  return cutPaths


}