import { classNames, Dialog, LoadingSpinner, PrimaryButton, SecondaryButton, SortDirection, Tooltip } from '@oshcut/components'
import { AccountStatementItem, fetchPost, FetchError, CustomerPaymentMethod } from '@oshcut/oshlib'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BillingViewProps, formatStatementItemPrice, SortInfo } from './shared'
import { InvoiceTable } from './InvoiceTable'
import { FaCheckCircle } from 'react-icons/fa'
import { FaRegCircleQuestion } from 'react-icons/fa6'
import { InvoiceTableMobile } from './InvoiceTableMobile'
import { PaymentMethodSelector } from '../PaymentMethod/PaymentMethodSelector'
import { AddPaymentMethod, PaymentExecutable } from '../PaymentMethod/AddPaymentMethod'

type LocalProps = {
  onRefreshRequest: () => void
  sortInfo?: SortInfo
  setSortInfo: (sortInfo: SortInfo | undefined) => void
  mobile?: boolean
} & BillingViewProps


export const OpenInvoices: React.FC<LocalProps> = (props: LocalProps) => {

  const shouldUseNewCustomerPaymentMethods = !!props.customer.ff_checkout_v2

  const [statementItems, setStatementItems] = useState<AccountStatementItem[]>([])

  const [error, setError] = useState<string | null>(null)
  const [paymentError, setPaymentError] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [paymentRequestInProgress, setPaymentRequestInProgress] = useState<boolean>(false)
  const [paymentCompleteDialogVisible, setPaymentCompleteDialogVisible] = useState<boolean>(false)

  const [checkboxMap, setCheckboxMap] = useState<Set<string>>(new Set())

  const [payDialogVisible, setPayDialogVisible] = useState<boolean>(false)

  const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod] = useState<CustomerPaymentMethod | null>(null)

  useEffect(() => {
    fetchPost('/api/v2/payment_method/get_default')
      .then((r)=> {
        setSelectedSavedPaymentMethod(r.defaultPaymentMethod)
      })
  }, [])

  function selectAll() {
    const newCheckboxMap = new Set<string>(statementItems.map(si => si.disambiguatedId))
    setCheckboxMap(newCheckboxMap)
  }

  function openPayDialog() {
    setPayDialogVisible(true)
  }

  const updateOpenInvoices = useCallback(() => {
    if (props.customer.id == undefined) throw new Error('customerId is required')
    setIsLoading(true)
    const si = !!props.sortInfo ? { column: props.sortInfo.column, direction: props.sortInfo.direction === SortDirection.ASCENDING ? 'ascending' as const : 'descending' as const } : undefined
    fetchPost('/api/v2/account_statement/items',
      { customer_id: props.customer.id, sort_info: si, request_type: 'open', pagination_type: 'all'}
    ).then(({ account_statement_items }) => {
      setStatementItems(account_statement_items)
      setIsLoading(false)
      setError(null)
    }).catch((ex) => {
      console.error(ex)
      setError('Unable to load page data. Please contact support for assistance.')
    })
  }, [props.customer.id, props.sortInfo])

  useEffect(() => {
    updateOpenInvoices()
  }, [updateOpenInvoices])

  const statementInvoices = useMemo(() => {
    return statementItems.filter(si => si.type === 'Invoice')
  }, [statementItems])

  const selectedItems = useMemo(() => {
    if(!checkboxMap) return []
    if(!statementItems) return []
    return statementItems.filter(si => checkboxMap.has(si.disambiguatedId))
  }, [checkboxMap, statementItems])

  const selectedInvoices = useMemo(() => {
    if(!statementItems) return []
    return selectedItems.filter(si => si.type === 'Invoice')
    }, [selectedItems])

  const selectedCreditNotes = useMemo(() => {
    if(!statementItems) return []
    return selectedItems.filter(si => si.type === 'Credit Note')
  }, [selectedItems])

  const noPayableInvoicesSelected = useMemo(() => {
    return selectedInvoices.length === 0
  }, [selectedInvoices])

  const { totalInvoiceBalanceCents, totalCreditNoteBalanceToApplyCents, totalChargeAmountCents } = useMemo(() => {
    const totalInvoiceBalanceCents =  selectedInvoices.reduce((acc, si) => acc + si.outstandingBalanceCents, 0)
    //credit note balance values are negative for sorting purposes, but we want to look at the absolute value for the total
    const totalCreditBalanceAvailable = Math.abs(selectedCreditNotes.reduce((acc, si) => acc + si.outstandingBalanceCents, 0))
    const totalCreditNoteBalanceToApplyCents = Math.min(totalInvoiceBalanceCents, totalCreditBalanceAvailable)
    const totalChargeAmountCents = Math.max(totalInvoiceBalanceCents - totalCreditNoteBalanceToApplyCents, 0)
    return { totalInvoiceBalanceCents, totalCreditNoteBalanceToApplyCents, totalChargeAmountCents }
  }, [selectedInvoices, selectedCreditNotes])

  const handlePaymentMethodSelection = (pm: CustomerPaymentMethod) => {
    setSelectedSavedPaymentMethod(pm)
    if (paymentMethodRequired && pm.status === 'pending_verification') {
      setPaymentError('Selected payment method requires verification.')
    } else {
      setPaymentError(null)
    }
  }

  //Below can go away after ff_checkout_v2 is fully rolled out
  const [paymentExecutable, setPaymentExecutable] = useState<PaymentExecutable | null>(null)
  const getPaymentMethodId = useCallback(async () => {
    if(totalChargeAmountCents === 0) return null
    if(!paymentExecutable){
      setPaymentError("Payment method capture error")
      return null
    } else {
      const paymentMethodResponse = await paymentExecutable()
      if(paymentMethodResponse.error){
        setPaymentError(paymentMethodResponse.error)
        return null
      } else {
        return paymentMethodResponse.paymentMethodId
      }
    }
  }, [paymentExecutable, totalChargeAmountCents])

  function storeExecutable(executable: PaymentExecutable | null) {
    setPaymentExecutable((previousState)=>{
      return executable
    })
  }

  async function handleLegacyConfirm() {
    setPaymentRequestInProgress(true)
    const paymentMethodId = await getPaymentMethodId()
    await fetchPost('/api/v2/sales_invoice/pay', {
      payment_method_id: paymentMethodId,
      credit_note_ids: selectedCreditNotes.map(cn => cn.id),
      sales_invoice_uuids: selectedInvoices.map(i => i.invoiceUUID ?? ''),
      total_invoice_balance_to_pay_cents: totalInvoiceBalanceCents,
      total_credit_note_balance_to_apply_cents: totalCreditNoteBalanceToApplyCents,
      total_charge_amount_cents: totalChargeAmountCents,
    }).then(result => {
      setPayDialogVisible(false)
      setPaymentCompleteDialogVisible(true)
      updateOpenInvoices()
      props.onRefreshRequest()
      setPaymentRequestInProgress(false)
    }).catch(e => {
      setPaymentError(getErrorText(e))
      setPaymentRequestInProgress(false)
    })
  }
  //Above can go away after ff_checkout_v2 is fully rolled out


  async function handleConfirm() {
    setPaymentRequestInProgress(true)
    await fetchPost('/api/v2/sales_invoice/pay', {
      customer_payment_method_guid: selectedSavedPaymentMethod?.guid,
      credit_note_ids: selectedCreditNotes.map(cn => cn.id),
      sales_invoice_uuids: selectedInvoices.map(i => i.invoiceUUID ?? ''),
      total_invoice_balance_to_pay_cents: totalInvoiceBalanceCents,
      total_credit_note_balance_to_apply_cents: totalCreditNoteBalanceToApplyCents,
      total_charge_amount_cents: totalChargeAmountCents,
    }).then(result => {
      setPayDialogVisible(false)
      setPaymentCompleteDialogVisible(true)
      updateOpenInvoices()
      props.onRefreshRequest()
      setPaymentRequestInProgress(false)
    }).catch(e => {
      setPaymentError(getErrorText(e))
      setPaymentRequestInProgress(false)
    })
  }

  const paymentMethodRequired = totalChargeAmountCents > 0
  const paymentMethodReady = shouldUseNewCustomerPaymentMethods ? (!!selectedSavedPaymentMethod && selectedSavedPaymentMethod.status === 'ready') : (paymentExecutable != null)

  return (
    <div className="OpenInvoices">
      {error && <div className={'error'}>{error}</div>}
      {props.mobile
        ? <InvoiceTableMobile
          statementItems={statementItems}
          sortInfo={props.sortInfo}
          setSortInfo={props.setSortInfo}
          checkboxMap={checkboxMap}
          checkboxMapUpdated={setCheckboxMap}
          dispatch={props.dispatch} state={props.state} customer={props.customer} />
        : <InvoiceTable
          statementItems={statementItems}
          sortInfo={props.sortInfo}
          setSortInfo={props.setSortInfo}
          checkboxMap={checkboxMap}
          checkboxMapUpdated={setCheckboxMap}
          dispatch={props.dispatch} state={props.state} customer={props.customer} />
      }
      <div className={classNames('actions-section', { mobile: props.mobile })}>
        <SecondaryButton disabled={statementInvoices.length === 0} onClick={selectAll}>Select All</SecondaryButton>
        <PrimaryButton disabled={noPayableInvoicesSelected} onClick={openPayDialog}>Pay Selected</PrimaryButton>
      </div>
      <Dialog className="pay-invoice-dialog" open={payDialogVisible} title='Pay Invoice' onClose={() => setPayDialogVisible(false)}>
        <div className="pay-invoice-main">
          <div className='payment-summary-container'>
            <h3 className="pay-invoice-column-header">Payment Summary</h3>
            <div className="pay-invoice-summary">
              <div className="invoice-section">
                {selectedInvoices.map(invoice => {
                  return <div className='summary-row invoice-row' key={invoice.disambiguatedId}>
                    <div>{invoice.name}</div>
                    <div>{formatStatementItemPrice(invoice.outstandingBalanceCents)}</div>
                  </div>
                })}
              </div>
              {totalCreditNoteBalanceToApplyCents > 0 && <>
                <div className="summary-divider"></div>
                <div className="summary-row subtotal-row">
                  <div>{selectedInvoices.length > 1 ? 'Invoices Total' : 'Invoice Total'}</div>
                  <div>{formatStatementItemPrice(totalInvoiceBalanceCents)}</div>
                </div>
                <div className="summary-row subtotal-row">
                  <div>
                    Less Credits
                    <div className='tooltip-wrap'>
                      <Tooltip
                        exitDelay={100}
                        placement="right"
                        title={'When you select one or more credit notes for a payment, they will be applied in order from oldest to newest, up to the total invoice amount.  If the total credit note balance exceeds the total invoice balance, credit notes can be partially used.'}
                        children={<span><FaRegCircleQuestion /></span>}
                      />
                    </div>
                  </div>
                  <div>
                    {formatStatementItemPrice(-totalCreditNoteBalanceToApplyCents)}
                  </div>
                </div>
              </>}
              <div className='summary-divider'></div>
              <div className='summary-row total-row'>
                <div>Total Due</div>
                <div>{formatStatementItemPrice(totalChargeAmountCents)}</div>
              </div>
            </div>
          </div>
          {totalChargeAmountCents > 0 && <div className='payment-method-container'>
            <h3 className='pay-invoice-column-header'>
              Payment Method
            </h3>
            {!shouldUseNewCustomerPaymentMethods && <div>
              <AddPaymentMethod emitExecutable={storeExecutable} isLoading={paymentRequestInProgress} />
            </div>}
            {shouldUseNewCustomerPaymentMethods && <div>
              <PaymentMethodSelector onPaymentMethodSelection={handlePaymentMethodSelection} paymentMethod={selectedSavedPaymentMethod} />
            </div>}
          </div>}
        </div>

        <div className="invoice-terms-and-conditions">
          <p>By clicking <b>Confirm & Pay</b>, you agree to OSH Cut's <a target="_blank" href="https://www.oshcut.com/terms-of-service/">Terms and Conditions</a>.</p>
        </div>

        <div className="payment-error error">
          {paymentError && <div><br />{paymentError}</div>}
        </div>

        <div className="pay-invoice-actions">
          <PrimaryButton disabled={(paymentMethodRequired ? (!paymentMethodReady) : false)  || paymentRequestInProgress} onClick={()=> shouldUseNewCustomerPaymentMethods ? handleConfirm() : handleLegacyConfirm()}>{paymentRequestInProgress ? <LoadingSpinner /> : 'Confirm & Pay'}</PrimaryButton>
          <SecondaryButton disabled={paymentRequestInProgress} onClick={() => {
            setPayDialogVisible(false)
            setPaymentError(null)
          }}>Cancel</SecondaryButton>
        </div>
      </Dialog>
      <Dialog className="payment-complete-dialog" open={paymentCompleteDialogVisible} title='Pay Invoice' onClose={() => setPaymentCompleteDialogVisible(false)}>
        <div>
          <div className="payment-complete-icon"><FaCheckCircle /></div>
          <div>Thanks for your payment.</div>
        </div>
      </Dialog>
    </div>
  )
}

export function getErrorText(error: unknown) {
  if (error instanceof FetchError) {
    return error.serverMessage ?? error.message
  } else if (error instanceof Error) {
    return error.message
  } else {
    return "Unknown error"
  }
}
