import { fetchPost, Order, OrderForCustomer } from '@oshcut/oshlib'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DispatchFunction } from 'reducer'
import { ParsedOrder, StateType } from 'types'
import OrderDetails from './OrderDetails'
import { useNotify } from 'hooks/useNotify'
import { getErrorText } from './InvoicesView/OpenInvoices'
import { useAlert } from 'hooks/useAlert'
import { BlockPlaceholder, Slideover } from '@oshcut/components'
import "./css/OrderDetailsRouteWrapper.scss"

type OrderDetailsRouterWrapperProps = {
  state: StateType
  dispatch: DispatchFunction
  onClose: () => void
  onOrderUpdate?: (order: ParsedOrder<OrderForCustomer>) => void
}

const refreshIntervalMs = 1000 * 60 * 5

/**
 * Looks for a sales order GUID in the route. If found, attempts to fetch the order and display `OrderDetailsSlideover`.
 * If the a sales order for the given GUID is not found, or the request otherwise errors, the user will immediately be
 * re-rerouted to `/account/quotes-orders`.
 */
const OrderDetailsRouteWrapper = ({ state, dispatch, onClose, onOrderUpdate }: OrderDetailsRouterWrapperProps) => {

  const { guid: routeGuid } = useParams<{ guid?: Order['guid'] }>()
  const { notify } = useNotify()
  const [alertContent, showAlert] = useAlert()

  if(!routeGuid) {
    onClose()
  }

  const [order, setOrder] = useState<ParsedOrder<OrderForCustomer> | null>()

  const fetchTimeoutRef = useRef<number | null>(null)
  const timesFetchedRef = useRef(0)

  const tryFetchOrder = useCallback(async () => {
    if(fetchTimeoutRef.current != null) {
      clearTimeout(fetchTimeoutRef.current)
      fetchTimeoutRef.current = null
    }

    try {
      if (!routeGuid) throw new Error("No GUID provided")

      const response = await fetchPost('/api/v2/order/get', { guid: routeGuid })
      setOrder(response.order)
      onOrderUpdate?.(response.order)
      timesFetchedRef.current += 1

      // Set timeout for 5 minutes
      fetchTimeoutRef.current = setTimeout(tryFetchOrder, refreshIntervalMs)
    } catch(e) {
      const message = getErrorText(e)

      if(timesFetchedRef.current > 0) {
        notify({
          message: `Failed to refresh this order: ${message}`,
          linkMessage: "Try again",
          linkCallback: tryFetchOrder,
          timeout: 10000
        })

        // Still try again in 5 minutes
        fetchTimeoutRef.current = setTimeout(tryFetchOrder, refreshIntervalMs)
      } else {
        let errorBoxMessage = `Failed to load this order: ${message}`

        // For some known error messages, print a friendlier error message.
        if(/Not authorized/.test(message)) {
          errorBoxMessage = "You are not authorized to view this order. Please sign in with the correct account."
        } 
        else if (/Unknown Order/.test(message)) {
          errorBoxMessage = `Unable to find an order matching "${routeGuid}"`
        }
        
        // If it failed on first attempt, show an error message and redirect
        showAlert("Error", errorBoxMessage, onClose) 
      }
    }

  }, [routeGuid, setOrder, fetchTimeoutRef, timesFetchedRef])

  useEffect(() => {
    tryFetchOrder()
    
    return () => {
      clearTimeout(fetchTimeoutRef.current ?? 0)
    }
  }, [routeGuid, tryFetchOrder, fetchTimeoutRef])

  function handleOrderUpdate(order: ParsedOrder<OrderForCustomer>) {
    setOrder(order)
    onOrderUpdate?.(order)
  }

  const slideoverTitle = useMemo(() => {
    if(!order) return null
    if(order.status === "saved") {
      return "Saved Cart"
    }
    if (order.internal_id) {
      return `Order Details: ${order.internal_id}`
    }
    return "Order Details"
  }, [order])

  return (<>
    <Slideover className="OrderDetailsSlideover" fixed title={slideoverTitle ?? <BlockPlaceholder style={{height: 20, borderRadius: 3, width: 200}} />} open onClose={onClose}>
      {order ? (

        <OrderDetails
          state={state}
          dispatch={dispatch}
          order={order}
          onOrderUpdate={handleOrderUpdate}
        />

      ) : null}
    </Slideover>

    {alertContent}
  </>)
}

export default OrderDetailsRouteWrapper