import './AddPaymentMethod.scss'
import * as React from 'react'
import { fetchPost } from '@oshcut/oshlib'
import { useCallback, useEffect, useState } from 'react'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { classNames, Input, LoadingSpinner } from '@oshcut/components'
import { PaymentMethod } from '@stripe/stripe-js/types/api/payment-methods'

export type PaymentExecutable = ()=> Promise<PaymentMethodResponse>
export type PaymentMethodResponse = {paymentMethodId: string, error?: never} | {paymentMethodId?: never, error: string}

type AddPaymentMethodProps = {
  emitExecutable: (executable:PaymentExecutable | null)=> void //this will return a function if the input is valid
  isLoading: boolean
}

export const AddPaymentMethod = (props: AddPaymentMethodProps) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [cardReady, setCardReady] = useState<boolean>(false)
  const [nameOnCard, setNameOnCard] = useState<string>('')
  const [cardError, setCardError] = useState<StripeCardElementChangeEvent['error'] | null>(null)

  const stripe = useStripe()
  const elements = useElements()



  const  paymentExecutable = useCallback(async ()=>  {
    if (!stripe || !elements || !clientSecret || !nameOnCard || !cardReady) return {error: 'Payment Error, please contact support!'}

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return {error: 'Payment Error, please contact support!'}
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: nameOnCard
        }
      },
      expand: ['payment_method'],
    } as any) // this is mad about the expand property, but it is in the stripe docs

    if (result.error) {
      return {error: result.error.message || 'Payment Error, please contact support!2'}

    }

    if (result.setupIntent == undefined || result.setupIntent.status !== 'succeeded') {
      return {error: `Unexpected status returned by carrier: ${result?.setupIntent?.status}`}
    }
    const paymentMethod = result.setupIntent.payment_method as PaymentMethod // this will be true due to the expand property in the strip call above
    if(!paymentMethod) {
      return {error: 'Unexpected error, please contact support!3'}
    }

    return { paymentMethodId: paymentMethod.id }
  }, [stripe,elements,clientSecret,nameOnCard,cardReady])

  useEffect(() => {
    fetchPost('/api/v2/payment_method/setup_intent').then(({ client_secret }) => {
      setClientSecret(client_secret)
    })
  }, [])

  useEffect(() => {
      if(!!nameOnCard && cardReady && !!paymentExecutable){
        props.emitExecutable(paymentExecutable)
      } else {
        props.emitExecutable(null)
      }
  }, [nameOnCard, cardReady, paymentExecutable])

  function handleCardElementChange(evt: StripeCardElementChangeEvent) {
    if (evt.complete) {
      setCardReady(true)
    } else {
      setCardReady(false)
    }

    if (evt.error) {
      setCardError(evt.error)
    } else {
      setCardError(null)
    }
  }

  return (
    <div className="AddPaymentMethod">
      <div className={classNames('add-payment-main', {'add-payment-loading': props.isLoading || !stripe || !elements || !clientSecret })}>
        <div>
          <label>
            <Input className="name-input" placeholder="Name on card" value={nameOnCard} onBlur={(evt, parsed, value)=> setNameOnCard(parsed)} />
          </label>
        </div>
        <div>
          <div className="stripe-input-wrapper">
            <CardElement options={{ hidePostalCode: true }} onChange={handleCardElementChange} />
          </div>
          <span className="add-payment-card-error">{cardError?.message}</span>
        </div>
      </div>
      <div className={classNames('add-payment-loading-spinner', {'add-payment-loading':  props.isLoading || !stripe || !elements || !clientSecret})}><LoadingSpinner /></div>
    </div>
  )
}
