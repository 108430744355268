import './PaymentMethodCard.scss'
import { classNames, SecondaryButton } from '@oshcut/components'
import { CustomerPaymentMethod } from '@oshcut/oshlib'
import { MouseEvent, useContext, useMemo } from 'react'
import { PaymentMethodsContext } from './PaymentMethodsContext'

type PaymentMethodCardProps = {
  paymentMethod: CustomerPaymentMethod
  disabled?: boolean
  noBoxShadow?: boolean
  transparentBorder?: boolean
  hideVerificationButton?: boolean
}

export const PaymentMethodCard = ({ paymentMethod, disabled = false, noBoxShadow = false, transparentBorder = false, hideVerificationButton = false }: PaymentMethodCardProps) => {
  const {navigation} = useContext(PaymentMethodsContext)
  const pi = paymentMethod

  const primary = useMemo(() => {
    switch (pi.type) {
      case 'credit_card':
        return `Card ending in ${pi.last4}`
      case 'ach_bank_debit':
        return `Bank Account ending in ${pi.last4}`
      default:
        const _exhaustiveCheck: never = pi.type
    }

  }, [paymentMethod])

  function handlePendingVerificationClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    e.stopPropagation()
    navigation.confirmBankAccount(pi.guid)
  }

  return (
    <div className={classNames('PaymentCard', {'disabled-appearance': disabled, 'no-box-shadow': noBoxShadow, 'transparent-border': transparentBorder})}>
      <div className='type-tag-wrapper'>
        {pi.status === 'pending_verification' && <div className='type-tag tag-pending'>PENDING VERIFICATION</div>}
        {/*{!!pi.is_primary && <div className='type-tag tag-primary'>PRIMARY</div>}*/}
        {/*{!!pi.is_backup && <div className='type-tag tag-backup'>Backup</div>}*/}
      </div>
      <div className='info-wrapper'>
        <h3 className='card-title'>{primary}</h3>
        <div className='row-1'>
          <div>{pi.nickname}</div>
        </div>
        {!hideVerificationButton && pi.status === 'pending_verification' && <div><SecondaryButton onClick={handlePendingVerificationClick}>Verify Bank Account</SecondaryButton></div>}
      </div>
    </div>
  )
}
