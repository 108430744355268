import { CustomerMaterialSheet, CustomerMaterialTube, CustomerMaterialType } from "@oshcut/oshlib"
import { useMemo } from "react"
import { CatalogDispatchFunction, CatalogStateType } from "./Catalog"
import { RecentMaterialsView } from "./RecentMaterialsView"
import { useCatalogParams } from "./useCatalogParams"
import { matchTube } from "./useFilter"

type PropType = {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction
}

export function RecentMaterialsWrapper({ state, dispatch }: PropType) {

  const params = useCatalogParams(state)

  const recentMaterialSheets = useMemo(() => {
    if (!state.recentSheetIds || !state.materialSheets) return undefined
    if (params.category !== 'sheet') return []
    return state.recentSheetIds
      .map(id => state.materialSheets?.find(s => s.id === id))
      .filter((x): x is CustomerMaterialSheet => !!x)
  }, [state.recentSheetIds, state.materialSheets])

  const recentMaterialTubes = useMemo(() => {
    if (!state.recentTubeIds || !state.materialTubes || !state.materialTypes) return undefined
    if (params.category !== 'tube') return []
    return state.recentTubeIds
      .map(id => {
        let materialTube = state.materialTubes?.find(t => t.id === id)
        let materialType = state.materialTypes?.find(m => m.material_id === materialTube?.material_id)
        if (materialTube && materialType) {
          return { ...materialTube, ...materialType }
        }
      })
      .filter((x): x is CustomerMaterialTube & CustomerMaterialType => !!x)
      .filter(t => matchTube(t, state.filter))

  }, [state.recentTubeIds, state.materialTubes, state.materialTypes, state.filter])

  return recentMaterialSheets && recentMaterialTubes && state.materialTypes ?
    <RecentMaterialsView
      state={state}
      dispatch={dispatch}
      materialSheets={recentMaterialSheets}
      materialTubes={recentMaterialTubes}
      materialTypes={state.materialTypes}
    /> : null

}