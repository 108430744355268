/**
 * Formats a number of dollars as a string.
 * @example
 * formatPrice(5) // "$5.00"
 * formatPrice(-5) // "-$5.00"
 * formatPrice(123.45) // "$123.45"
 * formatPrice(-123.45) // "-$123.45"
 * formatPrice(2.71828) // "$2.72"
 * formatPrice(-2.71828) // "-$2.72"
 * formatPrice(0) // "$0.00"
 * formatPrice(-0) // "$0.00"
 * formatPrice(-0.00001) // "$0.00"
 * formatPrice(null) // "$-.--"
 * @param dollars The number of dollars to format.
 * @returns The formatted price.
 */
export function formatPrice(dollars?: number | null) {
  if (typeof dollars !== 'number' || !Number.isFinite(dollars)) return "$-.--";

  dollars = Math.round(dollars * 100) / 100;
  if (dollars < 0) {
    return "-$" + Math.abs(dollars).toFixed(2);
  } else {
    return "$" + dollars.toFixed(2);
  }
}

export function formatPriceCents(cents?: number | null) {
  return formatPrice((cents ?? 0) / 100)
}