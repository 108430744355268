import { ClipperLib } from "@oshcut/oshlib"
import Log from "./logs"

const offsetCache = {}

/**
 * Computes the offset polygon of a contour. Will cache generated polygons by part id, contour id, and contour type.
 * @param {*} partId Id of the part. Used as key for caching offset polys.
 * @param {*} contour Contour to generate the offset for
 * @param {*} offset The distance of the offset. Inner contours will automatically negate this value before computing the offset.
 * @returns {{[Polyline], Bbox}} Returns an array of offset polys and the bounding box.
 */
export function computeOffsetPolys(partId, contour, offset) {


  // TODO: Cache polys by partId, contour.id, offset, and contour.type (since the type can change)
  const key1 = partId
  const key2 = contour.id
  const key3 = contour.type
  const key4 = offset

  // if (key.includes('undefined') || key.includes('null') || key.includes('--')) {
  //   throw new Error('computeOffsetPolys called with invalid arguments, resulting in a key containing one or more of the following substrings: "undefined", "null", or "--". The key is: ' + key)
  // }

  // TODO: Delete old entries
  // if (Object.keys(offsetCache).length > 1000) {
  //   for (let k in offsetCache) {
  //     if (Date.now() - offsetCache[k].timestamp > 300000 && k !== key) {
  //       Log.info('Deleting from cache key ' + k)
  //       delete offsetCache[k]
  //     }
  //   }
  // }

  let cachedResult = offsetCache[key1]?.[key2]?.[key3]?.[key4]

  if (!cachedResult) {

    let offsetPolys, offsetBbox
    if (contour.type === 'inner' || contour.type === 'outer') {

      let poly
      if (contour.type === 'outer' && contour.poly.length <= 3) {
        poly = contour.finePoly
      } else {
        poly = contour.poly
      }

      const clipPoly = poly.map(p => ({ X: p.x, Y: p.y }))
      clipPoly.splice(0, 1) // Our closed polygons repeat first and last points, clipper's do not
      if (contour.type === 'inner') {
        offset *= -1
      }
      const miterLimit = 1
      const curveTolerance = 0.05
      const co = new ClipperLib.ClipperOffset(miterLimit, curveTolerance)
      // Round join type prevents offset polys from penetrating others when we use the NFP
      co.AddPath(clipPoly, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etClosedPolygon)

      const newpaths = new ClipperLib.Paths()
      co.Execute(newpaths, offset)
      // Offsetting a path could result in multiple closed paths
      offsetPolys = newpaths.map(np => {
        let newNp = np.map(p => ({ x: p.X, y: p.Y }))
        newNp.push(newNp[0]) // Our closed polygons repeat first and last points, clipper's do not
        return newNp
      })
      offsetBbox = {
        min: { x: contour.bbox.min.x - offset, y: contour.bbox.min.y - offset },
        max: { x: contour.bbox.max.x + offset, y: contour.bbox.max.y + offset },
      }
    }
    const key = `${key1}-${key2}-${key3}-${key4}`
    Log.info('generated offset polys for key ' + key)

    if (!offsetCache[key1]) offsetCache[key1] = {}
    if (!offsetCache[key1][key2]) offsetCache[key1][key2] = {}
    if (!offsetCache[key1][key2][key3]) offsetCache[key1][key2][key3] = {}
    cachedResult = { offsetPolys, offsetBbox, key, timestamp: Date.now() }
    offsetCache[key1][key2][key3][key4] = cachedResult
  }

  return cachedResult
}

/**
 * Invalidates all cached offset polys for the given part. Useful if the part geometry changes.
 * @param {*} partId Id of the part
 */
export function invalidateOffsetPolys(partId) {
  const key1 = partId
  delete offsetCache[key1]

}