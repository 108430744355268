import React from 'react'
import { useHistory } from 'react-router'
import { fetchPost } from '@oshcut/oshlib'
import './css/RefreshPrompt.scss'
import config from 'config'

export function RefreshPrompt({ app }) {

  const history = useHistory()

  const [showRefreshPrompt, setShowRefreshPrompt] = React.useState(false)

  React.useEffect(() => {

    // Retrieve version every 10 minutes. If the version differs, show the prompt.
    const intervalHandle = setInterval(async () => {
      try {
        let { version } = await fetchPost('/api/v2/versions', { app, mine: config.appVersion })
        if (version !== config.appVersion && version !== 'unknown') {
          setShowRefreshPrompt(true)
        }
      }
      catch (e) { }
    }, 600000)

    // Remove timer on unmount
    return () => {
      clearInterval(intervalHandle)
    }
  }, [])

  function handleReload() {
    history.go(0)
  }

  return showRefreshPrompt ? (
    <div className="RefreshPrompt">
      <div className="RefreshPromptText">
        A new version of this page is available.
        <br />
        Please <span className='link' onClick={handleReload}>reload</span> for the latest features.
      </div>
    </div>
  ) : null
}