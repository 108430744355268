import React from 'react'
import './css/LinkAnimator.scss'

export function LinkAnimator(props) {

  return (
    <div className={`linkAnimator ${props.message ? 'active' : ''}`}>
       {/* <div className='linkAnimatorArrow'></div> */}
      <div className='linkAnimatorBurst'></div>
      
       <div className='linkAnimatorMessage'>
        🠝 {props.message}
       </div>
    </div>
  )
}