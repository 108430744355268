import { GiCheckMark } from "react-icons/gi"
import "./OshcutServiceCard.scss"

type OshcutServiceCardProps = {
  url: string,
  image: string,
  title: string,
  subtitle: string,
  list: string[]
}

export const OshcutServiceCard = ({ url, image, title, subtitle, list }: OshcutServiceCardProps) => {
  return (
    <a className="OshcutServiceCard" href={url}>
      <div className="OshcutServiceCard-Image">
        <img src={image} alt={title} />
      </div>
      <div className="OshcutServiceCard-Body">
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <div className="OshcutServiceCard-List">
          {list.map((item, i) => <div key={i} className="OshcutServiceCard-ListItem">
            <div className="OshcutServiceCard-Checkmark">
              <GiCheckMark />
            </div>
            <p>{item}</p>
          </div>)}
        </div>
      </div>
    </a>
  )
}



export default OshcutServiceCard