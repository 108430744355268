import { useEffect, useState, createContext, ReactNode, useMemo } from 'react'
import { Customer, CustomerPaymentMethod, CustomerUser, fetchPost } from '@oshcut/oshlib'
import { useHistory } from 'react-router-dom'


type LocalPaymentMethodUpdateObject = Pick<CustomerPaymentMethod, 'guid' | 'nickname'>

type BankVerificationAmountObject = {
  guid: string
  microdepositType: 'amounts'
  firstDeposit: number
  secondDeposit: number
}
type BankVerificationDescriptorObject = {
  guid: string
  microdepositType: 'descriptor_code'
  descriptorCode: string
}
export type BankVerificationObject = BankVerificationAmountObject | BankVerificationDescriptorObject

type PaymentMethodContextDef = {

  paymentMethods: CustomerPaymentMethod[]
  createPaymentMethod: (setupIntentId: string, paymentMethodType: CustomerPaymentMethod['type'], nickname: string | null) => Promise<CustomerPaymentMethod>
  updatePaymentMethod: (draftPaymentMethod: LocalPaymentMethodUpdateObject) => Promise<void>
  removePaymentMethod: (guid: string) => Promise<void>
  verifyBankAccount: (verificationObject: BankVerificationObject) => Promise<void>
  customer: Customer
  customerUser: CustomerUser
  loading: boolean
  navigation: {
    listView: () => void
    addNew: () => void
    edit: (guid: string) => void
    remove: (guid: string) => void
    confirmBankAccount: (guid: string) => void
  }

}

export const PaymentMethodsContext = createContext<PaymentMethodContextDef>({
  paymentMethods: [],
  createPaymentMethod: () => Promise.reject('PaymentMethodsContext not initialized'),
  updatePaymentMethod: () => Promise.reject('PaymentMethodsContext not initialized'),
  removePaymentMethod: () => Promise.reject('PaymentMethodsContext not initialized'),
  verifyBankAccount: () => Promise.reject('PaymentMethodsContext not initialized'),
  customer: {} as Customer,
  customerUser: {} as CustomerUser,
  loading: false,
  navigation: {
    listView: () => {
      throw new Error('PaymentMethodsContext not initialized')
    },
    addNew: () => {
      throw new Error('PaymentMethodsContext not initialized')
    },
    edit: (guid: string) => {
      throw new Error('PaymentMethodsContext not initialized')
    },
    remove: (guid: string) => {
      throw new Error('PaymentMethodsContext not initialized')
    },
    confirmBankAccount: (guid: string) => {
      throw new Error('PaymentMethodsContext not initialized')
    }
  }
})

const historyNotInitialized = () => {
  console.error('history hook not initialized!')
}

export function PaymentMethodsProvider({ children, customer, customerUser }: { children: ReactNode, customer: Customer, customerUser: CustomerUser }) {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [paymentMethods, setPaymentMethods] = useState<CustomerPaymentMethod[]>([])

  useEffect(() => {
    setLoading(true)
    fetchPost('/api/v2/payment_method/list')
      .then(res => {
        setPaymentMethods(res.paymentMethods)
      })
      .finally(() => setLoading(false))
  }, [])

  const sortedPaymentMethods = useMemo(() => {
    if (!paymentMethods) return []
    const copy = new Array(...paymentMethods)
    return copy.sort((a, b) => {
      return a.id > b.id ? -1 : 1
    })
  }, [paymentMethods])

  function handlePaymentMethodUpdates(updates: CustomerPaymentMethod[]) {
    const map = new Map(paymentMethods.map(pm => [pm.id, pm]))
    updates.forEach(pm => map.set(pm.id, pm))
    setPaymentMethods(new Array(...map.values()))
  }

  async function createPaymentMethod(setupIntentId: string, paymentMethodType: CustomerPaymentMethod['type'], nickname: string | null) {
    setLoading(true)
    const data = {
      stripe_setup_intent_id: setupIntentId,
      payment_method_type: paymentMethodType,
      nickname: nickname
    }
    return fetchPost('/api/v2/payment_method/create', data)
      .then(({ newPaymentMethod }) => {
        setPaymentMethods([newPaymentMethod, ...paymentMethods])
        return newPaymentMethod
      })
      .finally(() => setLoading(false))
  }

  async function updatePaymentMethod(draftPaymentMethod: LocalPaymentMethodUpdateObject) {
    setLoading(true)
    const { guid, nickname} = draftPaymentMethod
    await fetchPost('/api/v2/payment_method/update', {guid, nickname: nickname == null ? undefined : nickname})
      .then(result => result.updatedPaymentMethod)
      .then(updatedMethod => setPaymentMethods(paymentMethods.with(paymentMethods.findIndex(pm => pm.guid === updatedMethod.guid), updatedMethod)))
      .finally(() => setLoading(false))
  }

  async function removePaymentMethod(guid: string) {
    setLoading(true)
    await fetchPost('/api/v2/payment_method/delete', { guid })
      .then(result => setPaymentMethods(paymentMethods.filter(pm => pm.guid !== result.guid)))
      .finally(() => setLoading(false))
  }

  async function verifyBankAccount(verificationObject: BankVerificationObject) {
    setLoading(true)
    await fetchPost('/api/v2/payment_method/verify_bank_account', verificationObject)
      .then(result => handlePaymentMethodUpdates(result.customerPaymentMethodUpdates))
      .finally(() => setLoading(false))
  }

  const navigation = useMemo(() => {
    if (!history) {
      return {
        listView: historyNotInitialized,
        addNew: historyNotInitialized,
        edit: historyNotInitialized,
        remove: historyNotInitialized,
        confirmBankAccount: historyNotInitialized
      }
    } else {
      return {
        listView: () => history.push('/account/payment_methods'),
        addNew: () => history.push('/account/payment_methods/add_new'),
        edit: (guid: string) => history.push(`/account/payment_methods/${guid}/edit`),
        remove: (guid: string) => history.push(`/account/payment_methods/${guid}/remove`),
        confirmBankAccount: (guid: string) => history.push(`/account/payment_methods/${guid}/verify_bank_account`)
      }
    }

  }, [history])

  const providerValue: PaymentMethodContextDef = {
    paymentMethods: sortedPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    removePaymentMethod,
    verifyBankAccount,
    customer,
    customerUser,
    loading,
    navigation
  }

  return (
    <PaymentMethodsContext.Provider value={providerValue}>
      {children}
    </PaymentMethodsContext.Provider>
  )

}
