import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { GlobalLoadingOverlay } from '../components/GlobalLoadingOverlay'
import { useAlert } from './useAlert'

type OptionType = { timeoutMessage?: string, callback?: () => void }

export function useLoadingOverlay() {
  const timer = useRef<any>(null)
  const [option, setOption] = useState<OptionType>({})
  const [reason, setReason] = useState<ReactNode>('')
  const [show, setShow] = useState(false)
  const [_progress, _setProgress] = useState<number | null>(null)

  const [alertContent, showAlert] = useAlert()

  function showLoadingOverlay(reason: ReactNode, timeout?: number, options?: OptionType) {
    clearTimeout(timer.current)
    if (options) {
      setOption(options)
    }
    else {
      setOption({})
    }

    if (timeout) {
      timer.current = setTimeout(handleTimeout, timeout)
    }
    _setProgress(null)
    setReason(reason)
    setShow(true)
  }

  function hideLoadingOverlay() {
    clearTimeout(timer.current)
    setShow(false)
  }

  function setProgress(progress: number) {
    _setProgress(progress)
  }

  function handleTimeout() {

    setShow(false)
    if (option.timeoutMessage) {
      showAlert('Timeout', option.timeoutMessage)
    }

    if (option.callback) {
      option.callback()
    }
  }

  const content = show ? <GlobalLoadingOverlay reason={reason} progress={_progress} /> : null
  const combinedContent = <>{content}{alertContent}</>

  return [combinedContent, showLoadingOverlay, hideLoadingOverlay, setProgress] as const
}
