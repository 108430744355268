import React, { useCallback, useContext, useMemo } from 'react'
import { Notification } from '../components/Notification'

const NotifyContext = React.createContext<{ notify: NotifyFunction }>({ notify: null as unknown as NotifyFunction })

export type NotifyFunction = ({
  message, timeout, linkMessage, linkCallback
}: {
  message: string,
  timeout?: number,
  linkMessage?: string,
  linkCallback?: () => void | Promise<void> | (() => void | Promise<void>)
}) => void

export function useNotify() {
  const { notify } = useContext(NotifyContext)
  if (!notify) throw new Error('useNotify must be used within a NotifyProvider')
  return { notify }
}

export function NotifyProvider({ children }: { children: React.ReactNode }) {

  const [items, setItems] = React.useState<{
    message: string,
    timeout?: number,
    linkMessage?: string,
    linkCallback?: () => void | Promise<void> | (() => void | Promise<void>)
    id: number,
    onClose: () => void,
    onLink: () => void
  }[]>([])

  /** Auto-incrementing id */
  const autoIncId = React.useRef(0)

  const notify: NotifyFunction = useCallback(({
    message, timeout, linkMessage, linkCallback
  }) => {

    const onClose = () => {
      setItems(items => {
        return items.filter(i => i.id !== newItem.id)
      })
    }

    const onLink = () => {
      if (linkCallback && linkMessage) {
        onClose()
        newItem.linkCallback?.()
      }
    }

    let newItem = {
      message,
      timeout,
      linkMessage,
      linkCallback,
      id: autoIncId.current++,
      onClose,
      onLink
    }

    setTimeout(newItem.onClose, timeout ?? 5000)

    setItems(items => [newItem, ...items])
  }, [])

  const content = useMemo(() => items.map((item, index) => <Notification key={item.id} item={item} index={index} />), [items])

  return (
    <NotifyContext.Provider value={{ notify }}>
      {children}
      {content}
    </NotifyContext.Provider>
  )

}
