import React, { ReactNode, useEffect, useState } from 'react'
import { fetchPost } from '@oshcut/oshlib'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import Log from '../../logs'
import { LoadingSpinner, PrimaryButton } from '@oshcut/components'
import './ElementsWrapper.scss'
import { ErrorCard } from './ErrorCard'
import { PaymentMethodActionsWrapper } from './PaymentMethodActionsWrapper'

let key = 'pk_live_FWbuGoKAts01e1EIglKH6sdq'
if (window.location.href.indexOf('localhost') >= 0
  || window.location.href.indexOf('dev3.oshcut.com') >= 0
  || window.location.href.indexOf('sand.oshcut.com') >= 0) {
  key = 'pk_test_Yntq8iG12Ms1xw6FuvfdHqx4'
  Log.info('Using test API key.')
}

const stripePromise = loadStripe(key)

type props = {
  children: ReactNode
  onErrorExitClick: () => void
}

export function ElementsWrapper({ children, onErrorExitClick }: props) {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!clientSecret) {
      fetchPost('/api/v2/payment_method/setup_intent')
        .then(({ client_secret }) => setClientSecret(client_secret))
        .catch(e => setError(e.serverMessage ?? e.message ?? 'An unknown error occurred.'))
    }
  }, [clientSecret])

  if(!!error) return (
    <div className="ElementsWrapper">
      <ErrorCard>{error}</ErrorCard>
      <PaymentMethodActionsWrapper>
        <PrimaryButton onClick={onErrorExitClick}>Return</PrimaryButton>
      </PaymentMethodActionsWrapper>
    </div>
  )

  if (!clientSecret) return (
    <div className="ElementsWrapper">
    <LoadingSpinner />
    </div>
  )

  return (
    <div className="ElementsWrapper">
      <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
        {children}
      </Elements>
    </div>
  )
}