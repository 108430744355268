import { CustomerMaterialTube } from "@oshcut/oshlib"
import { roundShapes } from "./useFilter"

type PropType = {
  materialTube: CustomerMaterialTube
  className?: string
}

export function MaterialTubeThumbnail({ materialTube, className }: PropType) {

  const margin = 0

  let maxSize = 100 - margin * 2
  if (['Rectangular', 'Square'].includes(materialTube.shape)) {

    let scale = Math.min(maxSize / materialTube.profile_size_x, maxSize / materialTube.profile_size_y)
    let width = materialTube.profile_size_x * scale
    let height = materialTube.profile_size_y * scale
    if (height > width) {
      let temp = height
      height = width
      width = temp
    }
    let x = margin + (maxSize - width) / 2
    let y = margin + (maxSize - height) / 2
    let r = (materialTube.corner_radius ?? 0) * scale
    let w = materialTube.wall_thickness * scale

    if (r <= 0) {
      // No corner radius
      return <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
        <path
          d={`M${rnd(x)},${rnd(y)}V${rnd(y + height)}H${rnd(x + width)}V${rnd(y)}H${rnd(x)}M${rnd(x + w)},${rnd(y)}V${rnd(y + height - w)}H${rnd(x + width - w)}V${rnd(y + w)}H${rnd(x + w)}`}
          fill="#3E4C59"
          fillRule="evenodd"
        />
      </svg>
    } else if (r <= w) {
      // Corner radius on outer rectangle
      return <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
        <path
          d={`M${rnd(x)},${rnd(y + r)}V${rnd(y + height - r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + r)},${rnd(y + height)}H${rnd(x + width - r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + width)},${rnd(y + height - r)}V${rnd(y + r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + width - r)},${rnd(y)}H${rnd(x + r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x)},${rnd(y + r)}M${rnd(x + w)},${rnd(y + r)}V${rnd(y + height - w)}H${rnd(x + width - w)}V${rnd(y + w)}H${rnd(x + w)}`}
          fill="#3E4C59"
          fillRule="evenodd"
        />
      </svg>
    } else {
      // Corner radius on both rectangles
      return <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
        <path
          d={`M${rnd(x)},${rnd(y + r)}V${rnd(y + height - r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + r)},${rnd(y + height)}H${rnd(x + width - r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + width)},${rnd(y + height - r)}V${rnd(y + r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x + width - r)},${rnd(y)}H${rnd(x + r)}A${rnd(r)},${rnd(r)} 0 0 0 ${rnd(x)},${rnd(y + r)}M${rnd(x + w)},${rnd(y + r)}V${rnd(y + height - r)}A${rnd(r - w)},${rnd(r - w)} 0 0 0 ${rnd(x + r)},${rnd(y + height - w)}H${rnd(x + width - r)}A${rnd(r - w)},${rnd(r - w)} 0 0 0 ${rnd(x + width - w)},${rnd(y + height - r)}V${rnd(y + r)}A${rnd(r - w)},${rnd(r - w)} 0 0 0 ${rnd(x + width - r)},${rnd(y + w)}H${rnd(x + r)}A${rnd(r - w)},${rnd(r - w)} 0 0 0 ${rnd(x + w)},${rnd(y + r)}`}
          fill="#3E4C59"
          fillRule="evenodd"
        />
      </svg>
    }

    // Readable version of the SVG path for corner radius on both rectangles:
    // d={`
    //   M${x},${y + r}
    //   V${y + height - r} A${r},${r} 0 0 0 ${x + r},${y + height}
    //   H${x + width - r} A${r},${r} 0 0 0 ${x + width},${y + height - r}
    //   V${y + r} A${r},${r} 0 0 0 ${x + width - r},${y}
    //   H${x + r} A${r},${r} 0 0 0 ${x},${y + r}

    //   M${x + w},${y + r}
    //   V${y + height - r} A${r - w},${r - w} 0 0 0 ${x + r},${y + height - w}
    //   H${x + width - r} A${r - w},${r - w} 0 0 0 ${x + width - w},${y + height - r}
    //   V${y + r} A${r - w},${r - w} 0 0 0 ${x + width - r},${y + w}
    //   H${x + r} A${r - w},${r - w} 0 0 0 ${x + w},${y + r}
    // `}

  }

  else if (roundShapes.includes(materialTube.shape)) {
    let scale = maxSize / materialTube.profile_size_x
    let radius = materialTube.profile_size_x * scale / 2
    let x = margin + radius
    let y = margin + radius
    let w = materialTube.wall_thickness * scale
    return <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
      <path
        d={`M${rnd(x)},${rnd(y - radius)}A${rnd(radius)},${rnd(radius)} 0 1 1 ${rnd(x)},${rnd(y + radius)}A${rnd(radius)},${rnd(radius)} 0 1 1 ${rnd(x)},${rnd(y - radius)}M${rnd(x)},${rnd(y - radius + w)}A${rnd(radius - w)},${rnd(radius - w)} 0 1 1 ${rnd(x)},${rnd(y + radius - w)}A${rnd(radius - w)},${rnd(radius - w)} 0 1 1 ${rnd(x)},${rnd(y - radius + w)}`}
        fill="#3E4C59"
        fillRule="evenodd"
      />
    </svg>

    // Readable version of the SVG path for a round tube:
    // d={`
    //   M${x},${y - radius}
    //   A${radius},${radius} 0 1 1 ${x}, ${y + radius}
    //   A${radius},${radius} 0 1 1 ${x}, ${y - radius}
    //   M${x},${y - radius + w}
    //   A${radius - w},${radius - w} 0 1 1 ${x},${y + radius - w}
    //   A${radius - w},${radius - w} 0 1 1 ${x},${y - radius + w}
    // `}
  }

  else {
    return null
  }


}

function rnd(x: number) {
  return +x.toFixed(2)
}