import React from 'react'
import PropTypes from 'prop-types'
import { mul, add } from '@oshcut/oshlib'
import { renderPc, clearRenderCache } from '../renderPc'

PartThumbnailRenderer.propTypes = {

  /** The part to be rendered. */
  part: PropTypes.object,

  /** Array of contour id and color pairs. When provided, will fill the specified outer contours with the specified color, overriding the default fill color. */
  contourFillColors: PropTypes.arrayOf(PropTypes.shape({ contourId: PropTypes.number, color: PropTypes.string })),

  /** If a part has a rawEntities property, will render those raw entities alongside the part. */
  showRawEntities: PropTypes.bool,

  width: PropTypes.number,

  height: PropTypes.number

}

/**
 * 
 * @param {Object} props
 */
export function PartThumbnailRenderer({
  part,
  showRawEntities,
  contourFillColors,
  width,
  height
}) {

  /**** View state ****/

  /** scale or zoom of the drawing canvas, in pixels per inch */
  const [scale, setScale] = React.useState(6)

  /** pan of the drawing canvas, in pixels */
  const [pan, setPan] = React.useState({ x: 10, y: 10 })   // pixels

  /**** Canvas refs ****/

  /** ref to the main drawing canvas */
  const drawingRef = React.useRef()

  /**
   * This effect sets the pan and scale. Runs whenever viewBbox changes.
   *
   */
  React.useEffect(() => {
    if (part.arcified.bbox) {

      const drawingCanvas = drawingRef.current

      // First, determine the scale that will put viewBbox just barely inside the canvas.
      const scaleIfXAtLimit = drawingCanvas.width / (part.arcified.bbox.max.x - part.arcified.bbox.min.x)
      const scaleIfYAtLimit = drawingCanvas.height / (part.arcified.bbox.max.y - part.arcified.bbox.min.y)
      const _scale = Math.min(scaleIfXAtLimit, scaleIfYAtLimit) * 0.95

      // Next, determine the pan to center it in the canvas.
      const centerOfDrawing = mul(add(part.arcified.bbox.min, part.arcified.bbox.max), 0.5)
      const centerOfWindow = { x: drawingCanvas.width / 2, y: drawingCanvas.height / 2 }

      // When applying inchToPixel, the centerOfDrawing should equal centerOfWindow
      const _pan = {
        x: centerOfWindow.x - centerOfDrawing.x * _scale,
        y: drawingCanvas.height - centerOfWindow.y - centerOfDrawing.y * _scale
      }

      setScale(_scale)
      setPan(_pan)
    }

  }, [part.arcified.bbox])

  /**
    * Converts the given point from pixel (screen) coordinates to inches
    * @param {Point} pPixel An object with x and y properties
    * @returns {Point} The transformed point in inches
    */
  const pixelToInch = React.useCallback((pPixel) => {
    // if (!pan || !scale || !height) throw new Error('Expected four arguments in pixelToInch')
    return {
      x: (pPixel.x - pan.x) / scale,
      y: ((height - pPixel.y) - pan.y) / scale
    }
  }, [height, pan.x, pan.y, scale])

  /**
   * Converts the given point from inches to pixel (screen) coordinates
   * @param {Point} pInch An object with x and y properties
   * @returns {Point} The transformed point in pixels
   */
  const inchToPixel = React.useCallback((pInch) => {
    // if (!pan || !scale || !height) throw new Error('Expected four arguments in inchToPixel')
    return {
      x: pInch.x * scale + pan.x,
      y: height - (pInch.y * scale + pan.y)
    }
  }, [height, pan.x, pan.y, scale])


  /**** Rendering ****/


  // Render everything
  React.useEffect(function renderEverything() { // Using a named function to make it easier to find and to profile :-)

    const kerf = part.arcified.material?.kerf ?? 0.006

    const drawingCanvas = drawingRef.current
    let ctx = drawingCanvas.getContext('2d')
    ctx.clearRect(0, 0, width, height)

    // Get visible area
    let visibleArea = {
      min: pixelToInch({ x: 0, y: height }, pan, scale, height),
      max: pixelToInch({ x: width, y: 0 }, pan, scale, height)
    }

    // Push transformation matrix: items drawn in inches will be rendered to screen coordinates
    ctx.save()
    // Don't rearrange these!!! Took forever to get them right....
    ctx.translate(0, height)
    ctx.scale(1, -1)
    ctx.translate(pan.x, pan.y)
    ctx.scale(scale, scale)

    // We're in inchland now


    let partProperties = [
      {
        partId: part.id,
        contourProperties: part.arcified.contours.map(c => ({ contourId: c.id, cuttingMethod: c.cuttingMethod, positions: [] }))
      }
    ]

    // Draw parts
    // Log.info(partCoords)

    clearRenderCache()

    renderPc({
      ctx,
      pc: { position: { x: 0, y: 0 }, rotation: 0, partId: part.id },
      parts: [part],
      partProperties,
      scale,
      showCutPaths: false,
      kerf,
      showRawEntities,
      contourFillColors,
      selectedContours: []
    })



    // Draw any other items in inch coordinates here (but that don't require the partCoord-specific rotation and position)

    // Pop matrix
    ctx.restore()

    // We're back in pixelworld now
  },
    [
      pixelToInch,
      inchToPixel,
      height,
      pan,
      part.arcified.version,
      part.id,
      scale,
      width,
    ])

  return (
    <canvas ref={drawingRef}
      style={{ width: '100%' }}
      width={width}
      height={height} />
  )

}


