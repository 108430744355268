import { drawContour, drawPolyline, drawBbox } from "./drawContour"
import update from 'immutability-helper'
import { offsetContourCrude } from "./offsetContour"
import { areContoursSimilar, mapLeadInPositions } from "./similarContourHelper"
import { createCutPathsFull } from "./createCutPaths"
import constants from "./constants"
import { traverseContour } from "./traverseContour"
import {
  mul, norm, add, normalize, sub,
  getPointTangentOfEntity
} from "@oshcut/oshlib"
import { numberToAlpha } from "./numberToAlpha"
import { computeOffsetPolys } from "./computeOffsetPolys"
import { findPart } from "./findPart"
import Log from "./logs"
import unitScale from "./unitScale"

// Render each pc onto the context
let renderCache = {}

export function clearRenderCache() {
  renderCache = {}
}

export function renderPc({
  ctx,
  pc,
  parts,
  partProperties,
  scale,
  sheet,
  rotatingPartsDeltaRotation,
  draggingLeadIn,
  selDecs,
  selectedContours,
  showContourOrder,
  showCutPaths = true,
  showBendAllowanceRegions = true,
  kerf,
  debug,
  showRawEntities,
  contourFillColors,
  cache = true,
  local = true,
  bendRegions
}) {


  // Key will be all parameters that could affect the appearance of this part (except position and rotation)
  const part = findPart(parts, pc)
  let key = `${pc.partId}-${!!pc._isSelected}-${(!!pc._isColliding) || (!!pc._isOffSheet)}`

  // This will be used to translate the part to put it in the center of the local canvas
  let partTrans = {
    x: -part.arcified.bbox.min.x + 0.5,
    y: -part.arcified.bbox.min.y + 0.5
  }

  let _width = (part.arcified.bbox.max.x - part.arcified.bbox.min.x + 1) * scale
  let _height = (part.arcified.bbox.max.y - part.arcified.bbox.min.y + 1) * scale

  let useLocalCanvas = _width * _height < 1e6 // If the part is not too big, render it to an offscreen (local) canvas

  useLocalCanvas &= local

  if (!renderCache[key] || !useLocalCanvas || !cache) {
    // Render this part onto a local canvas. We can then draw the local canvas as many times as we want on the main canvas very quickly.


    let localCanvas

    let targetCtx // The part will be rendered to this context
    if (useLocalCanvas && cache) {

      // If rendering onto a local canvas, we'll do the transformations later on

      localCanvas = document.createElement('canvas')
      localCanvas.width = _width
      localCanvas.height = _height
      let localCtx = localCanvas.getContext('2d')
      localCtx.scale(scale, scale) // Render in native pixel/pixel resolution
      localCtx.translate(partTrans.x, partTrans.y) // Position the part in the center of the local canvas
      targetCtx = localCtx
    } else {

      targetCtx = ctx

      // If rendering directly onto the canvas, do all the transformations up front

      // if (pc._isSelected && translatingPartsDeltaDrag) {
      //   // Draw this part offset while dragging
      //   ctx.save()
      //   ctx.translate(translatingPartsDeltaDrag.x, translatingPartsDeltaDrag.y)
      // }
      if (pc._isSelected && rotatingPartsDeltaRotation) {
        // Draw this part rotated while rotating
        ctx.save()
        ctx.translate(selDecs.rotationCenter.x, selDecs.rotationCenter.y)
        ctx.rotate(rotatingPartsDeltaRotation)
        ctx.translate(-selDecs.rotationCenter.x, -selDecs.rotationCenter.y)
      }


      // Push second transformation matrix: parts will be rotated and translated

      // These last two still may not be right....
      // TODO: They are not perfect. It would be nice to place center of rotation at geometric center of part. We should be able to do that with two additional translations. But, maybe best to leave it now that we've been doing manual transformations in other places (like hitTest and getTransformedBoundingBox)
      ctx.save()
      ctx.translate(pc.position.x, pc.position.y)
      ctx.rotate(pc.rotation)
    }

    // We're now in partworld

    if (!part) {
      throw new Error('Could not find part with id', pc.partId)
    }

    // TODO: Better styles

    /** 
     * Styles
     * 
     * 
     * Fill Style
     * Normal: 7a94f077 (light blue)
     * Selected part: e7cbb077 (light orange)
     * Colliding part: ff000077 (light red)
     * Colliding and selected part: #ff8a6577 (orange red)
     * 
     * Stroke Color
     * Normal: 999999 (light gray) -- this is the part outline without any cut path superimposed
     * Cut path: #000000 (black)
     * Selected contour without a cut path: #9ab9ff (light blue)
     * Selected contour with a cut path: #2266ff (blue)
     * 
     * Stroke thickness
     * Normal: 1 pixel or actual kerf width
     * Selected contour: 3 pixel or actual kerf width
     * 
     */

    if (part.arcified) {
      targetCtx.lineCap = 'round'
      targetCtx.lineJoin = 'round'

      targetCtx.lineWidth = Math.max(kerf, 1 / scale)

      let partStrokeStyle = '#00000000' // transparent
      let partFillStyle
      if (pc._isSelected) {
        if (pc._isColliding || pc._isOffSheet) {
          partFillStyle = '#ff6b4277' // orange
        } else {
          partFillStyle = '#7B8794' // '#67b8fb77' // bright blue
        }
      } else {
        if (pc._isColliding || pc._isOffSheet) {
          partFillStyle = '#ff000077' // red
        } else {
          // Changing this to fully opaque, so that the heat image overlay will look better
          // partFillStyle = '#1368af77' // blue
          partFillStyle = '#90b8da'
        }
      }

      targetCtx.strokeStyle = partStrokeStyle
      targetCtx.fillStyle = partFillStyle

      // TODO: How to make re-render when contours are loaded?

      // First, group contours according to their fill color.

      let closedContourGroupsByColor = {}

      for (let c of part.arcified.contours.filter(c => c.isClosed && c.type !== 'nonTopological' && c.type !== 'intersecting')) {
        let fillColor = contourFillColors?.find(cfc => cfc.contourId === c.id)?.color
        fillColor = fillColor ?? partFillStyle
        if (!closedContourGroupsByColor.hasOwnProperty(fillColor)) {
          closedContourGroupsByColor[fillColor] = []
        }
        closedContourGroupsByColor[fillColor].push(c)
      }

      // First, render all closed contours (grouping by color) and fill with the evenodd rule.
      for (let color in closedContourGroupsByColor) {
        targetCtx.beginPath()
        closedContourGroupsByColor[color]
          .forEach(c => {
            drawContour(targetCtx, c)
            // drawPolyline(ctx, c.poly)
          })
        targetCtx.fillStyle = color
        targetCtx.fill('evenodd') // The 'evenodd' rule just saved my bacon!
        targetCtx.stroke()
      }

      if (bendRegions) {
        targetCtx.save()
        ctx.strokeStyle = '#ff0000'
        ctx.fillStyle = '#ff000066'
        ctx.lineWidth = Math.max(4 / scale, kerf)
        const colors = [
          '#1b9e77', // 0 Teal
          '#d95f02', // 1 Orange
          '#7570b3', // 2 Blue-purple
          '#e7298a', // 3 Magenta
          '#66a61e', // 4 Green
          '#e6ab02', // 5 Yellow
          '#a6761d', // 6 Yellow-Brown
          '#666666', // 7 Grey
          '#8dd3c7', // 8 Cyan
          '#ffffb3', 
          '#bebada',
          '#fb8072',
          '#80b1d3',
          '#fdb462',
          '#b3de69',
          '#fccde5',
        ]
        for (let i = 0; i < bendRegions.length; i++) {
          if (!bendRegions[i]) {
            continue
          }
          targetCtx.fillStyle = colors[bendRegions[i].id % colors.length] + 'aa'
          targetCtx.strokeStyle = colors[bendRegions[i].id % colors.length]
          if (bendRegions[i].polyGroup?.length) {
            targetCtx.beginPath()
            for (let poly of bendRegions[i].polyGroup) {
              drawPolyline(
                targetCtx,
                poly.outerPoly,
                true)
              for (let inner of poly.innerPolys) {
                drawPolyline(
                  targetCtx,
                  inner,
                  true)
              }
            }
            targetCtx.stroke()
            targetCtx.fill('evenodd')
          }
          if (bendRegions[i].contourGroup?.length) {
            targetCtx.beginPath()
            for (let contour of bendRegions[i].contourGroup) {
              drawContour(targetCtx, contour.outerContour)
              for (let inner of contour.innerContours) {
                drawContour(targetCtx, inner)
              }
            }
            targetCtx.stroke()
            targetCtx.fill('evenodd')
          }
        }
        targetCtx.restore()
      }

      if (part.arcified?.bendInfo) {

        // When to show a line's allowance regions?
        // 1. When the line is selected
        // 2. When the line has errors or warnings

        // baRegion-baRegion intersection: warning (collidingWarning)
        // mfRegion-mfRegion intersection: ok
        // baRegion-mfRegion intersection: error (colliding)
        // mfRegion off of part: error (unsupported)

        // Changing this now to use an explicit list of stuff to show, instead of each line having those supported or colliding properties.
        let bendLineDfm = part.dfm?.bending?.bendLineDfm

        targetCtx.lineWidth = 2 / scale
        for (let bend of part.arcified.bendInfo.bends) {

          let contourIds = bend.originalContourIds
          const isSelected = selectedContours.some(sc => sc.partId === part.id && contourIds.includes(sc.contourId))
          for (let lineIndex = 0; lineIndex < bend.lineGroup.length; lineIndex++) {
            let line = bend.lineGroup[lineIndex]
            let lineDfm = bendLineDfm?.find(le => le.bendId === bend.id && le.lineIndex === lineIndex)

            if (isSelected && lineDfm) {

              targetCtx.save()
              // mfRegion: error on unsupported and colliding
              if (lineDfm.colliding || lineDfm.unsupportedSections.length > 0) {
                targetCtx.fillStyle = '#ff004444'
                targetCtx.strokeStyle = '#ff004499'
              } else {
                targetCtx.fillStyle = '#00000022'
                targetCtx.strokeStyle = '#00000055'
              }
              if (lineDfm.mfRegion) {
                targetCtx.beginPath()
                drawPolyline(
                  targetCtx,
                  lineDfm.mfRegion,
                  true)

                // targetCtx.fill('evenodd')
                targetCtx.lineWidth = 4 / scale
                targetCtx.setLineDash([4 / scale, 4 / scale])
                targetCtx.lineCap = 'butt'
                // targetCtx.beginPath()
                // targetCtx.moveTo(lineDfm.mfRegion[1].x, lineDfm.mfRegion[1].y)
                // targetCtx.lineTo(lineDfm.mfRegion[2].x, lineDfm.mfRegion[2].y)
                // targetCtx.moveTo(lineDfm.mfRegion[3].x, lineDfm.mfRegion[3].y)
                // targetCtx.lineTo(lineDfm.mfRegion[0].x, lineDfm.mfRegion[0].y)
                targetCtx.stroke()
              }
              targetCtx.restore()

              if (lineDfm.unsupportedSections.length > 0) {
                targetCtx.save()
                targetCtx.fillStyle = '#ff000044'
                for (let i = 0; i < lineDfm.unsupportedSections.length; i += 2) {
                  if (lineDfm.unsupportedSections[i + 1] - lineDfm.unsupportedSections[i] > 1e-3) {
                    targetCtx.beginPath()
                    let tangent = normalize(sub(line.end, line.start))
                    let normal = { x: -tangent.y, y: tangent.x }
                    let pt0 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i]), mul(normal, lineDfm.minFlange))
                    let pt1 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i + 1]), mul(normal, lineDfm.minFlange))
                    let pt2 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i + 1]), mul(normal, -lineDfm.minFlange))
                    let pt3 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i]), mul(normal, -lineDfm.minFlange))
                    targetCtx.moveTo(pt0.x, pt0.y)
                    targetCtx.lineTo(pt1.x, pt1.y)
                    targetCtx.lineTo(pt2.x, pt2.y)
                    targetCtx.lineTo(pt3.x, pt3.y)
                    targetCtx.lineTo(pt0.x, pt0.y)
                    targetCtx.fill()
                  }
                }
                targetCtx.restore()
              }
              if (lineDfm.unsupportedSectionsWarning.length > 0) {
                targetCtx.save()
                targetCtx.fillStyle = '#ff990044'
                for (let i = 0; i < lineDfm.unsupportedSectionsWarning.length; i += 2) {
                  if (lineDfm.unsupportedSectionsWarning[i + 1] - lineDfm.unsupportedSectionsWarning[i] > 1e-3) {
                    targetCtx.beginPath()
                    let tangent = normalize(sub(line.end, line.start))
                    let normal = { x: -tangent.y, y: tangent.x }
                    let pt0 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i]), mul(normal, lineDfm.minFlange))
                    let pt1 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i + 1]), mul(normal, lineDfm.minFlange))
                    let pt2 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i + 1]), mul(normal, -lineDfm.minFlange))
                    let pt3 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i]), mul(normal, -lineDfm.minFlange))
                    targetCtx.moveTo(pt0.x, pt0.y)
                    targetCtx.lineTo(pt1.x, pt1.y)
                    targetCtx.lineTo(pt2.x, pt2.y)
                    targetCtx.lineTo(pt3.x, pt3.y)
                    targetCtx.lineTo(pt0.x, pt0.y)
                    targetCtx.fill()
                  }
                }
                targetCtx.restore()
              }

              targetCtx.save()
              // baRegion: error on colliding, warning on collidingWarning
              if (lineDfm.colliding) {
                targetCtx.fillStyle = '#ff004444'
                targetCtx.strokeStyle = '#ff004499'
              } else if (lineDfm.collidingWarning) {
                targetCtx.fillStyle = '#ff990044'
                targetCtx.strokeStyle = '#ff990099'
              } else {
                targetCtx.fillStyle = '#00000022'
                targetCtx.strokeStyle = '#00000055'
              }
              if (lineDfm.baRegion) {
                targetCtx.beginPath()
                drawPolyline(
                  targetCtx,
                  lineDfm.baRegion,
                  true)
                targetCtx.fill('evenodd')
                targetCtx.stroke()
              }
              targetCtx.restore()

            }
          }
        }
      }


      // Draw the crude offsets of the outermost contours
      if (debug) {
        part.arcified.contours
          .filter(c => c.isOuterMost)
          .forEach(c => {
            let crudeOffset = offsetContourCrude(c, parseFloat(sheet.options.sheetCutMargin) + kerf)
            targetCtx.beginPath()
            drawContour(targetCtx, { entities: crudeOffset }, false)
            targetCtx.lineWidth = 2 / scale
            targetCtx.strokeStyle = '#00ff00'
            targetCtx.stroke()
            // drawPolyline(ctx, c.poly)
          })
      }

      // Second, render all open contours, but do not fill.
      part.arcified.contours
        .filter(c => !c.isClosed || c.type === 'nonTopological' || c.type === 'intersecting')
        .forEach(c => {
          targetCtx.beginPath()
          drawContour(targetCtx, c, false)
          // drawPolyline(ctx, c.poly)
          targetCtx.stroke()
        })


      // Render raw entities
      if (showRawEntities && part.arcified.rawEntities) {
        targetCtx.strokeStyle = '#ff0000'
        targetCtx.lineWidth = Math.max(kerf * 1.5, 0.9 / scale)
        targetCtx.beginPath()
        drawContour(targetCtx, { entities: part.arcified.rawEntities }, false)
        targetCtx.stroke()
        targetCtx.lineWidth = Math.max(kerf, 1 / scale)
      }

      // Render cut paths (and contour cutting order)
      let pProp = partProperties.find(pp => pp.partId === part.id)

      // If one of the contour's lead-ins is being dragged, this is it:
      let contourWhoseLeadInIsBeingDragged = draggingLeadIn && draggingLeadIn.partId === part.id && part.arcified.contours.find(c => draggingLeadIn.contourId === c.id)
      let cPropBeingDragged
      if (contourWhoseLeadInIsBeingDragged) {
        let cPropOriginal = pProp.contourProperties.find(cp => cp.contourId === contourWhoseLeadInIsBeingDragged.id)
        // Update the position of the lead-in while it is being dragged
        if (!draggingLeadIn.previewPosition) {
          // The lead-in has been dragged off of the contour
          cPropBeingDragged = update(cPropOriginal, {
            positions: { $splice: [[draggingLeadIn.positionIndex, 1]] }
          })
          // If the last remaining lead-in has been dragged off the contour, change its cutting method to IGNORE, so that the preview will be rendered correctly. (This is all temporary, no properties are actually being changed.)
          if (cPropBeingDragged.positions.length === 0) {
            cPropBeingDragged = update(cPropBeingDragged, { cuttingMethod: { $set: constants.CUTTING_METHOD_IGNORE } })
          }
        } else {
          cPropBeingDragged = update(cPropOriginal, {
            positions: {
              [draggingLeadIn.positionIndex]: { $set: draggingLeadIn.previewPosition }
            }
          })
        }
      }


      part.arcified.contours
        .forEach(function renderContourCutPath(contour) {   // Using a named function to make it easier to find and to profile :-)

          let cutPathStrokeStyle
          let cutPathLineWidth

          // Render the contour as selected if it is selected, or if the contour it was generated from is selected.

          const isSelected = selectedContours.some(sc => sc.partId === part.id && (sc.contourId === contour.id || sc.contourId === contour.autoGeneratedOriginContourId))

          // Choose correct contour properties
          let cProp
          let isSimilarContourBeingDragged = draggingLeadIn && draggingLeadIn.partId === part.id && areContoursSimilar(contour, contourWhoseLeadInIsBeingDragged)
          if (contour === contourWhoseLeadInIsBeingDragged) {
            cProp = cPropBeingDragged
          } else if (isSimilarContourBeingDragged && isSelected) {
            // Copy all the properties of the similar contour, and map its lead-in positions to the current contour
            cProp = cPropBeingDragged
            cProp = update(cProp, {
              positions: { $set: mapLeadInPositions(contourWhoseLeadInIsBeingDragged, contour, cProp.positions) },
              contourId: { $set: contour.id } // Later checks will fail if the id of the contour and its property don't match
            })
          } else {
            cProp = pProp.contourProperties.find(cp => cp.contourId === contour.id)
          }


          // If a contour has no cut path, just render the contour itself
          let contoursToRender = [contour]
          let piercePoints = []
          let stopPoints = []
          let hasCutPaths = false



          if (showCutPaths && cProp && cProp.cuttingMethod !== constants.CUTTING_METHOD_IGNORE) {
            // Compute and render the cut path (cut paths will be cached, eventually)

            if (debug) {
              cProp.positions.forEach(p => {
                targetCtx.beginPath()
                targetCtx.ellipse(p.x, p.y, 0.125, 0.125, 0, 0, Math.PI * 2)
                targetCtx.stroke()
              })
            }

            let cutPaths = createCutPathsFull(contour, cProp).map(cpFull => [...cpFull.leadIn, ...cpFull.partPath])
            hasCutPaths = true
            contoursToRender = cutPaths.map(cutP => ({ entities: cutP }))
            piercePoints = cutPaths.map(cutP => getPointTangentOfEntity(cutP[0], cutP[0].reversed ? 1 : 0)[0])
            stopPoints = cutPaths.map(cutP => {
              let iLast = cutP.length - 1
              return getPointTangentOfEntity(cutP[iLast], cutP[iLast].reversed ? 0 : 1)[0]
            })
          }

          if (isSelected) {
            cutPathLineWidth = Math.max(kerf, 4 / scale) // 4 pixels or kerf width
            if (hasCutPaths) {
              cutPathStrokeStyle = '#6622ff'
            } else {
              cutPathStrokeStyle = '#6622ff'
            }
          } else {
            cutPathLineWidth = Math.max(kerf, 1 / scale) // 1 pixel or kerf width
            if (cProp.cuttingMethod === constants.CUTTING_METHOD_IGNORE) {
              cutPathStrokeStyle = '#00000055'
            } else if (cProp.cuttingMethod === constants.CUTTING_METHOD_CUT
              || cProp.cuttingMethod === constants.CUTTING_METHOD_ENGRAVE
              || cProp.cuttingMethod === constants.CUTTING_METHOD_BEND
              || cProp.cuttingMethod === constants.CUTTING_METHOD_STITCH
              || cProp.cuttingMethod === constants.CUTTING_METHOD_HOLE) {
              cutPathStrokeStyle = '#000000'
            } else {
              cutPathStrokeStyle = '#ff0000'
            }
          }

          let cutPathLineDash
          if (cProp?.cuttingMethod === constants.CUTTING_METHOD_ENGRAVE) {
            cutPathLineDash = [Math.max(0.01, 5 / scale), Math.max(0.01, 5 / scale)]
          } else if (cProp?.cuttingMethod === constants.CUTTING_METHOD_BEND || cProp.cuttingMethod === constants.CUTTING_METHOD_STITCH || cProp.cuttingMethod === constants.CUTTING_METHOD_HOLE) {
            cutPathLineDash = [Math.max(0.014, 7 / scale), Math.max(0.008, 4 / scale), Math.max(0.004, 2 / scale), Math.max(0.008, 4 / scale)]
          } else {
            cutPathLineDash = []
          }
          targetCtx.setLineDash(cutPathLineDash)
          targetCtx.strokeStyle = cutPathStrokeStyle
          targetCtx.lineWidth = cutPathLineWidth
          let debugColors = [
            '#ff000077',
            '#00ff0077',
            '#0000ff77'
          ]
          let debugColorIdx = 0
          for (let c of contoursToRender) {
            targetCtx.beginPath()
            drawContour(targetCtx, c, false)
            if (debug) targetCtx.strokeStyle = debugColors[debugColorIdx]
            targetCtx.stroke()
            debugColorIdx = (debugColorIdx + 1) % debugColors.length

            // Render removed entities
            for (let e of c.entities) {
              if (e.removed) {
                let fakeContour = { entities: [e] }
                targetCtx.beginPath()
                targetCtx.strokeStyle = '#ff0000'
                drawContour(targetCtx, fakeContour, false)
                targetCtx.stroke()
              }
            }
          }

          targetCtx.setLineDash([])
          targetCtx.strokeStyle = '#ff9922'
          for (let p of piercePoints) {
            targetCtx.beginPath()
            targetCtx.arc(p.x, p.y, 0.125, 0, 2 * Math.PI * 2)
            targetCtx.moveTo(p.x - 0.088, p.y - 0.088)
            targetCtx.lineTo(p.x + 0.088, p.y + 0.088)
            targetCtx.moveTo(p.x + 0.088, p.y - 0.088)
            targetCtx.lineTo(p.x - 0.088, p.y + 0.088)
            targetCtx.stroke()
          }
          if (cProp?.microjoint > 0) {
            targetCtx.strokeStyle = '#00bb00'
            for (let p of stopPoints) {
              targetCtx.beginPath()
              targetCtx.moveTo(p.x - 0.03125, p.y - 0.03125)
              targetCtx.lineTo(p.x - 0.03125, p.y + 0.03125)
              targetCtx.lineTo(p.x + 0.03125, p.y + 0.03125)
              targetCtx.lineTo(p.x + 0.03125, p.y - 0.03125)
              targetCtx.lineTo(p.x - 0.03125, p.y - 0.03125)
              targetCtx.stroke()
            }
          }

          // Render cutting direction
          targetCtx.strokeStyle = '#776600'
          const renderCuttingDirection = (p) => {

            let arrowHeadSize = Math.min(0.125, contour.properties.perimeter / 12)
            let arrowOffset = Math.min(0.25, contour.properties.perimeter / 4)
            if (cProp.cuttingDirection === constants.CLOCKWISE) arrowOffset = -arrowOffset

            let traversedPointAndTangent = traverseContour(contour, p, arrowOffset)
            if (traversedPointAndTangent) {
              let [arrowPoint, arrowTangent] = traversedPointAndTangent

              if (cProp.cuttingDirection === constants.CLOCKWISE) arrowTangent = mul(arrowTangent, -1)
              arrowTangent = mul(arrowTangent, 1 / norm(arrowTangent))
              let arrowNormal = { x: arrowTangent.y, y: -arrowTangent.x }
              targetCtx.beginPath()
              let pt1 = add(arrowPoint, add(mul(arrowNormal, arrowHeadSize), mul(arrowTangent, -arrowHeadSize)))
              let pt2 = add(arrowPoint, add(mul(arrowNormal, -arrowHeadSize), mul(arrowTangent, -arrowHeadSize)))
              targetCtx.moveTo(pt1.x, pt1.y)
              targetCtx.lineTo(arrowPoint.x, arrowPoint.y)
              targetCtx.lineTo(pt2.x, pt2.y)
              targetCtx.stroke()
            }
          }

          if (cProp.cuttingMethod !== constants.CUTTING_METHOD_IGNORE) {
            if (contour.isClosed) {
              if (cProp) cProp.positions.forEach(renderCuttingDirection)
            } else {
              piercePoints.forEach(renderCuttingDirection)
            }
          }

          if (cProp.cuttingMethod === constants.CUTTING_METHOD_BEND) {
            // Render bend indicator and angle
            // Choose side of bend farthest from center of part
            // No, actually, choose side of bend closest to top-left, so the directions of the arrows will be the same for same-signed bends
            // let d2Start = norm2(sub(contour.entities[0].start, partCtr))
            // let d2End = norm2(sub(contour.entities[0].end, partCtr))
            let d2Start = -contour.entities[0].start.x + contour.entities[0].start.y
            let d2End = -contour.entities[0].end.x + contour.entities[0].end.y
            let pt1, pt2, tang, norm
            if (d2Start > d2End) {
              pt1 = contour.entities[0].start
              pt2 = contour.entities[0].end
            } else {
              pt1 = contour.entities[0].end
              pt2 = contour.entities[0].start
            }
            tang = normalize(sub(pt2, pt1))
            norm = { x: -tang.y, y: tang.x }


            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            if (contour.bendAngle < 0) {
              let a0t = 8 / scale * Math.cos(Math.PI * 7 / 4)
              let a0n = 12 / scale * Math.sin(Math.PI * 7 / 4)
              let a1t = a0t - 5 / scale
              let a1n = a0n - 0 / scale
              let a2t = a0t + 0 / scale
              let a2n = a0n - 5 / scale
              let p0 = add(add(mul(tang, a0t), mul(norm, a0n)), pt1)
              let p1 = add(add(mul(tang, a1t), mul(norm, a1n)), pt1)
              let p2 = add(add(mul(tang, a2t), mul(norm, a2n)), pt1)

              let a3t = 8 / scale * Math.cos(Math.PI / 4)
              let a3n = 12 / scale * Math.sin(Math.PI / 4)
              let a4t = a3t - 5 / scale
              let a4n = a3n + 0 / scale
              let a5t = a3t + 0 / scale
              let a5n = a3n + 5 / scale
              let p3 = add(add(mul(tang, a3t), mul(norm, a3n)), pt1)
              let p4 = add(add(mul(tang, a4t), mul(norm, a4n)), pt1)
              let p5 = add(add(mul(tang, a5t), mul(norm, a5n)), pt1)

              targetCtx.beginPath()
              targetCtx.ellipse(pt1.x, pt1.y, 8 / scale, 12 / scale, Math.atan2(tang.y, tang.x), Math.PI / 4, Math.PI * 7 / 4, false)
              targetCtx.lineTo(p1.x, p1.y)
              targetCtx.moveTo(p0.x, p0.y)
              targetCtx.lineTo(p2.x, p2.y)

              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p4.x, p4.y)
              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p5.x, p5.y)
              targetCtx.stroke()

            } else if (contour.bendAngle > 0) {

              let a0t = 8 / scale * Math.cos(Math.PI * 5 / 4)
              let a0n = 12 / scale * Math.sin(Math.PI * 5 / 4)
              let a1t = a0t + 5 / scale
              let a1n = a0n - 0 / scale
              let a2t = a0t - 0 / scale
              let a2n = a0n - 5 / scale
              let p0 = add(add(mul(tang, a0t), mul(norm, a0n)), pt1)
              let p1 = add(add(mul(tang, a1t), mul(norm, a1n)), pt1)
              let p2 = add(add(mul(tang, a2t), mul(norm, a2n)), pt1)

              let a3t = 8 / scale * Math.cos(Math.PI * 3 / 4)
              let a3n = 12 / scale * Math.sin(Math.PI * 3 / 4)
              let a4t = a3t + 5 / scale
              let a4n = a3n + 0 / scale
              let a5t = a3t - 0 / scale
              let a5n = a3n + 5 / scale
              let p3 = add(add(mul(tang, a3t), mul(norm, a3n)), pt1)
              let p4 = add(add(mul(tang, a4t), mul(norm, a4n)), pt1)
              let p5 = add(add(mul(tang, a5t), mul(norm, a5n)), pt1)

              targetCtx.beginPath()
              targetCtx.ellipse(pt1.x, pt1.y, 8 / scale, 12 / scale, Math.atan2(tang.y, tang.x), Math.PI * 3 / 4, Math.PI * 5 / 4, true)
              targetCtx.lineTo(p1.x, p1.y)
              targetCtx.moveTo(p0.x, p0.y)
              targetCtx.lineTo(p2.x, p2.y)

              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p4.x, p4.y)
              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p5.x, p5.y)
              targetCtx.stroke()
            }

            targetCtx.save()
            targetCtx.scale(1, -1)
            const fontSize = Math.max(0.03, 16 / scale)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'

            let text = toConventional(contour.bendAngle)?.angle + '°'
            let textMetrics = targetCtx.measureText(text)
            let textCtr = add(pt1, mul(tang, -30 / scale))
            targetCtx.fillText(text, textCtr.x - textMetrics.width / 2, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()
          }

          if (cProp.cuttingMethod === constants.CUTTING_METHOD_HOLE && contour.holeType === constants.HOLE_TYPE_REAM) {
            // Render ream indicator
            // Choose point at the two o'clock position outside the circle
            let maxRadius = contour.properties.circle.r
            let generatedContour = part.arcified.contours.find(c => contour.id === c.autoGeneratedOriginContourId)
            if (generatedContour) {
              maxRadius = Math.max(maxRadius, generatedContour.entities[0].r)
            } else {
              console.log('No generated contour found for ream')
              console.log(contour.autoGeneratedOriginContourId)
            }
            const fontSize = Math.max(0.03, 16 / scale)
            let point0 = {
              x: contour.properties.circle.x + maxRadius * Math.cos(Math.PI / 6),
              y: contour.properties.circle.y + maxRadius * Math.sin(Math.PI / 6)
            }
            let point1 = {
              x: point0.x + fontSize * Math.cos(Math.PI / 6),
              y: point0.y + fontSize * Math.sin(Math.PI / 6)
            }
            let point2 = {
              x: point1.x + fontSize * 0.5,
              y: point1.y
            }

            targetCtx.beginPath()
            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            targetCtx.moveTo(point0.x, point0.y)
            targetCtx.lineTo(point1.x, point1.y)
            targetCtx.lineTo(point2.x, point2.y)
            targetCtx.stroke()


            targetCtx.save()
            targetCtx.scale(1, -1)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'


            let partUnits = part.arcified.units ?? 'in'
            let reamUnits = partUnits[partUnits.length - 1] === 'm' ? 'mm' : 'in'
            let reamDigits = reamUnits === 'mm' ? 2 : 4
            let reamScale = unitScale[reamUnits]
            let text = reamUnits === 'in' ?
              `⌀ ${(contour.holeFinishedDiameter * reamScale).toFixed(reamDigits)}" REAM`
              :
              `⌀ ${(contour.holeFinishedDiameter * reamScale).toFixed(reamDigits)} mm REAM`

            let textCtr = point2
            targetCtx.fillText(text, textCtr.x, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()

          }
          if (cProp.cuttingMethod === constants.CUTTING_METHOD_HOLE && contour.holeType === constants.HOLE_TYPE_TAP) {
            // Render tap indicator
            // Choose point at the two o'clock position outside the circle
            let maxRadius = contour.properties.circle.r
            let generatedContour = part.arcified.contours.find(c => contour.id === c.autoGeneratedOriginContourId)
            if (generatedContour) {
              maxRadius = Math.max(maxRadius, generatedContour.entities[0].r)
            } else {
              console.log('No generated contour found for tap')
              console.log(contour.autoGeneratedOriginContourId)
            }
            const fontSize = Math.max(0.03, 16 / scale)
            let point0 = {
              x: contour.properties.circle.x + maxRadius * Math.cos(Math.PI / 6),
              y: contour.properties.circle.y + maxRadius * Math.sin(Math.PI / 6)
            }
            let point1 = {
              x: point0.x + fontSize * Math.cos(Math.PI / 6),
              y: point0.y + fontSize * Math.sin(Math.PI / 6)
            }
            let point2 = {
              x: point1.x + fontSize * 0.5,
              y: point1.y
            }

            targetCtx.beginPath()
            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            targetCtx.moveTo(point0.x, point0.y)
            targetCtx.lineTo(point1.x, point1.y)
            targetCtx.lineTo(point2.x, point2.y)
            targetCtx.stroke()


            targetCtx.save()
            targetCtx.scale(1, -1)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'

            let text = `${contour.holeThread ?? '???'}`
            let textCtr = point2
            targetCtx.fillText(text, textCtr.x, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()

          }
        })



      if (showContourOrder) {

        // Render cutting order and labels
        let thisPartsContourProps = partProperties.find(pp => pp.partId === part.id).contourProperties


        targetCtx.beginPath()
        thisPartsContourProps.forEach((cProp, i) => {

          // Get position
          let contour = part.arcified.contours.find(c => c.id === cProp.contourId)
          let pos = cProp.positions[0]
          if (!pos || !contour.isClosed) {
            if (cProp.cuttingDirection === constants.CLOCKWISE) {
              pos = getPointTangentOfEntity(contour.entities[contour.entities.length - 1], contour.entities[contour.entities.length - 1].reversed ? 0 : 1)[0]
            } else {
              pos = getPointTangentOfEntity(contour.entities[0], contour.entities[0].reversed ? 1 : 0)[0]
            }
          }

          if (i === 0) {
            targetCtx.moveTo(pos.x, pos.y)
          } else {
            targetCtx.lineTo(pos.x, pos.y)
          }
        })
        targetCtx.strokeStyle = '#774400'
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        targetCtx.stroke()

        targetCtx.save()
        targetCtx.scale(1, -1)
        thisPartsContourProps.forEach((cProp, i) => {

          // Get position
          let contour = part.arcified.contours.find(c => c.id === cProp.contourId)
          let pos = cProp.positions[0]
          if (!pos || !contour.isClosed) {
            if (cProp.cuttingDirection === constants.CLOCKWISE) {
              pos = getPointTangentOfEntity(contour.entities[contour.entities.length - 1], contour.entities[contour.entities.length - 1].reversed ? 0 : 1)[0]
            } else {
              pos = getPointTangentOfEntity(contour.entities[0], contour.entities[0].reversed ? 1 : 0)[0]
            }
          }

          // Render contour cutting order labels
          // Draw numbers
          targetCtx.lineWidth = Math.max(kerf, 1 / scale)
          const fontSize = Math.max(0.1, 16 / scale)
          targetCtx.font = `${fontSize}px sans-serif`
          targetCtx.strokeStyle = '#000000'

          let text = numberToAlpha(i + 1)
          // // Rotate and translate the partCtr
          // let cos = Math.cos(pc.rotation)
          // let sin = Math.sin(pc.rotation)

          // // Rotate and translate to get midpt in original part coords
          // let partCtrTrans = {
          //   x: cos * partCtr.x - sin * partCtr.y,
          //   y: sin * partCtr.x + cos * partCtr.y
          // }
          let partCtrTrans = pos//add(partCtrTrans, pc.position)
          let textMetrics = targetCtx.measureText(text)
          targetCtx.fillStyle = cProp._isPainted ? '#88eeff' : '#ffffff'
          targetCtx.beginPath()
          targetCtx.rect(partCtrTrans.x - textMetrics.width / 2 - fontSize * 0.15, -partCtrTrans.y - fontSize * 0.7, textMetrics.width + fontSize * 0.3, fontSize * 1.4)
          targetCtx.fill()
          targetCtx.stroke()
          targetCtx.fillStyle = '#000000'
          targetCtx.fillText(text, partCtrTrans.x - textMetrics.width / 2, -partCtrTrans.y + fontSize * 0.35)
        })
        targetCtx.restore()

      }

      if (part.alertPoints) {
        for (let pt of part.alertPoints) {
          targetCtx.beginPath()
          let rad = 10 / scale
          targetCtx.lineWidth = 2 / scale
          targetCtx.strokeStyle = '#ff0000'
          targetCtx.ellipse(pt.x, pt.y, rad, rad, 0, 0, 2 * Math.PI)
          targetCtx.stroke()
        }
      }

      // Third, render rough polylines for debugging.
      if (debug) {
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        part.arcified.contours
          .forEach(c => {
            if (c.type === 'outer')
              targetCtx.strokeStyle = '#ff00ff'
            else if (c.type === 'inner')
              targetCtx.strokeStyle = '#00ffff'
            else
              targetCtx.strokeStyle = '#ff7700'
            targetCtx.beginPath()
            drawPolyline(targetCtx, c.poly)
            targetCtx.stroke()

            if (c.type === 'outer')
              targetCtx.strokeStyle = '#ff00ff'
            else if (c.type === 'inner')
              targetCtx.strokeStyle = '#00ffff'
            else
              targetCtx.strokeStyle = '#ff7700'
            targetCtx.beginPath()
            drawPolyline(targetCtx, c.finePoly)
            targetCtx.stroke()

            if (c.piercePolys) {
              for (let pp of c.piercePolys) {
                targetCtx.beginPath()
                drawPolyline(targetCtx, pp)
                targetCtx.stroke()
              }
            }

            if (c.recommendedLeadIn?.pierce && c.recommendedLeadIn?.position) {
              targetCtx.fillStyle = '#000000'
              targetCtx.beginPath()
              targetCtx.arc(c.recommendedLeadIn.pierce.x, c.recommendedLeadIn.pierce.y, 0.02, 0, Math.PI * 2)
              targetCtx.fill()
              targetCtx.beginPath()
              targetCtx.arc(c.recommendedLeadIn.position.x, c.recommendedLeadIn.position.y, 0.02, 0, Math.PI * 2)
              targetCtx.fill()
            }
          })

        targetCtx.strokeStyle = '#ff0000'
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        part.arcified.contours
          .forEach(c => {
            let { offsetPolys } = computeOffsetPolys(part.id, c, parseFloat(sheet.options.partMargin) / 2)
            if (offsetPolys) {
              offsetPolys.forEach(offsetPoly => {
                targetCtx.beginPath()
                drawPolyline(targetCtx, offsetPoly)
                targetCtx.stroke()
              })
            }
          })


      }

      if (part.arcified.erodedPolys) {
        targetCtx.strokeStyle = '#ff0000aa'
        targetCtx.lineWidth = Math.max(0.021, 3 / scale)
        targetCtx.fillStyle = '#ff000077'
        targetCtx.beginPath()
        for (let poly of part.arcified.erodedPolys) {
          drawPolyline(targetCtx, poly)
        }
        targetCtx.stroke()
        targetCtx.fill('evenodd')
      }

      // Draw bounding boxes for debugging.
      if (debug) {
        targetCtx.strokeStyle = '#00ffff'
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        targetCtx.beginPath()
        drawBbox(targetCtx, part.arcified.bbox)
        targetCtx.stroke()
      }

      // Draw partcoord uuid
      if (debug) {
        let part = findPart(parts, pc)
        let partCtr = mul(add(part.arcified.bbox.min, part.arcified.bbox.max), 0.5)
        targetCtx.save()
        targetCtx.scale(1, -1)
        targetCtx.fillStyle = '#000000'
        targetCtx.font = '1px sans-serif'

        targetCtx.fillText(pc.uuid, partCtr.x, -partCtr.y)
        targetCtx.restore()
      }
    }

    if (!useLocalCanvas) {
      // Pop matrix
      ctx.restore()
      // We're back in inchland

      if (pc._isSelected && rotatingPartsDeltaRotation) {
        ctx.restore()
      }
      // if (pc._isSelected && translatingPartsDeltaDrag) {
      //   ctx.restore()
      // }
    }

    if (cache) {
      renderCache[key] = localCanvas // localCanvas will only be defined if useLocalCanvas is true
    }

  }


  if (useLocalCanvas) {
    ctx.save()

    // if (pc._isSelected && translatingPartsDeltaDrag) {
    //   // Draw this part offset while dragging
    //   ctx.translate(translatingPartsDeltaDrag.x, translatingPartsDeltaDrag.y)
    // }
    if (pc._isSelected && rotatingPartsDeltaRotation) {
      // Draw this part rotated while rotating
      ctx.translate(selDecs.rotationCenter.x, selDecs.rotationCenter.y)
      ctx.rotate(rotatingPartsDeltaRotation)
      ctx.translate(-selDecs.rotationCenter.x, -selDecs.rotationCenter.y)
    }

    // Push second transformation matrix: parts will be rotated and translated
    ctx.translate(pc.position.x, pc.position.y)
    ctx.rotate(pc.rotation)

    ctx.translate(-partTrans.x, -partTrans.y)

    let renderedRotation = (pc._isSelected && rotatingPartsDeltaRotation ? rotatingPartsDeltaRotation : 0) + pc.rotation
    if (Math.abs(((renderedRotation % (Math.PI / 2)) + 0.5) % 1 - 0.5) < 1e-9)
      ctx.imageSmoothingEnabled = false
    // Draw the localCtx onto the ctx
    try {
      ctx.drawImage(renderCache[key], 0, 0, renderCache[key].width / scale, renderCache[key].height / scale)
    } catch (ex) {
      Log.error(ex)
    }

    ctx.restore()
  }
}


/**
 * Converts an angle from Oshcut's internal format to the conventional format.
 * @param {Number} angle Bend angle in Oshcut's internal format
 * @returns {Object} An object containing properties angle and direction in the conventional format.
 */
function toConventional(angle) {
  // 0 angle should result in direction of 1
  if (angle == null) return null
  return { angle: 180 - Math.abs(angle), direction: Math.sign(angle) || 1 }
}
