import { Arcified, ArcifyOperation, Powder, Unit, ArcifiedTube, CustomerMaterialSheet, CustomerMaterialType, CustomerMaterialTube } from "@oshcut/oshlib"

/** Set the part scale factor and save (overwrite). */
export function setScale(scaleFactor: number): ArcifyOperation[] {
  return [
    { type: 'setScale', scaleFactor },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

/** Set the part scale factor, process bends, and save (overwrite). */
export function setScaleAndInspect(scaleFactor: number): ArcifyOperation[] {
  return [
    { type: 'setScale', scaleFactor },
    { type: 'processBends' },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

/** Sets the scale factor, then re-parses the dxf and re-arcifies. */
export function setScaleComplete(scaleFactor: number): ArcifyOperation[] {
  return [
    { type: 'setScale', scaleFactor },
    ...reprocessPart()
  ]
}


/** Sets the units, then re-parses the dxf and re-arcifies. */
export function setUnitsComplete(unit: Unit): ArcifyOperation[] {
  return [
    { type: 'setUnits', unit },
    ...reprocessPart()
  ]
}

/**
 * Parses the DXF and runs the complete gamut of arcify operations.
 * applyTransforms
 * arcify
 * removeDuplicates
 * link
 * setEngraveLayers
 * simplify
 * flatten
 * dust
 * closeGaps
 * generatePolylines
 * computeContourProperties
 * computeTopology
 * matchSimilarContours
 * findNestedParts
 * save
 */
export function newPart(overwrite = false): ArcifyOperation[] {
  return [
    { type: 'autoDetectPartType' },
    { type: 'parse' },
    { type: 'applyTransforms' },
    { type: 'removeDuplicates' },
    { type: 'translateToOrigin' },
    { type: 'linkNative' },
    { type: 'arcify', tol: 0.001 },
    { type: 'setEngraveLayers' },
    { type: 'parseUnfoldedJson', },
    { type: 'simplify', tol: 0.001 },
    { type: 'flatten', tol: 0.001, },
    { type: 'dust' },
    { type: 'joinParallel', tol: 0.0001 },
    { type: 'closeGaps' },
    { type: 'optimizeRotation' },
    { type: 'applyMirrorOption' },
    { type: 'generatePolylines', tolRough: 0.1, tolFine: 0.01 },
    { type: 'computeContourProperties', },
    { type: 'createStitchCuts' },
    { type: 'createHoles' },
    { type: 'computeTopology' },
    { type: 'stretchBendAllowance' },
    { type: 'matchSimilarContours', tol: 0.1 },
    { type: 'findNestedParts' },
    { type: 'processBends' },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', megapixels: 0.2, fileTypes: 'json|svg|png', overwrite }
  ]
}

export function setMaterial(material: CustomerMaterialSheet & CustomerMaterialType): ArcifyOperation[] {
  return [
    { type: 'setMaterial', materialId: material.id },
    { type: 'createStitchCuts', },
    { type: 'createHoles' },
    { type: 'computeTopology' },
    { type: 'findNestedParts' },
    { type: 'processBends' },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setMaterialTube(materialTube: CustomerMaterialTube & CustomerMaterialType): ArcifyOperation[] {
  return [
    { type: 'setMaterialTube', materialTubeId: materialTube.id },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setMaterialComplete(material: CustomerMaterialSheet & CustomerMaterialType): ArcifyOperation[] {
  return [
    { type: 'setMaterial', materialId: material.id },
    ...reprocessPart()
  ]
}

export function setPowder(powder: Powder, powderOptions: { zincPlated?: boolean, clearCoat?: boolean }): ArcifyOperation[] {
  return [
    { type: 'setPowder', powder, powderOptions },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setBendAngle(contourIds: number[], angle: number): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName: 'bendAngle', propertyValue: angle },
    { type: 'processBends' },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setBendAngleComplete(contourIds: number[], angle: number): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName: 'bendAngle', propertyValue: angle },
    ...reprocessPart()
  ]
}

export function setBendRadiusSource(bendRadiusSource: 'auto' | 'manual'): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { bendRadiusSource } },
    ...reprocessPart()
  ]
}

export function setBendMachineIdSource(bendMachineIdSource: 'auto' | 'manual'): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { bendMachineIdSource } },
    ...reprocessPart()
  ]
}

export function setBendRadiusManualValue(bendRadiusManualValueInch: number): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { bendRadiusManualValueInch } },
    ...reprocessPart()
  ]
}

export function setBendMachineIdManualValue(bendMachineIdManualValue: number): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { bendMachineIdManualValue } },
    ...reprocessPart()
  ]
}

export function setPartProperty(propName: keyof Arcified | keyof ArcifiedTube, propValue: any): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { [propName]: propValue } },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setCuttingMethod(contourIds: number[], cuttingMethod: Arcified['contours'][number]['cuttingMethod']): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName: 'cuttingMethod', propertyValue: cuttingMethod },
    { type: 'createStitchCuts' },
    { type: 'createHoles' },
    { type: 'computeTopology' },
    { type: 'findNestedParts' },
    { type: 'processBends', },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', megapixels: 0.2, fileTypes: 'json|svg|png', overwrite: true }
  ]
}

export function setCuttingMethodComplete(contourIds: number[], cuttingMethod: Arcified['contours'][number]['cuttingMethod']): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName: 'cuttingMethod', propertyValue: cuttingMethod },
    ...reprocessPart()
  ]
}

export function recomputeBendStretching() {
  return reprocessPart()
}

export function setStitchProperty(contourIds: number[], propertyName: keyof Arcified['contours'][number], propertyValue: any): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName, propertyValue },
    { type: 'createStitchCuts' },
    { type: 'computeTopology' },
    { type: 'findNestedParts' },
    { type: 'processBends', },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', megapixels: 0.2, fileTypes: 'json|svg|png', overwrite: true }
  ]
}

export function setHoleProperty(contourIds: number[], propertyName: keyof Arcified['contours'][number], propertyValue: any): ArcifyOperation[] {
  return [
    { type: 'setContourProperty', contourIds, propertyName, propertyValue },
    { type: 'createHoles' },
    { type: 'computeTopology' },
    { type: 'findNestedParts' },
    { type: 'processBends', },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', megapixels: 0.2, fileTypes: 'heat.png|json|svg|png', overwrite: true }
  ]
}

export function setMirrorOption(mirrored: boolean): ArcifyOperation[] {
  return [
    { type: 'setProperty', properties: { mirrored } },
    ...reprocessPart()
  ]
}

export function computeContourProperties(): ArcifyOperation[] {
  return [
    { type: 'computeContourProperties' },
    { type: 'save', fileTypes: 'json', overwrite: true }
  ]
}

export function setPartType(partType: 'sheetMetal' | 'tube'): ArcifyOperation[] {
  let operations = newPart(true)
  let autoDetectPartType = operations.find(op => op.type === 'autoDetectPartType')
  if (!autoDetectPartType) throw new Error('autoDetectPartType not found in operations')
  autoDetectPartType.preferredType = partType
  return operations
}

/**
 * Completely reprocess a part, preserving all of its properties.
 * @returns {Array}
 */
export function reprocessPart(overwrite = true): ArcifyOperation[] {
  return [
    { type: 'parse' },
    { type: 'applyTransforms' },
    { type: 'removeDuplicates' },
    { type: 'translateToOrigin' },
    { type: 'linkNative' },
    { type: 'arcify', tol: 0.001 },
    { type: 'simplify', tol: 0.001 },
    { type: 'flatten', tol: 0.001, },
    { type: 'dust' },
    { type: 'joinParallel', tol: 0.0001 },
    { type: 'closeGaps' },
    { type: 'optimizeRotation' },
    { type: 'applyMirrorOption' },
    { type: 'matchContourProperties' },
    { type: 'generatePolylines', tolRough: 0.1, tolFine: 0.01 },
    { type: 'computeContourProperties', },
    { type: 'createStitchCuts' },
    { type: 'createHoles' },
    { type: 'computeTopology' },
    { type: 'stretchBendAllowance' },
    { type: 'matchSimilarContours', tol: 0.1 },
    { type: 'findNestedParts' },
    { type: 'processBends' },
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'save', megapixels: 0.2, fileTypes: 'json|svg|png', overwrite }
  ]
}

export function dfmChecks(): ArcifyOperation[] {
  return [
    { type: 'compute3DProperties', includeLegacy: false, minHoleSize: 1000 },
    { type: 'mfgChecks', operations: ['all'] },
    // Do not save the json.
  ]
}
