

/**
 * Log levels
 * 1: Fatal - a crash. Log error in console AND send a crash report
 * 2: Error - Not supposed to happen but oh well
 * 3: Warn - Maybe something to look into
 * 4: Info - Mostly helpful for debugging
 */

const defaultLogLevelDevelopment = 4
const defaultLogLevelProduction = 3
const logLevel = import.meta.env.DEV ? defaultLogLevelDevelopment : defaultLogLevelProduction

function fatal(...msg) {
  logLevel >= 1 && console.error(...msg)
}

let info, warn, error

if (logLevel >= 2) {
  error = console.error.bind(window.console)
} else {
  error = () => { }
}

if (logLevel >= 3) {
  warn = console.warn.bind(window.console)
} else {
  warn = () => { }
}

if (logLevel >= 4) {
  info = console.log.bind(window.console)
} else {
  info = () => { }
}

const Log = { fatal, error, warn, info }

export default Log