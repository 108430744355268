import { ReactNode } from 'react'
import { ProgressSpinner } from './ProgressSpinner'

import './css/GlobalLoadingOverlay.scss'

type PropType = {
  progress?: number | null,
  reason: ReactNode
}

export function GlobalLoadingOverlay({ progress, reason }: PropType) {

  return (<div className="globalLoadingOverlay">
    <div className="globalLoadingBox">
      <ProgressSpinner progress={progress} />
      <div id="GlobalAppLoadingText">{reason}</div>
    </div>
  </div>)
}
