import { mul, getPointTangentOfEntity, distanceToEntities, entityLength, Vector, Contour } from "@oshcut/oshlib"

/**
 * Returns a point that is a given distance away from a starting point when following the given contour in the CCW direction. If the point does not lie on the contour, the closest point on the contour will be used as the starting point.
 * @param {Contour} contour Contour
 * @param {Point} startingPoint The starting point
 * @param {Number} distance Distance to traverse in the CCW direction. If CW traversal is desired, distance should be negative.
 * @returns {Point} The point that is distance away from starting point
 */
export function traverseContour(contour: Contour, startingPoint: Vector, distance: number) {

  let nearest = distanceToEntities(startingPoint, contour.entities)
  if (!nearest) return
  let { nearestEntity, nearestParametric } = nearest

  let currentEntity = nearestEntity
  let currentEntityIndex = contour.entities.indexOf(currentEntity)
  let currentParametric = nearestParametric
  let currentDistanceRemaining = distance

  while (true) {
    let currentEntityLength = entityLength(currentEntity)

    let entityParametricRemaining = 1 - currentParametric
    if (currentEntity.reversed) entityParametricRemaining = 1 - entityParametricRemaining
    if (distance < 0) entityParametricRemaining = 1 - entityParametricRemaining
    let entityDistanceRemaining = entityParametricRemaining * currentEntityLength

    if (Math.abs(currentDistanceRemaining) <= entityDistanceRemaining) {
      // The final point is on this entity
      let finalParametric
      if (currentEntity.reversed)
        finalParametric = currentParametric - (currentDistanceRemaining / currentEntityLength)
      else
        finalParametric = currentParametric + (currentDistanceRemaining / currentEntityLength)
      // Log.info(currentDistanceRemaining, entityDistanceRemaining, currentParametric, currentEntityLength, finalParametric, currentEntity)
      let [point, tangent] = getPointTangentOfEntity(currentEntity, finalParametric)
      if (currentEntity.reversed) tangent = mul(tangent, -1)
      return [point, tangent]
    } else if (currentDistanceRemaining > 0) {
      // Move to the beginning of the next entity
      currentEntityIndex = (currentEntityIndex + 1) % contour.entities.length
      currentEntity = contour.entities[currentEntityIndex]
      currentParametric = currentEntity.reversed ? 1 : 0
      currentDistanceRemaining -= entityDistanceRemaining
    } else if (currentDistanceRemaining < 0) {
      // Move to end of previous entity
      currentEntityIndex = (currentEntityIndex - 1 + contour.entities.length) % contour.entities.length
      currentEntity = contour.entities[currentEntityIndex]
      currentParametric = currentEntity.reversed ? 0 : 1
      currentDistanceRemaining += entityDistanceRemaining
    } else {
      break
    }

  }


}