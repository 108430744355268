import React from 'react'
import './css/FallbackErrorComponent.scss'
import SiteHeader from './SiteHeader'

export function FallbackErrorComponent(props) {

  return <>
    <SiteHeader />
    <div className='FallbackErrorComponent'>
      <div className='FallbackErrorMessage'>
        Oh, snap!
        <br />
        <br />
        Something went wrong. You can try <a href='/'>reloading the page</a>, or contact support@oshcut.com if the problem continues.
      </div>
    </div>
  </>
}