import config from "config";

/**
 * Get a recaptcha token for the given action. If the token cannot be retrieved in 3 seconds, undefined is returned.
 * @param action The action to get the token for.
 * @returns The recaptcha token or undefined if it could not be retrieved.
 */
export async function getRecaptchaToken(action: string) {

  let _window = window as any
  if (_window && 'grecaptcha' in _window) {
    const getToken = new Promise<string>((resolve) => {
      _window.grecaptcha.enterprise.ready(async () => {
        const recaptchaToken = await _window.grecaptcha.enterprise.execute(config.recaptchaKey, { action });
        resolve(recaptchaToken)
      })
    })

    let token
    try {
      token = await Promise.race<string>([getToken, new Promise((_, reject) => setTimeout(() => reject('Timed out'), 3000))])
      return token
    } catch (e) {
      console.error('Error getting recaptcha token', e)
    }
  }

  return undefined
}