/**
 * Given an array of parts, and a partCoord, return the part belonging to the part coord. Since the index of the part in the array is unlikely to change, it will be cached on pc as `pc._partIndex`, and this index will be examined first on subsequent calls to findPart. If the part is not found, `pc._partIndex` will be -1 and findPart will return undefined.
 * @param {import("types").PartType[]} parts An array of parts
 * @param {PartCoord} pc A partCoord whose part to find.
 */
export function findPart(parts, pc) {
  if (parts[pc._partIndex]?.id !== pc.partId) {
    pc._partIndex = parts.findIndex(p => p.id === pc.partId)
  }
  return parts[pc._partIndex]
}
