import { add, mul, sub, norm } from "@oshcut/oshlib"
import Log from "./logs"

export function drawContour(ctx, c, connect = true) {
  let i = 0
  for (let e of c.entities) {
    switch (e.type) {
      case 'ARC':
        let sa = e.startAngle
        let ea = e.endAngle
        while (sa < 0) sa += 2 * Math.PI
        while (sa > 2 * Math.PI) sa -= 2 * Math.PI
        while (ea < 0) ea += 2 * Math.PI
        while (ea > 2 * Math.PI) ea -= 2 * Math.PI
        if (Math.abs(ea - sa) < 1e-15)
          ea += 2 * Math.PI

        let dAngle = (e.endAngle - e.startAngle) / 2
        let bulge = (1 - Math.cos(dAngle)) * e.r

        if (e.reversed || !connect) {
          if (i === 0)
            ctx.moveTo(e.x + e.r * Math.cos(ea), e.y + e.r * Math.sin(ea))

          if (bulge < 1e-3 && dAngle < 3) {
            ctx.lineTo(e.x + e.r * Math.cos(sa), e.y + e.r * Math.sin(sa))
          } else {
            ctx.arc(e.x, e.y, e.r, ea, sa, true)
          }
        } else if (e.reversed === false) {
          if (i === 0)
            ctx.moveTo(e.x + e.r * Math.cos(sa), e.y + e.r * Math.sin(sa))

          if (bulge < 1e-3 && dAngle < 3) {
            ctx.lineTo(e.x + e.r * Math.cos(ea), e.y + e.r * Math.sin(ea))
          } else {
            ctx.arc(e.x, e.y, e.r, sa, ea)
          }
        } else {
          Log.info(e)
          Log.warn('Entity does not have a `reversed` property')
        }
        break
      case 'LINE':
        if (e.reversed || !connect) {
          if (i === 0)
            ctx.moveTo(e.end.x, e.end.y)
          ctx.lineTo(e.start.x, e.start.y)
        } else if (e.reversed === false) {
          if (i === 0)
            ctx.moveTo(e.start.x, e.start.y)
          ctx.lineTo(e.end.x, e.end.y)
        } else {
          Log.info(e)
          Log.warn('Entity does not have a `reversed` property')
        }
        break
      case 'CIRCLE':
        if (i === 0) {
          ctx.moveTo(e.x + e.r, e.y)
        }
        ctx.arc(e.x, e.y, e.r, 0, Math.PI * 2)
        break
      case 'SPLINE':

        if ((e.degree === 2) || (e.degree === 3)) {
          try {
            bezier(ctx, e)
          } catch (err) {
            throw new Error('Could not render spline: ' + err.message)
          }
        } else {
          throw new Error('Could not render spline')
        }

        break
      case 'POLYLINE':
      case 'LWPOLYLINE':
        drawPolyline(ctx, e.vertices, false)
        break
      case 'ELLIPSE':
        let majorAxis = Math.sqrt(e.majorX * e.majorX + e.majorY * e.majorY)
        let minorAxis = majorAxis * e.axisRatio
        let majorAxisAngle = Math.atan2(e.majorY, e.majorX)
        let sx = majorAxis * Math.cos(e.startAngle)
        let sy = minorAxis * Math.sin(e.startAngle)
        let tsx = Math.cos(majorAxisAngle) * sx - Math.sin(majorAxisAngle) * sy
        let tsy = Math.sin(majorAxisAngle) * sx + Math.cos(majorAxisAngle) * sy
        ctx.moveTo(e.x + tsx, e.y + tsy)
        ctx.ellipse(e.x, e.y, majorAxis, minorAxis, majorAxisAngle, e.startAngle, e.endAngle, false)
        break
      case 'MTEXT':
      case 'POINT':
        Log.warn('Ignoring entity: ' + e.type)
        break
      default:
        Log.info(e)
        throw new Error('Unsupported entity: ' + e.type)
    }

    if (connect) i++
  }
}

export function drawPolyline(ctx, poly, repeatFirst) {
  if (poly?.length > 0) {
    ctx.moveTo(poly[0].x, poly[0].y)
    for (let i = 1; i < poly.length; i++) {
      if (poly[i - 1].bulge) {
        drawLineBulge(ctx, poly[i - 1], poly[i], poly[i - 1].bulge)
      } else {
        ctx.lineTo(poly[i].x, poly[i].y)
      }
    }
    if (repeatFirst) {
      ctx.lineTo(poly[0].x, poly[0].y)
    }
  }
}

export function drawBbox(ctx, bbox) {
  ctx.rect(bbox.min.x, bbox.min.y, bbox.max.x - bbox.min.x, bbox.max.y - bbox.min.y)
}

export function drawLeadIn(ctx, leadIn) {

  const li = leadIn

  // TODO: Lead in angles other than 90 deg

  const pierce = add(add(li.position, mul(li.normal, li.length)), mul(li.tangent, -li.radius))
  const beginTurn = add(add(li.position, mul(li.normal, li.length - li.radius)), mul(li.tangent, -li.radius))
  const centerOfTurn = add(li.position, mul(li.normal, li.radius))
  let startAngle = Math.atan2(-li.tangent.y, -li.tangent.x)
  let endAngle = Math.atan2(-li.normal.y, -li.normal.x)

  let cross = li.normal.x * li.tangent.y - li.normal.y * li.tangent.x

  // We may need to swap start/end angles under certain circumstances

  let anticlockwise = false
  if (cross > 0) {
    anticlockwise = true
  }

  ctx.moveTo(pierce.x, pierce.y)
  ctx.lineTo(beginTurn.x, beginTurn.y)
  ctx.arc(centerOfTurn.x, centerOfTurn.y, li.radius, startAngle, endAngle, anticlockwise)


}


/**
 * Compute the arc that terminates at the given points with the specified bulge.
 * @param {Point} P The first point
 * @param {Point} Q The second point
 * @param {Number} b The bulge factor
 * @returns {Arc} An ARC entity
 */
function drawLineBulge(ctx, P, Q, b) {

  if (b === 0) throw new Error('Cannot convert to arc: bulge factor is 0')
  const neg = Math.sign(b)
  b = Math.abs(b)
  const PQ = sub(Q, P)
  const d = norm(PQ)
  if (d < 1e-15) {
    throw new Error('Cannot convert to arc: line segment is zero length')
  }
  const bd = b * d / 2
  const r = bd / 2 + d * d / (8 * bd)
  const l = r - bd
  const M = mul(add(P, Q), 0.5)
  const MC = { x: -PQ.y, y: PQ.x }
  const C = add(M, mul(MC, neg * l / d))
  const CP = sub(P, C)
  const CQ = sub(Q, C)

  let startAngle = Math.atan2(CP.y, CP.x)
  let endAngle = Math.atan2(CQ.y, CQ.x)
  if (neg < 0) {
    const temp = startAngle
    startAngle = endAngle
    endAngle = temp
  }

  // Correct the angles
  while (startAngle < 0) startAngle += Math.PI * 2
  while (startAngle >= Math.PI * 2) startAngle -= Math.PI * 2
  while (endAngle < startAngle) endAngle += Math.PI * 2

  const e = {
    type: 'ARC',
    x: C.x,
    y: C.y,
    r,
    startAngle,
    endAngle
  }

  let sa = e.startAngle
  let ea = e.endAngle

  if (neg < 0) {
    ctx.arc(e.x, e.y, e.r, ea, sa, true)
  } else {
    ctx.arc(e.x, e.y, e.r, sa, ea)
  }

}

// From: https://github.com/bjnortier/dxf

function bezier(ctx, entity) {
  const k = entity.degree + 1
  const piecewise = toPiecewiseBezier(k, entity.controlPoints, entity.knots)
  const paths = piecewiseToPaths(ctx, k, piecewise.knots, piecewise.controlPoints)
  const element = `<g>${paths.join('')}</g>`
  return element
}

function piecewiseToPaths(ctx, k, knots, controlPoints) {
  const paths = []
  let controlPointIndex = 0
  let knotIndex = k
  while (knotIndex < knots.length - k + 1) {
    const m = multiplicity(knots, knotIndex)
    const cp = controlPoints.slice(controlPointIndex, controlPointIndex + k)
    if (k === 4) {
      ctx.moveTo(cp[0].x, cp[0].y)
      ctx.bezierCurveTo(cp[1].x, cp[1].y, cp[2].x, cp[2].y, cp[3].x, cp[3].y)
      // paths.push(`<path d="M ${cp[0].x} ${cp[0].y} C ${cp[1].x} ${cp[1].y} ${cp[2].x} ${cp[2].y} ${cp[3].x} ${cp[3].y}" />`)
    } else if (k === 3) {
      ctx.moveTo(cp[0].x, cp[0].y)
      ctx.quadraticCurveTo(cp[1].x, cp[1].y, cp[2].x, cp[2].y)
      // paths.push(`<path d="M ${cp[0].x} ${cp[0].y} Q ${cp[1].x} ${cp[1].y} ${cp[2].x} ${cp[2].y}" />`)
    }
    controlPointIndex += m
    knotIndex += m
  }
  return paths
}

/**
 * For a pinned spline, the knots have to be repeated k times
 * (where k is the order), at both the beginning and the end
 */
function checkPinned(k, knots) {
  // Pinned at the start
  for (let i = 1; i < k; ++i) {
    if (knots[i] !== knots[0]) {
      throw Error(`not pinned. order: ${k} knots: ${knots}`)
    }
  }
  // Pinned at the end
  for (let i = knots.length - 2; i > knots.length - k - 1; --i) {
    if (knots[i] !== knots[knots.length - 1]) {
      throw Error(`not pinned. order: ${k} knots: ${knots}`)
    }
  }
}

function multiplicity(knots, index) {
  let m = 1
  for (let i = index + 1; i < knots.length; ++i) {
    if (knots[i] === knots[index]) {
      ++m
    } else {
      break
    }
  }
  return m
}

/**
 * https://saccade.com/writing/graphics/KnotVectors.pdf
 * A quadratic piecewise Bézier knot vector with seven control points
 * will look like this [0 0 0 1 1 2 2 3 3 3]. In general, in a
 * piecewise Bézier knot vector the first k knots are the same,
 * then each subsequent group of k-1 knots is the same,
 * until you get to the end.
 */
function computeInsertions(k, knots) {
  const inserts = []
  let i = k
  while (i < knots.length - k) {
    const knot = knots[i]
    const m = multiplicity(knots, i)
    for (let j = 0; j < k - m - 1; ++j) {
      inserts.push(knot)
    }
    i = i + m
  }
  return inserts
}

function toPiecewiseBezier(k, controlPoints, knots) {
  checkPinned(k, knots)
  const insertions = computeInsertions(k, knots)
  return insertions.reduce((acc, tNew) => {
    return insertKnot(k, acc.controlPoints, acc.knots, tNew)
  }, { controlPoints, knots })
}

/**
 * Knot insertion is known as "Boehm's algorithm"
 *
 * https://math.stackexchange.com/questions/417859/convert-a-b-spline-into-bezier-curves
 * code adapted from http://preserve.mactech.com/articles/develop/issue_25/schneider.html
 */
function insertKnot(k, controlPoints, knots, newKnot) {
  const x = knots
  const b = controlPoints
  const n = controlPoints.length
  let i = 0
  let foundIndex = false
  for (let j = 0; j < n + k; j++) {
    if (newKnot > x[j] && newKnot <= x[j + 1]) {
      i = j
      foundIndex = true
      break
    }
  }
  if (!foundIndex) {
    throw new Error('invalid new knot')
  }

  const xHat = []
  for (let j = 0; j < n + k + 1; j++) {
    if (j <= i) {
      xHat[j] = x[j]
    } else if (j === i + 1) {
      xHat[j] = newKnot
    } else {
      xHat[j] = x[j - 1]
    }
  }

  let alpha
  const bHat = []
  for (let j = 0; j < n + 1; j++) {
    if (j <= i - k + 1) {
      alpha = 1
    } else if (i - k + 2 <= j && j <= i) {
      if (x[j + k - 1] - x[j] === 0) {
        alpha = 0
      } else {
        alpha = (newKnot - x[j]) / (x[j + k - 1] - x[j])
      }
    } else {
      alpha = 0
    }

    if (alpha === 0) {
      bHat[j] = b[j - 1]
    } else if (alpha === 1) {
      bHat[j] = b[j]
    } else {
      bHat[j] = {
        x: (1 - alpha) * b[j - 1].x + alpha * b[j].x,
        y: (1 - alpha) * b[j - 1].y + alpha * b[j].y
      }
    }
  }
  return { controlPoints: bHat, knots: xHat }
}