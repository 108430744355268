import { MdCheck } from 'react-icons/md'
import { CustomerMaterialTube, CustomerMaterialType, MaterialTube, TubeProfileClassification } from '@oshcut/oshlib'
import { Button } from 'components/Button'
import { CatalogDispatchFunction, CatalogStateType } from './Catalog'
import './css/TubeListView.scss'
import { matchTubeClassifications, roundShapes, useMaterialTubeFilter } from './useFilter'
import ShapeRoundTubeImage from '../../images/Shape_Round_Tube.png'
import ShapePipeImage from '../../images/Shape_Pipe.png'
import ShapeSquareImage from '../../images/Shape_Square.png'
import ShapeRectangularImage from '../../images/Shape_Rectangular.png'
import { MaterialTubeThumbnail } from './MaterialTubeThumbnail'
import config from 'config'
import { RecentMaterialsWrapper } from './RecentMaterialsWrapper'
import { classNames } from '@oshcut/components'

type PropType = {
  state: CatalogStateType
  dispatch: CatalogDispatchFunction,
  onClose?: () => void,
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}

export function TubeListView({ state, dispatch, onClose, onSelect }: PropType) {

  const { matchingTubes, matchingShapes, matchingMaterials, matchingSupertypes } = useMaterialTubeFilter(state)

  /** Specify which levels of detail to include. */
  let levels

  if (matchingTubes.length > 30) {
    // Lots of matching tubes

    if (matchingShapes.length > 1) {
      // More than one shape. Show shapes only.
      levels = {
        shapeTwoColumns: true,
        shape: false,
        supertype: false,
        material: false,
        tubeName: false,
      }
    } else if (matchingMaterials.length > 1) {
      // Only one shape, lots of materials. Show types and materials.
      levels = {
        shapeTwoColumns: false,
        shape: true,
        supertype: true,
        material: true,
        tubeName: false,
      }
    } else {
      // Only one shape, only one material. Show everything.
      levels = {
        shapeTwoColumns: false,
        shape: true,
        supertype: true,
        material: true,
        tubeName: true,
      }
    }
  } else {
    // Just a few tubes, so show everything.
    levels = {
      shapeTwoColumns: false,
      shape: true,
      supertype: true,
      material: true,
      tubeName: true,
    }
  }

  if (matchingTubes.length === 0) {
    return <p>No materials match the selected filter criteria.</p>
  }

  return <>
    <p className='small'>Need a different material, size, or shape? Contact us at <a className='link' target="_blank" href="mailto:support@oshcut.com" onClick={e => e.stopPropagation()}>support@oshcut.com</a> and we'll try to add it to our catalog.</p>
    <RecentMaterialsWrapper state={state} dispatch={dispatch} />
    <TubeShapes state={state} dispatch={dispatch} levels={levels} onClose={onClose} onSelect={onSelect} />
  </>
}

function TubeShapes({ state, dispatch, levels, onClose, onSelect }: {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction,
  levels: { shapeTwoColumns: boolean, shape: boolean, supertype: boolean, material: boolean, tubeName: boolean },
  onClose?: () => void
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}) {

  const { matchingShapes, matchingMaterials, matchingTubes, matchingSupertypes } = useMaterialTubeFilter(state)

  function handleClickShape(shape: string) {
    dispatch({ type: 'setFilter', filter: { ...state.filter, shapes: [shape] } })
  }

  return (
    <div className={`${levels.shapeTwoColumns ? 'tubeShapesTwoColumns' : 'tubeShapes'}`}>
      {matchingShapes.map(shape =>
        <div key={shape}>
          {levels.shape &&
            <div className='tubeShape'>
              <h2 className='tubeShapeHeader'>{shape}</h2>
            </div>
          }
          {levels.shapeTwoColumns &&
            <div className='tubeShape clickable' onClick={() => handleClickShape(shape)}>
              {shape === 'Pipe' && <img src={ShapePipeImage} />}
              {['Round', 'Round Tube'].includes(shape) && <img src={ShapeRoundTubeImage} />}
              {shape === 'Square' && <img src={ShapeSquareImage} />}
              {shape === 'Rectangular' && <img src={ShapeRectangularImage} />}
              <h2 className='tubeShapeHeader'>{shape}</h2>
            </div>
          }
          {(levels.supertype || levels.material || levels.tubeName) &&
            <TubeSupertypes state={state} dispatch={dispatch} shape={shape} levels={levels} onClose={onClose} onSelect={onSelect} />
          }

        </div>
      )}

      {matchingShapes.length === 0 &&
        <p>No materials match the specified filters.</p>
      }
    </div>
  )
}

function TubeSupertypes({ state, dispatch, shape, levels, onClose, onSelect }: {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction,
  shape: string,
  levels: { shape: boolean, supertype: boolean, material: boolean, tubeName: boolean }
  onClose?: () => void
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}) {
  const { matchingSupertypes } = useMaterialTubeFilter(state, tube => tube.shape === shape)

  return (
    <div className={`tubeSupertypes`}>
      {matchingSupertypes.map(supertype =>
        <div key={supertype}>
          {levels.supertype &&
            <div className='tubeSupertype'>
              <h3 className='tubeSupertypeHeader'>{supertype}</h3>
            </div>
          }
          {(levels.material || levels.tubeName) &&
            <TubeMaterials state={state} dispatch={dispatch} shape={shape} supertype={supertype} levels={levels} onClose={onClose} onSelect={onSelect} />
          }
        </div>
      )}
    </div>
  )
}

function TubeMaterials({ state, dispatch, shape, supertype, levels, onClose, onSelect }: {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction,
  shape: string,
  supertype: string,
  levels: { shape: boolean, supertype: boolean, material: boolean, tubeName: boolean }
  onClose?: () => void
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}) {
  const { matchingMaterials } = useMaterialTubeFilter(state, tube => tube.shape === shape && tube.supertype === supertype)

  function handleClickMaterialType(material: CustomerMaterialType) {
    dispatch({ type: 'setFilter', filter: { ...state.filter, materialDescriptions: [material.description] } })
  }

  return (
    <div className='materials'>
      {matchingMaterials.map(material =>
        <div
          className={`materialType ${!levels.tubeName ? 'clickable' : 'shadow'}`}
          onClick={() => !levels.tubeName && handleClickMaterialType(material)}
          key={material.name}
        >
          {levels.material &&
            <div className='materialInfo'>
              <img
                src={`${config.staticAssets}/images/materials/${material.type_image}`}
              />
              <header>{material.description}</header>
              <div>{material.details}</div>
            </div>
          }
          {(levels.tubeName) &&
            <TubeVariations state={state} dispatch={dispatch} shape={shape} supertype={supertype} material={material} levels={levels} onClose={onClose} onSelect={onSelect} />
          }
        </div>
      )}
    </div>
  )
}

function TubeVariations({ state, dispatch, shape, supertype, material, levels, onClose, onSelect }: {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction,
  shape: string,
  supertype: string,
  material: CustomerMaterialType,
  levels: { shape: boolean, supertype: boolean, material: boolean, tubeName: boolean }
  onClose?: () => void
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}) {

  const { matchingTubes } = useMaterialTubeFilter(state, tube => tube.shape === shape && tube.supertype === supertype && tube.material_id === material.material_id)

  if (matchingTubes.length === 0) {
    return <p>No materials match the specified filters.</p>
  }

  return (
    <table className='tubeListTable'>
      {getHeader(matchingTubes[0])}
      {matchingTubes.map((tube) => {
        let matchesProfile = matchTubeClassifications(tube, state.filter.matchingTubeProfileClassifications ?? [])
        return <Row key={tube.id} state={state} dispatch={dispatch} tube={tube} onClose={onClose} onSelect={matchesProfile ? onSelect : undefined} disabled={!matchesProfile} />
      }
      )}
    </table>
  )
}

function getHeader(tube: CustomerMaterialTube) {
  if (tube.shape === 'Pipe') {
    return <tr>
      <th></th>
      <th></th>
      <th>Pipe Size</th>
      <th>Schedule</th>
      <th>Outer Diameter</th>
      <th>Wall Thickness</th>
      <th>Max Length</th>
      <th></th>
    </tr>
  }
  else if (roundShapes.includes(tube.shape)) {
    return <tr>
      <th></th>
      <th></th>
      <th>Outer Diameter</th>
      <th>Wall Thickness</th>
      <th>Max Length</th>
      <th></th>
    </tr>
  } else {
    return <tr>
      <th></th>
      <th></th>
      <th>Width</th>
      <th>Height</th>
      <th>Wall Thickness</th>
      <th>Max Length</th>
      <th></th>
    </tr>
  }
}

function Row({ state, dispatch, tube, onClose, onSelect, disabled }: {
  state: CatalogStateType,
  dispatch: CatalogDispatchFunction,
  tube: CustomerMaterialTube & CustomerMaterialType
  disabled?: boolean
  onClose?: () => void
  onSelect?: ({ tube_id }: { tube_id: MaterialTube['id'] }) => void,
}) {

  function handleClickRow() {
    dispatch({ type: 'setSelectedMaterialTubeId', id: tube.id })
  }

  function handleSelectRow() {
    dispatch({ type: 'setInitialMaterialTubeId', id: tube.id })
    setTimeout(() => {
      onSelect && onSelect({ tube_id: tube.id })
      onClose && onClose()
    }, 100)
  }

  const className = classNames({
    tubeRow: true,
    selectable: !!onSelect,
    selected: tube.id === state.initialTubeId,
    disabled: !!disabled,
  })

  if (tube.shape === 'Pipe') {
    return <tr
      onClick={onSelect ? handleSelectRow : handleClickRow}
      className={className}
    >
      <td><div className={`checkbox`}><MdCheck /></div></td>
      <td><MaterialTubeThumbnail materialTube={tube} className='' /></td>
      <td>{tube.nominal_pipe_size}"</td>
      <td>SCH {tube.pipe_schedule}</td>
      <td>{tube.profile_size_x}"</td>
      <td>{tube.wall_thickness}"</td>
      <td>{tube.max_length_in}"</td>
      <td><Button type='BTN_TERTIARY' onClick={e => { e.stopPropagation(); handleClickRow() }}>More info...</Button></td>
    </tr>
  }
  else if (roundShapes.includes(tube.shape)) {
    return <tr
      onClick={onSelect ? handleSelectRow : handleClickRow}
      className={className}
    >
      <td><div className={`checkbox`}><MdCheck /></div></td>
      <td><MaterialTubeThumbnail materialTube={tube} className='' /></td>
      <td>{tube.profile_size_x}"</td>
      <td>{tube.wall_thickness}"</td>
      <td>{tube.max_length_in}"</td>
      <td><Button type='BTN_TERTIARY' onClick={e => { e.stopPropagation(); handleClickRow() }}>More info...</Button></td>
    </tr>
  } else {
    return <tr
      onClick={onSelect ? handleSelectRow : handleClickRow}
      className={className}
    >
      <td><div className={`checkbox`}><MdCheck /></div></td>
      <td><MaterialTubeThumbnail materialTube={tube} className='' /></td>
      <td>{tube.profile_size_x}"</td>
      <td>{tube.profile_size_y}"</td>
      <td>{tube.wall_thickness}"</td>
      <td>{tube.max_length_in}"</td>
      <td><Button type='BTN_TERTIARY' onClick={e => { e.stopPropagation(); handleClickRow() }}>More info...</Button></td>
    </tr>
  }
}

export function getTubeName(tube: CustomerMaterialTube) {
  if (tube.shape === 'Pipe') {
    return `${tube.nominal_pipe_size}" SCH ${tube.pipe_schedule} - ${tube.shape}`
  }
  else if (roundShapes.includes(tube.shape)) {
    return `${tube.profile_size_x}" OD x ${tube.wall_thickness}" wall - ${tube.shape}`
  }
  else {
    return `${tube.profile_size_x}" x ${tube.profile_size_y}" x ${tube.wall_thickness}" wall - ${tube.shape}`
  }
}

export function getProfileClassificationString(classification: TubeProfileClassification) {
  let profile_size_x = classification.outerRectangle.bbox.max.x - classification.outerRectangle.bbox.min.x
  let profile_size_y = classification.outerRectangle.bbox.max.y - classification.outerRectangle.bbox.min.y
  if (profile_size_y > profile_size_x) {
    let temp = profile_size_x
    profile_size_x = profile_size_y
    profile_size_y = temp
  }

  let shape = ''
  let dimensions = ''
  let radius = ''
  if (classification.isCircular) {
    shape = 'Round'
    dimensions = `${+profile_size_x.toFixed(2)}" OD`
  } else {
    if (classification.isSquare) {
      shape = 'Square'
    } else {
      shape = 'Rectangular'
    }
    dimensions = `${+profile_size_x.toFixed(2)}" x ${+profile_size_y.toFixed(2)}"`
    if (classification.outerRectangle.radius > 0.02) {
      radius = `, ${+classification.outerRectangle.radius.toFixed(3)}" corner radius`
    } else {
      radius = `, sharp corners`
    }
  }

  return `${shape} ${dimensions}, ${+classification.wallThickness.toFixed(3)}" wall${radius}`
}

export function getMaterialTubeClassificationString(tube: CustomerMaterialTube) {
  let profile_size_x = Math.max(tube.profile_size_x, tube.profile_size_y)
  let profile_size_y = Math.min(tube.profile_size_x, tube.profile_size_y)
  if (profile_size_y > profile_size_x) {
    let temp = profile_size_x
    profile_size_x = profile_size_y
    profile_size_y = temp
  }
  const wall_thickness = tube.wall_thickness

  let shape = ''
  let dimensions = ''
  let radius = ''
  if (roundShapes.includes(tube.shape)) {
    shape = 'Round'
    dimensions = `${+profile_size_x.toFixed(2)}" OD`
  } else {
    if (tube.shape.toLocaleLowerCase().includes('square')) {
      shape = 'Square'
    } else {
      shape = 'Rectangular'
    }
    dimensions = `${+profile_size_x.toFixed(2)}" x ${+profile_size_y.toFixed(2)}"`
    if (tube.corner_radius && tube.corner_radius > 0.02) {
      radius = `, ${+tube.corner_radius.toFixed(3)}" corner radius`
    } else {
      radius = `, sharp corners`
    }
  }

  return `${shape} ${dimensions}, ${+wall_thickness.toFixed(3)}" wall${radius}`
}

