import { CustomerMaterialSheet, CustomerMaterialTube, CustomerMaterialType } from "@oshcut/oshlib"

/**
 * Joins material sheets or tubes with material types
 * @param matObjects The objects to extend with the material types
 * @param matTypes The material types to join with the objects
 * @returns A new array of objects with the material types joined
 */
export function joinWithMaterialTypes<T extends CustomerMaterialSheet | CustomerMaterialTube>(matObjects: T[], matTypes: CustomerMaterialType[]): (T & CustomerMaterialType)[] {
  return matObjects.map(mat => {
    return joinMaterial(mat, matTypes)
  }).filter((mat): mat is (T & CustomerMaterialType) => mat !== undefined)
}

export function joinMaterial<T extends CustomerMaterialSheet | CustomerMaterialTube | undefined>(matObject: T, matTypes: CustomerMaterialType[]): (T & CustomerMaterialType) | undefined {
  if (!matObject) return undefined
  let matType = matTypes.find(t => t.material_id === matObject.material_id)
  if (!matType) return undefined
  return { ...matObject, ...matType }
}