import React from 'react'
import PromisePool from 'es6-promise-pool'
import { dfmChecks } from '../arcifyRecipes'
import { partNeedsQuote } from './useQuote'
import { arcifyHttpTransaction } from '../arcifyHttpTransaction'
import Log from '../logs'
import { StateType } from 'types'
import { DispatchFunction } from 'reducer'
import { ArcifyResult, Part } from '@oshcut/oshlib'

type PropType = {
  state: StateType
  dispatch: DispatchFunction
}

// Watches the part status and dfm status of all parts, and runs dfm checks when necessary.
export function useDfmChecks({ state, dispatch }: PropType) {

  React.useEffect(() => {
    if (state.parts) {
      let partsNeedingDfmCheck = state.parts.filter(part => part.status === 'PART_STATUS_READY' && part.dfmStatus === 'DFM_STATUS_INVALID')
      console.log('partsNeedingDfmCheck', partsNeedingDfmCheck.map(part => part.id))

      const preparePart = (partId: Part['id']) => {
        dispatch({ type: 'ACTION_SET_PART_STATUS', partId, dfmStatus: 'DFM_STATUS_PROCESSING' })
      }

      const performCheck = async (partId: Part['id']) => {
        const part = state.parts.find(part => part.id === partId)
        if (!part?.arcified) return
        let operations = dfmChecks()

        if (operations.some(op => op.type === 'save')) {
          throw new Error('save operation not recommended for dfm checks')
        }
        let saveResult: ArcifyResult
        try {
          saveResult = await arcifyHttpTransaction({
            partId: part.id,
            version: part.arcified.version,
            preemptible: true, // If another operation on this part begins, this one will be canceled
            operations
          }, (updateMsg) => {
            if (updateMsg.pct) {
              dispatch({ type: 'ACTION_SET_PART_STATUS', partId: part.id, dfmStatusProgress: updateMsg.pct })
            }
          })
        } catch (ex) {
          Log.error(ex)
          dispatch({ type: 'ACTION_SET_PART_STATUS', partId, dfmStatus: 'DFM_STATUS_PROCESSING_ERROR' })
          return
        }
        let mfgChecks = saveResult.operations.find(op => op.operation.type === 'mfgChecks')?.result.mfgChecks
        dispatch({ type: 'ACTION_DFM_LOADED', partId: part.id, dfm: mfgChecks })
        dispatch({ type: 'ACTION_SET_PART_STATUS', partId: part.id, dfmStatus: 'DFM_STATUS_COMPLETE' })
        if (partNeedsQuote(part)) {
          dispatch({ type: 'ACTION_INVALIDATE_QUOTE' })
        }

        // HACK: Temporary to show the recognized rectangle and/or circle for tube profile debugging

        let classifiedRectangle = saveResult.operations.find(op => op.operation.type === 'mfgChecks')?.result.classifiedRectangle
        let classifiedCircle = saveResult.operations.find(op => op.operation.type === 'mfgChecks')?.result.classifiedCircle

        // @ts-ignore
        window.classifiedRectangle = classifiedRectangle
        // @ts-ignore
        window.classifiedCircle = classifiedCircle

      }


      // Run all the synchronous code first
      for (let part of partsNeedingDfmCheck) {
        preparePart(part.id)
      }

      // Run async code 2 parts at a time
      const generatePromises = function* () {
        for (let part of partsNeedingDfmCheck) {
          yield performCheck(part.id)
        }
      }
      const promiseIterator = generatePromises()
      // @ts-ignore https://github.com/timdp/es6-promise-pool/issues/74
      const pool = new PromisePool(promiseIterator, 2)
      pool.start()
    }
  }, [state.parts])


}
