// Temporarily accept json files for testing unfolding
let acceptFileTypes = ['.dxf', '.svg', '.ai', '.sldprt', '.catpart', '.ipt', '.step', '.stp', '.igs', '.par', '.iges', '.sldasm', '.psm', '.prt', '.x_t', '.SAT', '.SAB', '.3DM']
if (import.meta.env.DEV) acceptFileTypes.push('.json')

/**
 * Removes recognized file extensions from a filename.
 */
export function removeRecognizedExtensions(filename: string) {
  let extension = filename.split('.').pop()
  if (extension && acceptFileTypes.find((ext) => ext.toLowerCase() === `.${extension?.toLowerCase()}`)) {
    return filename.substring(0, filename.length - extension.length - 1)
  }
  return filename
}

export { acceptFileTypes }