import React from 'react'
import './css/WorkingIcon.css'

export function WorkingIcon() {
  return (
    <span className='working-icon'>
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{
        verticalAlign: 'middle', fill: 'currentColor', stroke: 'currentColor', strokeWidth: '1', width: '1.5em',
        height: '1.5em'
      }}>
      </svg>
      <span className='working-icon-dot1' />
      <span className='working-icon-dot2' />
      <span className='working-icon-dot3' />
    </span>
  )
}