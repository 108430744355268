import { formatPrice, SortDirection } from '@oshcut/components'
import { DispatchFunction } from '../../reducer'
import { StateType } from '../../types'
import { Customer } from '@oshcut/oshlib'

export type SortableColumn = 'type' | 'name' | 'orderNumber' | 'reference' | 'invoiceDate' | 'dueDate' | 'totalCents' | 'outstandingBalanceCents'

export type SortInfo = {
  column: SortableColumn
  direction: SortDirection
}

export interface BillingViewProps {
  dispatch: DispatchFunction;
  state: StateType;
  customer: Customer;
}


export function formatStatementItemPrice(priceInCents: number) {
  const isNegative = priceInCents < 0
  return isNegative ? `(${formatPrice((priceInCents/100) * -1)})` : formatPrice(priceInCents/100)
}