import {
  atan2, add, mul, norm, sub,
  intersectEntities
} from "@oshcut/oshlib"

export function createLeadInGeometry(...args) {
  return createLeadInGeometry2(...args)
}

/**
 * Creates line and arc entities for a lead-in. 
 * @param {Point} position The point where the lead-in contacts the part
 * @param {Point} tangent The direction of cutting at the lead-in point
 * @param {Point} normal The unit normal to the part, points away from the finished part
 * @param {*} length The length of the lead-in
 * @param {*} radius The radius of curvature of the lead-in
 * @param {*} angle The angle the lead-in makes to the tangent (default = pi/2)
 * @returns {Array} An array of entities that form the lead-in.
 */
export function createLeadInGeometry1(position, tangent, normal, length, radius, angle) {


  let h = radius * Math.sin(angle)
  let straightLength = Math.max(length - h, 0)

  const startOfTurn = add(position,
    add(
      mul(tangent, -radius * Math.sin(angle)),
      mul(normal, radius * (1 - Math.cos(angle)))
    )
  )

  const pierce = add(startOfTurn,
    add(
      mul(tangent, -straightLength * Math.sin(angle)),
      mul(normal, straightLength * Math.sin(angle))
    )
  )

  const centerOfTurn = add(position, mul(normal, radius))
  let endAngle = atan2(normal) + Math.PI

  // Which direction is the turn?
  const cross = normal.x * tangent.y - normal.y * tangent.x

  let startAngle
  if (cross > 0) {
    startAngle = endAngle + angle
  } else {
    startAngle = endAngle - angle
  }


  let arcReversed = false
  if (cross > 0) {
    let temp = startAngle
    startAngle = endAngle
    endAngle = temp
    arcReversed = true
  }

  const entities = []

  if (straightLength > 0) {
    entities.push({
      type: 'LINE',
      start: pierce,
      end: startOfTurn,
      reversed: false
    })
  }

  if (radius > 0) {
    entities.push({
      type: 'ARC',
      x: centerOfTurn.x,
      y: centerOfTurn.y,
      r: radius,
      startAngle: startAngle,
      endAngle: endAngle,
      reversed: !arcReversed
    })
  }

  // Log.info(entities)

  return { entities, pierce }
}

/**
 * Creates line and arc entities for a lead-in. Alternate form in which the pierce point does not change if the radius changes or if the tangent is reversed (for example, if reversing the direction of cutting) 
 * @param {Point} position The point where the lead-in contacts the part
 * @param {Point} tangent The direction of cutting at the lead-in point
 * @param {Point} normal The unit normal to the part, points away from the finished part
 * @param {*} length The length of the lead-in
 * @param {*} radius The radius of curvature of the lead-in
 * @param {*} angle The angle the lead-in makes to the tangent
 * @returns {Array} An array of entities that form the lead-in.
 */
export function createLeadInGeometry2(position, tangent, normal, length, radius, angle) {

  if (typeof length === 'string') length = parseFloat(length)
  if (typeof radius === 'string') radius = parseFloat(radius)
  if (typeof angle === 'string') angle = parseFloat(angle)
  if (isNaN(length)) length = 0
  if (isNaN(radius)) radius = 0
  if (isNaN(angle)) angle = 0
  if (length < 0) length = 0
  if (radius < 0) radius = 0

  const cos = Math.cos(angle)
  const sin = Math.sin(angle)

  // Pierce location
  const pierce = add(position,
    add(
      mul(tangent, length * cos),
      mul(normal, length * sin)
    )
  )

  // Handle special cases
  if (length === 0) {
    return { entities: [], pierce }
  }

  if (radius === 0) {
    return {
      entities: [{
        type: 'LINE',
        start: pierce,
        end: position,
        reversed: false
      }],
      pierce
    }
  }

  // Maximum radius
  // 90% of theoretical max, in order to give enough straight section for cutter comp to kick in
  let maxRadius = length / (2 * sin) * 0.9
  let hasStraightSection = true
  if (radius > maxRadius) {
    radius = maxRadius
    // hasStraightSection = false
  }

  // Center of turn
  const centerOfTurn = add(position, mul(normal, radius))

  // Distance from pierce to center of turn
  const dPC = norm(sub(pierce, centerOfTurn))
  if (dPC ** 2 - radius ** 2 <= 0) {
    // Second chance to bail out of a potentially awkward numerical situation
    throw new Error('Lead-in geometry has no straight section')
    hasStraightSection = false
  }


  // Which direction is the turn?
  const cross = normal.x * tangent.y - normal.y * tangent.x
  let arcReversed = false

  let startOfTurn
  if (hasStraightSection) {

    // The start of turn will be one of the two intersections formed by the these two circles: one centered at C with radius r, and one centered at P with radius dPC

    // We'll let e0 be the circle with center C and radius r
    // And e1 be the circle with center P and radius sqrt(dPC**2 - radius**2)
    let e0 = {
      type: 'CIRCLE',
      x: centerOfTurn.x,
      y: centerOfTurn.y,
      r: radius
    }
    let e1 = {
      type: 'CIRCLE',
      x: pierce.x,
      y: pierce.y,
      r: Math.sqrt(dPC ** 2 - radius ** 2)
    }

    let candidatePts = intersectEntities(e0, e1)

    // Somehow, magically, cross tells us whether to pick candidate 0 or 1. Ok!
    if (cross < 0) {
      startOfTurn = candidatePts[1]
    } else {
      startOfTurn = candidatePts[0]
    }
  } else {
    startOfTurn = pierce
  }

  let startAngle = atan2(sub(startOfTurn, centerOfTurn))
  let endAngle = atan2(normal) + Math.PI

  if (cross > 0) {
    let temp = startAngle
    startAngle = endAngle
    endAngle = temp
    arcReversed = true
  }
  const entities = []
  if (hasStraightSection > 0) {
    entities.push({
      type: 'LINE',
      start: pierce,
      end: startOfTurn,
      reversed: false
    })
  }

  if (norm(sub(startOfTurn, position)) > 1e-9) {

    entities.push({
      type: 'ARC',
      x: centerOfTurn.x,
      y: centerOfTurn.y,
      r: radius,
      startAngle: startAngle,
      endAngle: endAngle,
      reversed: arcReversed
    })
  }

  return { entities, pierce }

}