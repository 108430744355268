import { Timer } from "@oshcut/components"
import { DateTime, Duration } from "luxon";

type CalendarStringFormats = {
  lastDay?: string
  sameDay?: string
  nextDay?: string
  lastWeek?: string
  nextWeek?: string
  sameElse: string
}

type PropType = {
  /** The format to use to render the time. */
  format?: string

  /** The format to use to render the time, based on the relative calendar day. */
  calendar?: CalendarStringFormats

  /** The interval in milliseconds to update the rendered time. */
  interval?: number

  /** If true, will render the given time as a duration from the current time. */
  durationFromNow?: boolean

  /** The timestamp or javascript Date of the time to render. */
  children: number | Date
}

/**
 * Wraps a luxon DateTime or Duration object and renders it as a string. By default, the value is updated every 60
 * seconds, but this can be changed with the interval prop.
 * 
 * This component should be given a single child, the timestamp or javascript Date to render.
 * @returns 
 */
export default function Luxon({ format, durationFromNow, calendar, interval = 60000, children }: PropType) {

  return (
    <Timer interval={interval}>{now => {

      if (children instanceof Date) {
        children = children.getTime()
      }

      const dtNow = DateTime.fromMillis(now)
      const dtMillis = DateTime.fromMillis(children)

      let fmt

      if (format != null) {
        fmt = format
      } else if (calendar != null) {
        if (calendar.sameDay != null && dtMillis.hasSame(dtNow, 'day')) {
          fmt = calendar.sameDay
        }
        else if (calendar.lastDay != null && dtMillis.hasSame(dtNow.minus({ days: 1 }), 'day')) {
          fmt = calendar.lastDay
        }
        else if (calendar.nextDay != null && dtMillis.hasSame(dtNow.plus({ days: 1 }), 'day')) {
          fmt = calendar.nextDay
        }
        else if (calendar.lastWeek != null && dtMillis.hasSame(dtNow.minus({ weeks: 1 }), 'week')) {
          fmt = calendar.lastWeek
        }
        else if (calendar.nextWeek != null && dtMillis.hasSame(dtNow, 'week')) {
          fmt = calendar.nextWeek
        }
        else {
          fmt = calendar.sameElse
        }
      }

      if (durationFromNow) {
        const dtDiff = Duration.fromMillis(children - now)
        if (fmt) {
          return <time dateTime={dtDiff.toISO()}>{dtDiff.toFormat(fmt)}</time>
        } else {
          return <time dateTime={dtDiff.toISO()} >{dtDiff.toISO()}</time>
        }
      } else {
        if (fmt) {
          return <time dateTime={dtMillis.toISO() ?? undefined}>{dtMillis.toFormat(fmt)}</time>
        } else {
          return <time dateTime={dtMillis.toISO() ?? undefined}>{dtMillis.toISO()}</time>
        }
      }

    }}
    </Timer>
  )
}
