import { MdClose } from "react-icons/md"
import React, { useEffect, useState } from 'react'

import './css/LandingMessage.scss'

const LandingMessage = ({messages}) => {

  const [content, setContent] = useState(<></>)

  const onClose = (msgData) => {
    setContent(<></>)
    localStorage.setItem('landingMessage', msgData)
  }


  useEffect(() => {
    
    let msg = messages.find(m => m.name === "landing_message")

    if(msg !== undefined && msg.data?.length > 0 && localStorage.getItem("landingMessage") !== msg.data) {
      setContent(<div className="landingMessage">
        <div className="message">
          <span className="messageContent">
            {msg.data}
          </span>
          <div className="closeBtn" onClick={() => onClose(msg.data)}><MdClose size={24} /></div>
        </div>
      </div>)
    }

  }, [messages])


  return content
}

export default LandingMessage
