import { classNames } from "@oshcut/components"
import { PipeStatus } from "@oshcut/oshlib"
import { useMemo } from "react"
import "./css/OrderPartQtyBoxes.scss"
import { PartQtys, aggregatePercentages } from "pipeStatus"

type OrderPartQtyBoxesProps = {
  data: PartQtys[]
}
const OrderPartQtyBoxes = ({ data }: OrderPartQtyBoxesProps) => {

  const { inProcessQty, finishedQty, scheduledQty, percentage } = useMemo(() => aggregatePercentages(data), [data])

  return (
    <div className="OrderPartQtyBoxes">

      <div className="boxes">

        <div className={classNames("box", "deficit", { hasQty: scheduledQty > 0 })}>
          <div className="wrapper">
            <div className="label">
              Scheduled
            </div>
            <div className="qty">
              {scheduledQty}
            </div>
          </div>
        </div>

        <div className={classNames("box", "inProcess", { hasQty: inProcessQty > 0 })}>
          <div className="wrapper">
            <div className="label">
              In Process
            </div>
            <div className="qty">
              {inProcessQty}
            </div>
          </div>
        </div>

        <div className={classNames("box", "finished", { hasQty: finishedQty > 0 })}>
          <div className="wrapper">
            <div className="label">
              Finished
            </div>
            <div className="qty">
              {finishedQty}
            </div>
          </div>
        </div>

      </div>

      <div className="progressBar">
        <div style={{width: `${percentage}%`}} className="finished"></div>
      </div>

  </div>
  )
}

export default OrderPartQtyBoxes