import { classNames } from "@oshcut/components"
import "./Marquee.scss"

type MarqueeProps = React.HTMLProps<HTMLDivElement>

/**
 * Creates and infinite scrolling marquee.  
 * NOTE: Do not use `gap` or `margin` to separate elements, use `padding` instead.
 * `gap` and `margin` offset the `translationX` and cause it to not loop cleanly.
 * Why? I'm not really sure.
 */
const Marquee = ({ children, className, ...divProps }: MarqueeProps) => {

  return (
    <div className={classNames('Marquee', className)} {...divProps}>
      {children}
      {children}
      {children}
      {children}
    </div>
  )
}

export default Marquee