import { useContext, useEffect, useMemo, useState } from 'react'
import { CustomerPaymentMethod } from '@oshcut/oshlib'
import { PaymentMethodCard } from './PaymentMethodCard'
import './PaymentMethodSelector.scss'
import { AddNewPaymentMethod } from './AddNewPaymentMethod'
import { SecondaryButton, PrimaryButton, classNames, Dialog, LinkButton } from '@oshcut/components'
import { ElementsWrapper } from './ElementsWrapper'
import { PaymentMethodActionsWrapper } from './PaymentMethodActionsWrapper'
import Card from '../Card'
import { PaymentMethodsContext } from './PaymentMethodsContext'

type Props = {
  paymentMethodOptions?: CustomerPaymentMethod[]
  paymentMethod?: CustomerPaymentMethod | null
  onPaymentMethodSelection: (paymentMethod: CustomerPaymentMethod) => void
  /** Opt-in to a "Payment Method" header with a "Change" button.
   * Default to false
   */
  showHeader?: boolean
  flatCard?: boolean
  dialogTitleOverride?: string
}

export const PaymentMethodSelector = ({ paymentMethodOptions: providedPaymentMethods, paymentMethod: providedPaymentMethod, onPaymentMethodSelection, showHeader = false, flatCard = false, dialogTitleOverride}: Props) => {
  const { paymentMethods} = useContext(PaymentMethodsContext)
  const [view, _setView] = useState<null | 'select' | 'add new'>(null)
  const [paymentMethod, setPaymentMethod] = useState<CustomerPaymentMethod | undefined | null>(providedPaymentMethod)
  const [tempSelected, setTempSelected] = useState<CustomerPaymentMethod | undefined | null>(paymentMethod)

  //if the parent component changes the provided payment method then internal state should be overridden
  useEffect(() => {
    setPaymentMethod(providedPaymentMethod)
    setTempSelected(providedPaymentMethod)
  }, [providedPaymentMethod])

  function onRadioSelect(pm: CustomerPaymentMethod){
    setTempSelected(pm)
  }

  function confirmSelection(pm: CustomerPaymentMethod) {
    setTempSelected(pm)
    setPaymentMethod(pm)
    onPaymentMethodSelection(pm)
    setView(null)
  }

  function newPaymentMethodOnSuccess(pm: CustomerPaymentMethod) {
    if(paymentMethods.length === 0) {
      onPaymentMethodSelection(pm)
      setView(null)
    } else {
      setTempSelected(pm)
      setView('select')
    }
  }

  const readyPaymentMethods = useMemo(()=> {
    if(providedPaymentMethods) return providedPaymentMethods
    return paymentMethods.filter(pm => pm.status === 'ready')
  }, [paymentMethods])

  const selectedPaymentMethodExistsInOptions = useMemo(()=> {
    if(!tempSelected) return false
    return readyPaymentMethods.some(pm => pm.guid === tempSelected.guid)
  }, [readyPaymentMethods, tempSelected])

  function setView(newView:'select' | 'add new' | null) {
    if(newView === 'select' && readyPaymentMethods.length == 0) return _setView('add new')
    _setView(newView)
  }

  function cancelDialog() {
    if(view === 'select') _setView(null)
    else if(view === 'add new' && readyPaymentMethods.length == 0) _setView(null)
    else if(view === 'add new') _setView('select')
  }

  return (
    <div className='PaymentMethodSelector'>
      {showHeader && <h3>
        <span>Payment Method</span>
        {paymentMethod && <LinkButton onClick={_ => setView("select")}>Change</LinkButton>}
      </h3>}
      {!!paymentMethod
        ? <div className="clickable-card" onClick={() => setView('select')}><PaymentMethodCard paymentMethod={paymentMethod} noBoxShadow={flatCard} /></div>
        : <PrimaryButton className='method-selector-add-button' onClick={() => setView('select')}>Select Payment Method</PrimaryButton>
      }
      <Dialog open={!!view} className='payment-method-selector-dialog' title={view === 'select' ? (dialogTitleOverride || 'Your Payment Methods') : 'Add Payment Method'} onClose={cancelDialog}>
        {view === 'select' && <div className='payment-method-selector-dialog-content'>
          <SecondaryButton className='method-selector-add-button' onClick={() => setView('add new')}>Add New Payment Method</SecondaryButton>
          <div className='method-selector-methods'>
            {readyPaymentMethods.map(pmOption => (
              <Card key={pmOption.guid} className={classNames('wrapped-card', 'increase-specificity', { 'payment-method-selected': pmOption.guid === tempSelected?.guid })}>
                <label className='method-selector-card-wrapper'>
                  <input type='radio' name='payment-method' value={pmOption.guid} checked={pmOption.guid === tempSelected?.guid} onChange={() => onRadioSelect(pmOption)} />
                  <PaymentMethodCard paymentMethod={pmOption} noBoxShadow transparentBorder />
                </label>
              </Card>
            ))}
          </div>
          <PaymentMethodActionsWrapper>
            <PrimaryButton disabled={!tempSelected || !selectedPaymentMethodExistsInOptions} onClick={()=>!!tempSelected && confirmSelection(tempSelected)}>Confirm</PrimaryButton>
            <SecondaryButton onClick={() => setView(null)}>Cancel</SecondaryButton>
          </PaymentMethodActionsWrapper>
        </div>
        }
        {view === 'add new' && <ElementsWrapper onErrorExitClick={cancelDialog}>
          <AddNewPaymentMethod onSuccess={newPaymentMethodOnSuccess} onCancel={cancelDialog} />
        </ElementsWrapper>}
      </Dialog>
    </div>
  )
}
