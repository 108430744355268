import React, { ReactNode, useState } from 'react';
import { AlertPopup } from '../components/AlertPopup'

export function useAlert() {
  const [content, setContent] = useState(<></>)

  function showAlert(title: ReactNode, message: ReactNode, onOk?: () => void) {
    let callback: null | (() => void) = null
    if (onOk) {
      callback = onOk
    }

    setContent(<AlertPopup title={title} message={message} onOk={() => handleOk(callback)} />)
  }

  function handleOk(callback: null | (() => void)) {
    setContent(<></>)
    if (callback !== null) {
      callback()
    }
  }

  return [content, showAlert] as const
}
