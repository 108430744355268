import React from 'react'
import './index.scss'
import App from './components/App'
import config from './config'
import {
  BrowserRouter as Router
} from "react-router-dom"
import Modal from 'react-modal'
import { Buffer } from 'buffer'
import { createRoot } from 'react-dom/client'
import { SignInProvider } from 'useSignIn'

// TODO: CRA 5 removed polyfills. Remove this when we have a better solution
// If we don't have a value for Buffer (node core module) create it/polyfill it
if (window.Buffer === undefined) window.Buffer = Buffer;

Modal.setAppElement('#root');

// Fix for Google Translate
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this)
      }
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this)
      }
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Router basename={config.basename}>
    <SignInProvider>
      <App />
    </SignInProvider>
  </Router>
)

