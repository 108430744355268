import { useEffect, useState } from "react"

export const useClientWidth = () => {
  // Keep track of client width
  const [clientWidth, setClientWidth] = useState<number>(window.innerWidth)
  useEffect(() => {
    function handleResize() {
      setClientWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return clientWidth
}