import { CustomerPaymentMethod, OrderQuotedLead, OrderQuotedShipping, PostResponseType, TermsTypes } from "@oshcut/oshlib";
import { OrderBillAddress, OrderShipAddress } from "types";
import update from 'immutability-helper';

/** Describes the current state of the checkout process */
export type CheckoutState = {

  nestProgress: Record<string, string>
  /** The final nest result once all materials have completed */
  nestResult: PostResponseType<'/api/v2/nest'>['result'] | null
  /** If the transaction errors completely or is cancelled, this will be set to that error */
  nestError: string | null

  /** Current state of the ship address form.  
   * Stored in state so that we can POST an update to the order object whenever the user leaves the shipping section. */
  shippingAddress: OrderShipAddress
  shipNameError: string | null,
  shipPhoneError: string | null,
  shipAddressError: string | null,
  shipCityError: string | null,
  shipStateError: string | null,
  shipZipError: string | null,
  /** False if name, phone, address, city, state, or zip are invalid. */
  localShipAddressValid: boolean,


  shippingOptions: OrderQuotedShipping[number][] | null
  leadOptions: OrderQuotedLead | null
  /** `days` of the selected lead time option */
  selectedLeadDays: OrderQuotedLead["options"][number]["days"] | null
  /** `carrier` + `service` of the selected ship option */
  selectedShipDescription: string | null,//selectedShipOption.carrier + ' ' + selectedShipOption.service,



  /** Current state of the billing address form.  
   * Stored in state so that we can POST an update to the order object whenever the user leaves the shipping section. */
  billingAddress: OrderBillAddress
  billNameError: string | null,
  billPhoneError: string | null
  localBillAddressValid: boolean,
  paymentMethod: CustomerPaymentMethod | null
  /** If this is non-null, the customer-user has requested the GUID to be set as their default payment method going forward. */
  setDefaultPaymentMethodGuidTo: string | null
}

export const defaultCheckoutState: CheckoutState = {
  nestProgress: {},
  nestResult: null,
  nestError: null,

  shippingAddress: { // `CheckoutShipping` will sync this with the order on first load
    ship_company: "",
    ship_name: "",
    phone: "",
    ship_address: "",
    ship_address2: "",
    ship_city: "",
    ship_state: "",
    ship_zip: "",
    is_pickup: 0,
    pickup_facility_id: 0,
    requires_liftgate: 0,
    is_residential: 0,
    requires_appt: 0,
  },
  shipNameError: null,
  shipPhoneError: null,
  shipAddressError: null,
  shipCityError: null,
  shipStateError: null,
  shipZipError: null,
  localShipAddressValid: false,


  leadOptions: null,
  shippingOptions: null,
  selectedLeadDays: null,
  selectedShipDescription: null,


  billingAddress: { // `CheckoutBilling` will sync this with the order on first load
    bill_company: "",
    bill_name: "",
    bill_phone: "",
    customer_reference: "",
  },
  billNameError: null,
  billPhoneError: null,
  localBillAddressValid: false,
  paymentMethod: null,
  setDefaultPaymentMethodGuidTo: null,
}

/** Describes a reducer action for checkout state, with related data */
export type CheckoutAction = 
    { type: "clear_nest_progress" }
  | { type: "add_nest_progress", key: string, value: CheckoutState["nestProgress"][string] }
  | { type: "set_nest_result", data: CheckoutState["nestResult"] }
  | { type: "set_nest_error", data: CheckoutState["nestError"] }

  | { type: "set_shipping_address", data: CheckoutState["shippingAddress"] }
  | { type: "update_shipping_address", data: Partial<CheckoutState["shippingAddress"]> }

  | { type: "clear_ship_and_lead_options" }
  | { type: "set_lead_options", data: CheckoutState["leadOptions"] }
  | { type: "set_ship_options", data: CheckoutState["shippingOptions"] }
  | { type: "set_selected_lead_days", data: CheckoutState["selectedLeadDays"] }
  | { type: "set_selected_ship_option", shipment_description: CheckoutState["selectedShipDescription"] }

  | { type: "set_billing_address", data: CheckoutState["billingAddress"] }
  | { type: "update_billing_address", data: Partial<CheckoutState["billingAddress"]> }
  | { type: "set_use_shipping_address", data: boolean }
  | { type: "set_payment_method", data: CustomerPaymentMethod | null }
  | { type: "set_default_payment_method_guid", data: string | null}


export const checkoutReducer = (state: CheckoutState, action: CheckoutAction) => {

  switch(action.type) {
    case "clear_nest_progress": {
      return update(state, { nestProgress: { $set: {} } })
    }
    case "add_nest_progress": {
      return update(state, { nestProgress: { [action.key]: { $set: action.value } } })
    }
    case "set_nest_result": {
      return update(state, { nestResult: { $set: action.data } })
    }
    case "set_nest_error": {
      return update(state, { nestError: { $set: action.data } })
    }

    case "set_shipping_address": {
      const validityResult = checkShipAddressErrors(action.data)

      return update(state, {
        shippingAddress:  { $set: action.data },
        shipNameError:    { $set: validityResult.nameError },
        shipPhoneError:   { $set: validityResult.phoneError },
        shipAddressError: { $set: validityResult.addressError },
        shipCityError:    { $set: validityResult.cityError },
        shipStateError:   { $set: validityResult.stateError },
        shipZipError:     { $set: validityResult.zipError },
        localShipAddressValid: { $set: validityResult.isValid },
      })
    }
    case "update_shipping_address": {
      const newShippingAddress: OrderShipAddress = { ...state.shippingAddress, ...action.data }
      const validityResult = checkShipAddressErrors(newShippingAddress)

      return update(state, {
        shippingAddress:  { $set: newShippingAddress },
        shipNameError:    { $set: validityResult.nameError },
        shipPhoneError:   { $set: validityResult.phoneError },
        shipAddressError: { $set: validityResult.addressError },
        shipCityError:    { $set: validityResult.cityError },
        shipStateError:   { $set: validityResult.stateError },
        shipZipError:     { $set: validityResult.zipError },
        localShipAddressValid: { $set: validityResult.isValid },
      })
    }

    case "clear_ship_and_lead_options": {
      return update(state, { shippingOptions: { $set: null }, leadOptions: { $set: null }})
    }
    case "set_lead_options": {
      return update(state, { leadOptions: { $set: action.data }})
    }
    case "set_ship_options": {
      return update(state, { shippingOptions: { $set: action.data }})
    }
    case "set_selected_lead_days": {
      return update(state, { selectedLeadDays: { $set: action.data }})
    }
    case "set_selected_ship_option": {
      return update(state, { selectedShipDescription: { $set: action.shipment_description } })
    }

    case "set_billing_address": {
      const validityResult = checkBillAddressErrors(action.data)

      return update(state, {
        billingAddress:   { $set: action.data },
        billNameError:   { $set: validityResult.nameError },
        billPhoneError:   { $set: validityResult.phoneError },
        localBillAddressValid: { $set: validityResult.isValid },
      })
    }
    case "update_billing_address": {
      const newBillingAddress: OrderBillAddress = { ...state.billingAddress, ...action.data }
      const validityResult = checkBillAddressErrors(newBillingAddress)

      return update(state, {
        billingAddress:   { $set: newBillingAddress },
        billNameError:   { $set: validityResult.nameError },
        billPhoneError:   { $set: validityResult.phoneError },
        localBillAddressValid: { $set: validityResult.isValid },
      })
    }
    case "set_payment_method": {
      return update(state, { paymentMethod: { $set: action.data } })
    }
    case "set_default_payment_method_guid": {
      return update(state, { setDefaultPaymentMethodGuidTo: { $set: action.data } })
    }
  }

  return state
}


/**
 * Returns true if a string contains at least ten alphanumeric characters. Other characters are ignored.
 * @param {*} str 
 * @returns True of a string contains at least ten alphanumeric characters, false otherwise.
 */
export function isValidPhoneNumber(str: string) {
  let regex = /[a-zA-Z0-9]/g
  let chars = str.match(regex)
  return chars && chars.length >= 10
}

function checkShipAddressErrors(address: OrderShipAddress) {
  const isPickup = !!address.is_pickup && address.pickup_facility_id > 0
  const nameError = !address.ship_name.trim() ? 'Please enter a name' : null
  const phoneError = !isValidPhoneNumber(address.phone ?? "") ? 'Please enter a valid phone number' : null
  const addressError = !(address.ship_address ?? "").trim() && !isPickup ? 'Please enter an address' : null
  const cityError = !(address.ship_city ?? "").trim() && !isPickup ? 'Please enter a city' : null
  const stateError = !(address.ship_state ?? "").trim() && !isPickup ? 'Please enter a state' : null
  const zipError = !/[0-9]{5,}/.test(address.ship_zip ?? "") && !isPickup ? 'Please enter a valid zip code' : null
  const isValid = true
    && !nameError
    && !phoneError
    && !addressError
    && !cityError
    && !stateError
    && !zipError 

  return {
    nameError,
    phoneError,
    addressError,
    cityError,
    stateError,
    zipError,
    isValid,
  }
}

function checkBillAddressErrors(address: OrderBillAddress) {
  const nameError = !(address.bill_name ?? "").trim() ? 'Please enter a name' : null
  const phoneError = !isValidPhoneNumber((address.bill_phone ?? "")) ? 'Please enter a valid phone number' : null
  const isValid = true
    && !nameError
    && !phoneError

  return {
    nameError,
    phoneError,
    isValid,
  }
}