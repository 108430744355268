import { ReactNode } from 'react';
import './css/Card.scss';

interface CardProps {
    title?: ReactNode;
    rightElement?: ReactNode;
    children?: ReactNode;
    className?: string;
}

const Card = ({ title, rightElement, children, className }: CardProps) => {

    return (
        <div className={`card ${className ? className : ''}`}>

            {(title || rightElement) ? <div className={'cardHeader'}>
                <div className={'title'}>{title}</div>
                <div className={'rightElement'}>{rightElement}</div>
            </div> : null}

            <div className={'cardBody'}>
                {children}
            </div>

        </div>
    );
};
export default Card;