import { LinkButton, PrimaryButton } from '@oshcut/components'
import { FormEvent, ReactNode, useContext, useState } from 'react'
import './ForgotPasswordForm.scss'
import { useAlert } from 'hooks/useAlert'
import { SignInContext } from 'signInContext'
import { fetchPost } from '@oshcut/oshlib' 

const ForgotPasswordForm = () => {

  const { dispatch } = useContext(SignInContext)

  const [email, setEmail] = useState('')

  const [error, setError] = useState<ReactNode>('')
  const [isWorking, setIsWorking] = useState(false)

  const [alertContent, showAlert] = useAlert()

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)))
    {
      setError('Email not valid')
      return
    }

    setIsWorking(true)
    try {
      await fetchPost('/api/v1/customer/password/forgot', { email: email })

      showAlert('Email Sent', <div>We sent an email to <b>{email}</b> with instructions for resetting your password. Please check your email to continue.</div>)
      dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    } catch (ex) {
      setError(<span>An error occurred when trying to send a reset email to <b>{email}</b>. Please try again.</span>)
    } finally {
      setIsWorking(false)
    }
  }

  function handleBack() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    dispatch({ type: 'ACTION_OPEN_SIGNIN_DIALOG' })
  }

  return (
    <form className='ForgotPasswordForm' onSubmit={handleSubmit}>

      <div className="message">
        <p>
          Please provide your email address below and we’ll send you a link to reset your password.
        </p>
      </div>

      <div className="inputGroup">
        <div className="label"><span>Email</span></div>
        <input
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      {error ? <div className="error">
        {error}
      </div> : null}

      <div className="submit">
        <PrimaryButton type="submit" disabled={isWorking}>Send Reset Link</PrimaryButton>
      </div>

      <LinkButton type={'button'} tabIndex={-1} onClick={handleBack}>
        Back
      </LinkButton>

      {alertContent}
    </form>
  )
}

export default ForgotPasswordForm