import { ReactNode } from 'react'
import './ErrorCard.scss'
import Card from '../Card'
import { FaRegCircleXmark } from 'react-icons/fa6'

export function ErrorCard({ children }: { children: ReactNode }) {
  return (
    <Card className='ErrorCard'>
      <div className='error-card-content'>
        <FaRegCircleXmark className='error-card-icon' />
        {children}
      </div>
    </Card>
  )
}