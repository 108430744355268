import { Arcified, ArcifiedTube, ArcifiedUnknown, mul_3, Part, sub, sub_3, Unit, Vector3 } from "@oshcut/oshlib";
import { isSheetMetal, isUnknown } from "partTypeGuards";
import unitScale from "unitScale";

export type PartSize = {
  /** The part size in native part units. */
  native: Vector3
  /** The part size in scaled part units. */
  scaled: Vector3
  /** The rounded part size in scaled part units. */
  scaledRounded: Vector3
  /** The formatted part size in scaled part units. */
  scaledFormatted: string
  /** The part size in inches. */
  inches: Vector3
  /** The rounded part size in inches. */
  inchesRounded: Vector3
  /** The formatted part size in inches. */
  inchesFormatted: string
}

/**
 * Returns the size of a part in inches, native part units, and scaled part units.
 * @param part The object from the part database.
 * 
 * @example
 * A part has a native size of 10 x 20 x 30, a scale factor of 2, and units of "cm":
 * ```ts
 * {
 *   native: { x: 10, y: 20, z: 30 },
 *   scaled: { x: 20, y: 40, z: 60 },
 *   scaledRounded: { x: 20, y: 40, z: 60 },
 *   scaledFormatted: "20 cm x 40 cm x 60 cm",
 *   inches: { x: 7.8740157480315, y: 15.748031496063, z: 23.622047244094 },
 *   inchesRounded: { x: 7.874, y: 15.748, z: 23.622 }
 * }
 * ```
 */
export function getPartSize(part: Part) {
  const partScale = unitScale[part.units || 'in'] / part.scale_factor

  let native = {
    x: (part.finish_inches_x ?? 0) * partScale,
    y: (part.finish_inches_y ?? 0) * partScale,
    z: (part.finish_inches_z ?? 0) * partScale
  }

  // Setting z to 0 for flat parts is merely a matter of convention.
  if (!part.has_bending && !part.has_tube_laser) {
    native.z = 0
  }

  return getSizesFromNative(native, part.units, part.scale_factor)

}

/**
 * Returns the size of an arcified object in inches, native part units, and scaled part units.
 * @param arcified The arcified part.
 * @param flat If true, returns the flat size of sheet metal parts. Default is true if the part is a sheet metal part
 * with no bends, and false otherwise.
 * 
 * @example
 * A part has a native size of 10 x 20 x 30, a scale factor of 2, and units of "cm":
 * ```ts
 * {
 *   native: { x: 10, y: 20, z: 30 },
 *   scaled: { x: 20, y: 40, z: 60 },
 *   scaledRounded: { x: 20, y: 40, z: 60 },
 *   inches: { x: 7.8740157480315, y: 15.748031496063, z: 23.622047244094 },
 *   inchesRounded: { x: 7.874, y: 15.748, z: 23.622 }
 * }
 * ```
 */
export function getArcifiedSize(arcified: Arcified | ArcifiedTube | ArcifiedUnknown, {
  flat,
  minimal = false
}: {
  flat?: boolean
  minimal?: boolean
} = {}): PartSize | null {

  if (isUnknown(arcified)) return null

  if (flat === undefined) flat = isSheetMetal(arcified) && arcified.contours.filter(c => c.cuttingMethod === 'CUTTING_METHOD_BEND').length === 0

  let native

  if (flat && isSheetMetal(arcified)) {
    native = {
      ...sub(arcified.bboxCut.max, arcified.bboxCut.min),
      // Setting z to 0 for flat parts is merely a matter of convention.
      z: 0
    }
  } else if (minimal) {
    if (arcified.bbox3Minimal) {
      native = sub_3(arcified.bbox3Minimal.max, arcified.bbox3Minimal.min)
    } else if (arcified.props3D) {
      native = sub_3(arcified.props3D.minimalBbox.max, arcified.props3D.minimalBbox.min)
    } else {
      return null
    }
  } else {
    if (arcified.bbox3) {
      native = sub_3(arcified.bbox3.max, arcified.bbox3.min)
    } else if (arcified.props3D) {
      native = sub_3(arcified.props3D.bbox.max, arcified.props3D.bbox.min)
    } else {
      return null
    }
  }

  return getSizesFromNative(native, arcified.units, arcified.scaleFactor)
}

function getSizesFromNative(native: Vector3, units: Unit | null | undefined, scaleFactor: number | undefined): PartSize {
  const partScale = (scaleFactor ?? 1) / unitScale[units || 'in']

  let inches = mul_3(native, partScale)
  let scaled = mul_3(native, scaleFactor ?? 1)
  let inchesRounded = {
    x: round(inches.x, 3),
    y: round(inches.y, 3),
    z: round(inches.z, 3)
  }

  // Round scaled to give at least 0.001" precision
  let scaledRoundedDecimals = Math.ceil(3 - Math.log10(unitScale[units || 'in']))
  let scaledRounded = {
    x: round(scaled.x, scaledRoundedDecimals),
    y: round(scaled.y, scaledRoundedDecimals),
    z: round(scaled.z, scaledRoundedDecimals)
  }

  let scaledFormatted = format(scaledRounded, units, scaledRoundedDecimals)
  let inchesFormatted = format(inchesRounded, 'in')

  return {
    native,
    scaled,
    scaledRounded,
    scaledFormatted,
    inches,
    inchesRounded,
    inchesFormatted
  }

}



function round(x: number, decimals: number) {
  return Math.round(x * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

function format(size: Vector3, units?: string | null, decimals = 3) {
  let unitsWithSpace = units ? ` ${units}` : ''
  let pieces: string[] = []
  if (size.x) pieces.push(`${size.x.toFixed(decimals)}${unitsWithSpace}`)
  if (size.y) pieces.push(`${size.y.toFixed(decimals)}${unitsWithSpace}`)
  if (size.z) pieces.push(`${size.z.toFixed(decimals)}${unitsWithSpace}`)
  return pieces.join(' x ')
}