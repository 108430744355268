import { sub, add, Contour, Vector } from "@oshcut/oshlib"

/**
 * Determines if two contours are similar. The contours must belong to the same part, otherwise the behavior is undefined. A contour is not similar to itself.... or is it?
 * @param c1 The first contour
 * @param c2 The second contour
 * @returns Whether the two contours are similar
 */
export function areContoursSimilar(c1: Contour, c2: Contour) {
  return c1.similarTo === c2.id || c2.similarTo === c1.id || (c1.similarTo === c2.similarTo && typeof c1.similarTo !== 'undefined')
}

/**
 * Maps an array of lead-in positions from one contour to another, similar contour.
 * @param contour1 The source contour whose positions should be mapped from
 * @param contour2 The destination contour the positions will be mapped to
 * @param positions1 The positions on contour1 that will be mapped
 * @returns A new array of points that have been mapped from contour1 to contour2
 */
export function mapLeadInPositions(contour1: Contour, contour2: Contour, positions1: Vector[]) {
  let cos = Math.cos(contour2.properties.thetap - contour1.properties.thetap)
  let sin = Math.sin(contour2.properties.thetap - contour1.properties.thetap)

  return positions1.map(p => {
    let transP = sub(p, contour1.properties.centroid)
    transP = {
      x: cos * transP.x - sin * transP.y,
      y: sin * transP.x + cos * transP.y
    }
    transP = add(transP, contour2.properties.centroid)
    return transP
  })

}