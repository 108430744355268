import { fetchPost, fromTupleMaterials } from '@oshcut/oshlib'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useReducer } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { StateType } from 'types'
import { AllSignInForms } from 'useSignIn'
import { useDfmChecks } from '../hooks/useDfmChecks'
import { NotifyProvider } from '../hooks/useNotify'
import { partNeedsQuote, partReadyForQuote, useQuote } from '../hooks/useQuote'
import { useUploadQueue } from '../hooks/useUploadQueue'
import Log from '../logs'
import { reducer } from '../reducer'
import './css/App.scss'
import './css/Dialog.scss'
import './css/fonts.scss'
import { FallbackErrorComponent } from './FallbackErrorComponent'
import LandingMessage from './LandingMessage'
import { MainView } from './MainView'
import { RefreshPrompt } from './RefreshPrompt'
import SiteHeader from "./SiteHeader"

let key = 'pk_live_FWbuGoKAts01e1EIglKH6sdq'
if (window.location.href.indexOf('localhost') >= 0
  || window.location.href.indexOf('dev3.oshcut.com') >= 0
  || window.location.href.indexOf('sand.oshcut.com') >= 0) {
  key = 'pk_test_Yntq8iG12Ms1xw6FuvfdHqx4'
  Log.info('Using test API key.')
}

const stripePromise = loadStripe(key)

function App() {

  const initialState: StateType = {
    parts: [],
    order: null,
    quote: null,
    submittedOrder: null,
    order_parts: [],
    selectedPartIds: [],
    selectedContours: [],
    isErrorPanelVisible: false,
    expandedPartInfoKeys: ['generic-part-properties'],
    hoverPartInfoKey: null,
    recentMaterialIds: [],
    recentMaterialTubeIds: [],
    quoteInvalidationTimestamp: 0,
    materialSheets: [],
    materialTypes: [],
    materialTubes: [],
    powders: [],
    messages: [],
    billingEqualsShipping: false,
    linkAnimatorMessages: {
      account: null,
      currentOrder: null
    },
    colorCodeBends: false,
    showBendAllowance: false,
    disableBendTransforms: false,
    showStacked: false,
    bendAdvancedExpanded: false,
    showConvexGroups: false,
    percentBendTransform: 100,
    percentBendTransformById: {},
    previewSelectedContourProperties: {},
    taps: [],
    alertPoints: [],
    showTubeDebugHints: false,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { enqueueItem } = useUploadQueue({ parts: state.parts, parentDispatch: dispatch })

  useDfmChecks({ state, dispatch })

  /** Obtain quote */

  // Skip deleted parts, parts with hard errors, and parts without units or material
  let partsNeedingQuote = state.parts.filter(partNeedsQuote)

  // All remaining parts must have READY status. If not, pass null for the quote request
  let isReadyToQuote = partsNeedingQuote.length > 0
    && !partsNeedingQuote.some(p => !partReadyForQuote(p))
    && !!state.order?.guid

  let rfq = state.order && { order_guid: state.order.guid, orderGuid: state.order.guid }
  const [quote] = useQuote(state.quoteInvalidationTimestamp, isReadyToQuote ? rfq : undefined)

  useEffect(() => {
    dispatch({ type: 'ACTION_SET_QUOTE', quote })
  }, [quote])

  // Restore data from session storage
  useEffect(() => {
    let ssSubmitedOrder = sessionStorage.getItem('submittedOrder')
    if (ssSubmitedOrder) {
      dispatch({ type: 'ACTION_SET_SUBMITTED_ORDER', order: JSON.parse(ssSubmitedOrder) })
    }
  }, [])

  /** Load materials, powders, messages, taps */
  // TODO: This is called by the catalog view as well as the app. Maybe we could fix that.
  useEffect(() => {
    async function loadMaterials() {

      let [
        customerMaterialTuples,
        messages,
        powdersResult,
        tapsResult,
      ] = await Promise.all([
        fetchPost('/api/v2/materials'),
        fetchPost('/api/v2/messages'),
        fetchPost('/api/v3/powder/all'),
        fetchPost('/api/v3/taps/all'),
      ])

      let { materialTypes, materialSheets, materialTubes } = fromTupleMaterials(customerMaterialTuples)
      let powders = powdersResult.result[0].data
      let taps = tapsResult.result[0].data

      materialSheets.sort((a, b) => a.thickness - b.thickness)

      powders = powders.filter(p => p.enabled)

      dispatch({ type: 'ACTION_ALL_MATERIALS_LOADED', materialTubes, materialTypes, materialSheets })
      dispatch({ type: 'ACTION_MESSAGES_LOADED', messages })
      dispatch({ type: 'ACTION_ALL_POWDERS_LOADED', powders })
      dispatch({ type: 'ACTION_ALL_TAPS_LOADED', taps })
    }

    loadMaterials()
  }, [])


  function onError(error: Error, info: any) {
    if (import.meta.env.PROD) {
      fetchPost('/api/v2/status', {
        type: 'error',
        title: error.message,
        details: JSON.stringify({
          message: error.message,
          stack: error.stack,
          info,
          url: window.location.href
        }),
        order_guid: state.order?.guid
      })
        .catch(() => { })
    }
  }

  return (

    <div className="App">
      <ErrorBoundary FallbackComponent={() => <FallbackErrorComponent state={state} dispatch={dispatch} />} onError={onError}>
        <SiteHeader state={state} dispatch={dispatch} />
        <NotifyProvider>
          <Elements stripe={stripePromise}>
            <LandingMessage messages={state.messages} />
            <MainView
              state={state}
              dispatch={dispatch}
              enqueueUploadItem={enqueueItem} />
          </Elements>
        </NotifyProvider>

        <AllSignInForms />

        <RefreshPrompt app='customer' />
      </ErrorBoundary>

    </div>
  )
}


export default App
