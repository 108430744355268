import React from 'react'
import './css/Notification.scss'

export function Notification(props) {

  const style = { bottom: props.index * 70 + 10 }

  return (
    <div className="notification" style={style}>
      <div className="notificationWrapper">
        <div className="notificationCloseButton" onClick={props.item.onClose}>
          <svg viewBox="0 0 24 24 ">
            <path stroke='current' strokeWidth='2' d="M6 6L18 18M6 18L18 6" />
          </svg>
        </div>
        <div className="notificationText">{props.item.message}</div>
        {
          props.item.onLink && (
            <span className="link" onClick={props.item.onLink}>{props.item.linkMessage}</span>
          )
        }
      </div>
    </div >
  )
}